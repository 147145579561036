import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import userIcon from "../../src/icons/Profile/userIcon.png";
import { auth, db } from "../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../components/AuthContext";
import { useGeneralContext } from "../context/GeneralContext";
import Popup from "../components/Popup";
import { encrypt, decrypt } from "../service/encryption/Encryption";

const Login1 = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordSection, setShowPasswordSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [attempts, setAttempts] = useState(3);
  const navigate = useNavigate();
  const { login, setRoleBasedRoute } = useAuth();
  const [searchParams] = useSearchParams();
  const { dupAddressData, clearDupAddress, setDupAddress, setEmailfunc } =
    useGeneralContext();
  const verificationToken = searchParams.get("token");
  const isDupAddress = searchParams.get("dupAddress") === "true";
  const encodedData = searchParams.get("data");

  const getUserByEmail = async (email) => {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", email.toLowerCase())
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  const handleLogin = async () => {
    console.log(
      "Decrypted password:",
      decrypt("U2FsdGVkX197nKkuPIgCWi29LjREsNIZrBI0LSHQe80=")
    );
    setIsLoading(true);
    try {
      if (password.length === 0) {
        setPopupMessage("Password cannot be blank");
        return;
      }

      // First check if this email is a fraud email from URL parameters
      if (isDupAddress && encodedData) {
        const verificationData = JSON.parse(atob(encodedData));

        // Check if this is the fraud email trying to log in
        if (verificationData.fraud_email === email) {
          // Check if the original user has verified yet
          const originalUserQuery = query(
            collection(db, "users"),
            where("email", "==", verificationData.original_email),
            where("dup_address", "==", true),
            where("dup_address_owner", "==", true),
            where("dup_address_token", "==", verificationToken)
          );
          const originalUserSnapshot = await getDocs(originalUserQuery);

          if (originalUserSnapshot.empty) {
            // Original user hasn't verified yet
            setRoleBasedRoute(null);
            setEmailfunc(email.toLowerCase());
            navigate("/addressverify");
            return;
          }
        }
      }

      const userData = (
        await getDocs(
          query(
            collection(db, "users"),
            where("email", "==", email.toLowerCase().trim())
          )
        )
      ).docs[0].data();

      if (userData.disabled && userData.status === "inactive") {
        console.log(
          "fraud user redirected to addressverify from disabled login"
        );
        setRoleBasedRoute(null);
        setEmailfunc(email.toLowerCase());
        navigate("/addressverify");
        return;
      } else if (userData.disabled && userData.status === "active") {
        setPopupMessage(
          "Account has been disabled due to too many failed attempts. Please contact support."
        );
        return;
      }

      // Proceed with normal login if all checks pass
      let userCredential;
      try {
        userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        ).catch(() => {
          setPopupMessage("Incorrect password please try again");
          return;
        });

        if (!userCredential) return;
      } catch {
        setPopupMessage("Incorrect password please try again");
        return;
      }

      const user = await getUserByEmail(email);
      setRoleBasedRoute(user.user_role);
      login(userCredential.user.accessToken);

      // Update user document with encrypted password
      const userRef = doc(db, "users", user.id);
      const userDoc = await getDoc(userRef);
      if (!userDoc.data().password) {
        await updateDoc(userRef, {
          password: encrypt(password),
        });
      }

      if (isDupAddress && encodedData) {
        const verificationData = JSON.parse(atob(encodedData));
        if (verificationData.original_email === email) {
          await handleDupAddressLogin();
        } else if (
          verificationData.fraud_email === email &&
          user.dup_address_verified
        ) {
          setEmailfunc(email.toLowerCase());
          navigate("/addressverify");
          return;
        }
      }

      // Normal login flow
      if (user.profile_completed) {
        const isDupAddress = searchParams.get("dupAddress") === "true";
        if (isDupAddress) {
          navigate("/home?dupAddress=true");
        } else {
          navigate("/home");
        }
      } else {
        switch (user.user_role) {
          case "partner":
            navigate("/partnerprofile");
            break;
          case "citizen":
            navigate("/citizenprofile");
            break;
          case "center":
            navigate("/centerprofile");
            break;
          default:
            const isDupAddress = searchParams.get("dupAddress") === "true";
            if (isDupAddress) {
              navigate("/home?dupAddress=true");
            } else {
              navigate("/home");
            }
        }
      }
    } catch (err) {
      const attemptsLeft = attempts - 1;
      switch (err.code) {
        case "auth/invalid-email":
          setPopupMessage(
            `Invalid email format. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/user-not-found":
          setPopupMessage(
            `User not found. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/wrong-password":
          setPopupMessage(
            `Incorrect password. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/too-many-requests":
          setPopupMessage(
            "Account has been disabled due to too many failed attempts. Please contact support."
          );
          break;
        case "auth/invalid-credential":
          if (attemptsLeft === 1)
            setPopupMessage(
              `Invalid credentials provided. You have ${attemptsLeft} attempts remaining before your account is disabled.`
            );
          break;
        case "auth/missing-password":
          setPopupMessage("Password cannot be blank");
          break;
        default:
          setPopupMessage(
            "Account not found. Please make sure you have the correct email and password."
          );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePopupClose = () => {
    setPopupMessage("");
    setIsLoading(false);
  };

  const onContinue = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && emailPattern.test(email)) {
      setShowPasswordSection(true);
    } else {
      setPopupMessage("Please enter a valid email address.");
    }
  };

  const handleEditEmail = () => {
    setShowPasswordSection(false);
  };

  const handleDupAddressLogin = async () => {
    console.log("handleDupAddressLogin - Current state:", {
      isDupAddress,
      dupAddressData,
      email,
    });

    if (isDupAddress && dupAddressData && email) {
      try {
        console.log(
          "Processing duplicate address login with data:",
          dupAddressData
        );

        if (dupAddressData.original_email !== email) {
          console.log("User is not authorized to verify this address");
          setPopupMessage("You are not authorized to verify this address");
          return;
        }

        // Update original user
        const userQuery = query(
          collection(db, "users"),
          where("email", "==", email)
        );
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          await updateDoc(userDoc.ref, {
            dup_address: true,
            dup_address_owner: true,
            dup_address_token: dupAddressData.token,
          });
          console.log("Updated original user:", email);
        }

        // Update fraud user if they exist
        const fraudUserQuery = query(
          collection(db, "users"),
          where("email", "==", dupAddressData.fraud_email)
        );

        const fraudUserSnapshot = await getDocs(fraudUserQuery);
        if (!fraudUserSnapshot.empty) {
          const fraudUserDoc = fraudUserSnapshot.docs[0];
          await updateDoc(fraudUserDoc.ref, {
            disabled: true,
            status: "inactive",
            dup_address: true,
            dup_address_owner: false,
            dup_address_token: dupAddressData.token,
            dup_address_otp: dupAddressData.otp,
          });
          console.log("Updated fraud user:", dupAddressData.fraud_email);
        }

        // Clear the context data after processing
        clearDupAddress();
      } catch (error) {
        console.error("Error in handleDupAddressLogin:", error);
        setPopupMessage("An error occurred during address verification");
      }
    } else {
      console.log("Missing required data:", {
        isDupAddress,
        hasDupAddressData: !!dupAddressData,
        hasEmail: !!email,
      });
    }
  };

  return (
    <div className="viewport h-screen w-full overflow-hidden ">
      {popupMessage && (
        <Popup
          title={"Notification"}
          message={popupMessage}
          onClose={handlePopupClose}
        />
      )}
      <div className="viewport-bg flex h-full w-full flex-col items-center justify-center bg-[rgb(225,190,116)]/[0.13]">
        <div className="flex rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)]">
          <div className="w-[350px] rounded-l-lg bg-white">
            <h5 className="mt-12 flex flex-col items-center justify-center">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label className="login-logo-label mt-2.5">DARCI</label>
            </h5>
            <div className="z-0 mt-1 flex w-[483px] max-w-full flex-col items-center justify-center">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl leading-none">
                  <span className="text-sm text-blue-500">
                    Disaster Aware & Ready Communities
                  </span>
                  {/* <span className="text-base text-blue-500">BlueSky</span> */}
                </div>
              </div>
            </div>

            <div className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center px-5">
              <div className="flex w-full flex-col gap-[5px]">
                <p className="my-[10px] text-center text-[20px] font-bold text-[#3D435A]">
                  Sign In
                </p>

                {showPasswordSection ? (
                  <>
                    <div className="relative -mt-2">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            loading="lazy"
                            src={userIcon}
                            className="aspect-[0.99] w-[25px] object-contain"
                          />
                          <div className="ml-1">
                            <p className="max-md:max-w-full whitespace-nowrap text-xs font-bold text-blue-600">
                              {email}
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={handleEditEmail}
                          className="text-bold bg-white text-xs text-blue-500"
                        >
                          <FaEdit className="h-3 w-3" />
                        </button>
                      </div>
                    </div>
                    <div className="relative mt-2">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleLogin();
                          }
                        }}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer !font-[Inter] text-blue-500"
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="relative">
                    <input
                      placeholder="Enter Email"
                      type="text"
                      className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
                      value={email}
                      onChange={(e) => {
                        //console.log(encrypt(e.target.value));
                        setEmail(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onContinue();
                        }
                      }}
                    />
                  </div>
                )}

                <div className="mt-2 flex w-full flex-col gap-[10px]">
                  <button
                    className="bg-login-btn h-10 w-full rounded-[4px] py-[10px] font-[Inter] hover:cursor-pointer"
                    onClick={showPasswordSection ? handleLogin : onContinue}
                  >
                    {isLoading
                      ? "Please wait..."
                      : showPasswordSection
                      ? "Continue"
                      : "Continue"}
                  </button>
                  <div className="flexw-full text-center">
                    <p className="w-full text-center ">
                      Not yet registered?
                      <Link
                        className="ml-2  text-base text-blue-500 no-underline hover:cursor-pointer"
                        to="/registration"
                      >
                        Register
                      </Link>
                    </p>
                  </div>
                  <div className="flex w-full gap-0.5 text-center">
                    <p className="w-full text-center ">
                      <Link
                        to="/forgotpassword"
                        className="text-[#348BFF] no-underline hover:cursor-pointer"
                      >
                        Forgot Password
                      </Link>
                    </p>
                  </div>

                  <div
                    className="my-5 justify-center text-center "
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    <a
                      className="text-[inherit] text-blue-500 [text-decoration:none]"
                      href="/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    <a>&nbsp;and&nbsp;</a>
                    <a
                      className="text-[inherit] text-blue-500 [text-decoration:none]"
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </div>

                  {/*<div className="flex">
                          <button className="mr-2 h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
                              onClick={onLoginWithGoogle} > <
                              img alt="" src={google} />
                          </button>
                          <button className="h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
                              <img alt="" src={microsoft} />
                          </button>
                      </div>*/}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, a disaster aware and community ready
              application.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>

        <div className="mt-5 text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.blueskymobile"
            className="login-download-mobile text-blue-500 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Download DARCI on Android
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login1;
// ************************************THIS IS THE OLD CODE LOG IN PAGE****************************************
// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { auth } from "../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { useAuth } from "../components/AuthContext";
// import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
// import note from "../../src/assets/note.png";
// import check from "../../src/assets/check.png";
// import umbrella from "../../src/assets/umbrella.png";
// import lightning from "../../src/assets/lightning.png";
// import fire from "../../src/assets/fire.png";
// import google from "../icons/login/google-icon.svg";
// import microsoft from "../icons/login/microsoft-icon.svg";

// const Login1 = ({ email, onEmailChange, onContinue, onLoginWithGoogle }) => {
//   return (
//     <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto bg-zinc-50 px-8 !font-[Inter]">
//       <div className="!item-center login-form relative flex h-[80vh] w-[70vw] !justify-center overflow-hidden rounded-xl bg-white shadow-[0px_4px_9px_rgba(0,0,0,0.25)]">
//         <div className="flex w-2/3 flex-col items-center justify-center px-11 py-5 md:px-0">
//           <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
//             <img
//               loading="lazy"
//               src={blueSkyLogo}
//               className="aspect-[0.99] w-[90px] object-contain"
//               alt="DARCI logo"
//             />
//             <label
//               className="mt-2.5 "
//               style={{ fontSize: "32px", fontWeight: 700 }}
//             >
//               DARCI
//             </label>
//           </header>
//           <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
//             <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
//               <div className="text-xl  leading-none">
//                 <span className="text-base">Powered by </span>
//                 <span className="text-base text-blue-500">BlueSky</span>
//               </div>
//             </div>
//           </div>

//           <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
//             <div className="flex w-full flex-col gap-[5px] pt-3">
//               <p className="my-[20px] text-center  text-[20px] font-bold text-[#3D435A]">
//                 Sign In
//               </p>
//               <input
//                 placeholder="Enter Email"
//                 type="text"
//                 className="h-[40px]  w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
//                 name="email"
//                 value={email}
//                 onChange={(e) => onEmailChange(e.target.value)}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter") {
//                     onContinue();
//                   }
//                 }}
//               />
//               {/* <div className="mt-[10px] flex items-center gap-[10px]">
//                       <input
//                           type="checkbox"
//                           id="rememberMe"
//                           className="w-5 h-5 rounded border border-solid border-zinc-300 min-h-[20px]"
//                       />
//                         <p className=" text-base text-[#D5D7DB]">Remember me</p>
//                     </div> */}
//               <div className="mt-2 flex w-full flex-col gap-[10px]">
//                 <button
//                   className="h-10 w-full rounded-[4px] bg-[#348BFF] py-[10px] font-[Inter]  text-base font-bold text-white hover:cursor-pointer"
//                   onClick={onContinue}
//                 >
//                   Continue
//                 </button>
//                 <div className="flexw-full text-center">
//                   <p className="w-full text-center ">
//                     Not yet registered?
//                     <Link
//                       className="ml-2  text-base text-blue-500 no-underline hover:cursor-pointer"
//                       to="/registerv2"
//                     >
//                       Register
//                     </Link>
//                   </p>
//                 </div>
//                 <div className="flex w-full gap-0.5 text-center">
//                   <p className="w-full text-center ">
//                     <Link
//                       to="/forgotpassword"
//                       className="text-[#348BFF] no-underline hover:cursor-pointer"
//                     >
//                       Forgot Password
//                     </Link>
//                   </p>
//                 </div>

//                 <div
//                   className="my-5 justify-center text-center "
//                   style={{ fontSize: "12px", fontWeight: 400 }}
//                 >
//                   <a
//                     className="text-[inherit] text-blue-500 [text-decoration:none]"
//                     href="/privacy"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     Privacy Policy
//                   </a>
//                   <a>&nbsp;and&nbsp;</a>
//                   <a
//                     className="text-[inherit] text-blue-500 [text-decoration:none]"
//                     href="/terms"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     Terms & Conditions
//                   </a>
//                 </div>
// {/*
//                 {/*<div className="flex">
//                           <button className="mr-2 h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
//                               onClick={onLoginWithGoogle} > <
//                               img alt="" src={google} />
//                           </button>
//                           <button className="h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
//                               <img alt="" src={microsoft} />
//                           </button>
//                       </div>*/} */}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="block h-full w-1/3 bg-blue-500 px-5 py-5 md:hidden ">
//           <div
//             className="text-xl login-desc  text-white"
//             style={{ fontSize: "20px", fontWeight: 400 }}
//           >
//             Welcome to the DARCI Program, powered by Blue Sky.
//             <br />
//             <br />
//             We are here to support your efforts in strengthening community
//             preparedness and recovery.
//             <br />
//             <br />
//             Access comprehensive tools, resources, and insights to enhance your
//             leadership, response, and recovery capabilities.
//             <br />
//             <br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Login1;
