import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import placeholderImage from "../icons/Opportunities/opportunities.png";
import { useEffect } from "react";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
  getFirestore,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const JobOpportunities = ({ className = "", onNavigateBack }) => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);

  const filteredJobs = jobs.filter((job) =>
    job.job.title.toLowerCase().includes(searchTitle.toLowerCase())
  );

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return "$0.00";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const convertToDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "N/A";

    const date = timestamp.toDate();
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const db = getFirestore();

  const fetchJobs = async () => {
    if (!userData.id || !userData.center_id) {
      setJobs([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);

      let jobsQueryArray = [];
      let jobsQuerySingle = [];

      // Query for `center_id` as an array
      jobsQueryArray = query(
        collection(db, "opportunities"),
        where("type", "==", "job"),
        where("center_id", "array-contains", userData.center_id),
        where("is_deleted", "==", false),
        where("published", "==", true)
      );

      // Query for `center_id` as a single value
      jobsQuerySingle = query(
        collection(db, "opportunities"),
        where("type", "==", "job"),
        where("center_id", "==", userData.center_id),
        where("is_deleted", "==", false),
        where("published", "==", true)
      );

      // Execute both queries
      const [queryArraySnapshot, querySingleSnapshot] = await Promise.all([
        getDocs(jobsQueryArray),
        getDocs(jobsQuerySingle),
      ]);

      // Combine results, ensuring no duplicates
      const fetchedJobs = [
        ...queryArraySnapshot.docs.map((doc) => ({ job: doc.data() })),
        ...querySingleSnapshot.docs
          .map((doc) => ({ job: doc.data() }))
          .filter(
            (job) =>
              !queryArraySnapshot.docs.some(
                (arrayDoc) => arrayDoc.id === doc.id
              )
          ),
      ];

      console.log("fetchedJobs: ", fetchedJobs);
      setJobs(fetchedJobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [userData]);

  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0].job);
    }
  }, [jobs]);

  const JobCard = ({ job }) => {
    const [applicationsCount, setApplicationsCount] = useState(0);
    const isSelected = selectedJob && selectedJob.id === job.id;

    const fetchApplicationsCount = async () => {
      try {
        const applicationsRef = collection(
          db,
          "opportunities",
          job.id,
          "applications"
        );
        const applicationsSnapshot = await getDocs(applicationsRef);
        setApplicationsCount(applicationsSnapshot.size);
      } catch (error) {
        console.error("Error fetching applications count:", error);
      }
    };

    useEffect(() => {
      fetchApplicationsCount();
    }, [job.id]);

    return (
      <div
        className={`flex w-full flex-col items-end justify-start gap-[5px] rounded-mini border bg-white ${
          isSelected ? "job-card-selected" : "job-card"
        }`}
        onClick={() => setSelectedJob(job)}
      >
        <div className="w-full px-4 py-2">
          <div className="flex flex-col gap-1">
            <div className="flex w-full justify-between">
              <div className="w-2/3 space-y-1">
                <b className="block truncate text-base">{job.title}</b>
                <div className="flex items-center gap-1">
                  <span className="text-xs font-medium">
                    Application Deadline:
                  </span>
                  <span className="text-xs">
                    {convertToDate(job.applicationDeadline)}
                  </span>
                </div>
                <p className="text-xs">{job.location}</p>

                <div className="flex w-fit rounded bg-gray-300 p-2 text-xs font-semibold">
                  {job.jobType}
                </div>
              </div>
              <img
                className="z-[1] h-[75px] w-[75px] object-cover"
                alt={job.title}
                src={job.backgroundImage || placeholderImage}
              />
            </div>
            <div className="text-sm">
              {job.jobDescription.length > 220
                ? `${job.jobDescription.substring(0, 220)}...`
                : job.jobDescription}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleApply = () => {
    if (!userData || !userData.id) {
      return;
    }
    setIsApplyModalOpen(true);
  };

  const ApplyModal = ({ isOpen, onClose, selectedJob, userData }) => {
    const [formData, setFormData] = useState({
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      email: userData?.email || "",
      mobile_number: userData?.mobile_number || "",
      applicationLetter: "",
      attachments: [],
    });

    const [isUploading, setIsUploading] = useState(false);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleRemoveAttachment = (index) => {
      setFormData((prev) => ({
        ...prev,
        attachments: prev.attachments.filter((_, i) => i !== index),
      }));
    };

    const handleFileUpload = (e) => {
      const files = Array.from(e.target.files);

      setIsUploading(true);

      Promise.all(
        files.map(async (file) => {
          const storage = getStorage();
          const storageRef = ref(storage, `attachments/${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              null,
              (error) => reject(error),
              async () => {
                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref
                );
                resolve({ name: file.name, url: downloadURL });
              }
            );
          });
        })
      )
        .then((uploadedFiles) => {
          setFormData((prev) => ({
            ...prev,
            attachments: [...prev.attachments, ...uploadedFiles],
          }));
        })
        .catch((error) => console.error("Error uploading files:", error))
        .finally(() => setIsUploading(false));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const applicationsRef = doc(
          db,
          "opportunities",
          selectedJob.id,
          "applications",
          userData.id
        );

        await setDoc(
          applicationsRef,
          {
            ...formData,
            userId: userData.id,
            appliedAt: new Date(),
          },
          { merge: true }
        );

        onClose();
      } catch (error) {
        console.error("Error submitting application:", error);
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-[35%] rounded-lg bg-white shadow-lg">
          <div className="flex items-center justify-between bg-[#467BA4] p-4">
            <h2 className="text-[#FDB841]">Apply for {selectedJob.title}</h2>
            <button
              className="font-Inter cursor-pointer bg-[#467BA4] text-[25px] text-[#FDB841]"
              onClick={onClose}
            >
              ×
            </button>
          </div>
          <div className="max-h-[80vh] overflow-y-auto p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-2">
                <div className="flex w-full gap-2">
                  <TextBoxComponent
                    name="first_name"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    required
                  />
                  <TextBoxComponent
                    name="last_name"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    required
                  />
                </div>
                <div className="flex w-full gap-2">
                  <TextBoxComponent
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    required
                  />
                  <TextBoxComponent
                    name="mobile_number"
                    placeholder="Phone Number"
                    value={formData.mobile_number}
                    onChange={handleInputChange}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    required
                  />
                </div>
                <div>
                  <TextAreaComponent
                    name="applicationLetter"
                    placeholder="Application Letter"
                    value={formData.applicationLetter}
                    onChange={handleInputChange}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                    required
                  />
                </div>
                <div>
                  <p className="font-Inter mb-2 text-sm font-semibold text-[#467BA4]">
                    Attachments (PDF, Images)
                  </p>
                  <input
                    type="file"
                    multiple
                    accept=".pdf,image/*"
                    id="attachmentsUpload"
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                  <label
                    htmlFor="attachmentsUpload"
                    className="upload-button text-gray-700 hover:bg-gray-50 flex cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium shadow-sm"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    Upload Attachments
                  </label>

                  {isUploading && (
                    <p className="font-Inter mt-2 text-sm text-blue-500">
                      Uploading files...
                    </p>
                  )}

                  {formData.attachments.length > 0 && (
                    <div className="mt-2 space-y-2">
                      {formData.attachments.map((file, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between rounded-md border border-gray-300 p-2 shadow-sm"
                        >
                          <div className="flex items-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#3B82F6"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                            <a
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="max-w-[200px] truncate text-blue-600 underline"
                            >
                              {file.name}
                            </a>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveAttachment(index)}
                            className="text-sm text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-4">
                <button
                  type="button"
                  className="btn-cancel px-4 py-2"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-default px-4 py-2">
                  Submit Application
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-Inter font-bold text-blue-500">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <ApplyModal
        isOpen={isApplyModalOpen}
        onClose={() => setIsApplyModalOpen(false)}
        selectedJob={selectedJob}
        userData={userData}
      />
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <p className="font-Inter relative inline-block min-w-[80px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Opportunities
          </p>
          <div className="font-Inter relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <p className="font-Inter relative inline-block min-w-[77px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Job Opportunities
          </p>
        </div>
      </div>
      <div className="flex h-[calc(100vh-150px)] w-full">
        <div className="job-card-cont w-[400px] min-w-[400px] overflow-y-auto border-r border-solid border-gray-300">
          <div className="border-b border-solid border-gray-300 pb-2">
            <input
              type="text"
              placeholder="Search a Job"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="font-Inter w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-[#467BA4] focus:outline-none"
            />
          </div>
          <div className="job-card-cont grid grid-cols-1 gap-2">
            {filteredJobs.map((job) => (
              <JobCard key={job.job.id} job={job.job} />
            ))}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {selectedJob ? (
            <div className="h-full">
              <div className="w-full">
                <div className="mb-5 flex items-center justify-between border-b border-solid border-gray-300 p-2">
                  <div className="w-full space-y-1">
                    <b className="block truncate text-[25px] text-[#FDB841]">
                      {selectedJob.title}
                    </b>
                    <div className="flex items-center gap-1">
                      <span className="text-base font-medium">
                        Application Deadline:
                      </span>
                      <span className="text-base">
                        {convertToDate(selectedJob.applicationDeadline)}
                      </span>
                    </div>
                    <p className="text-base">{selectedJob.location}</p>
                    <div className="flex w-full items-center space-x-2">
                      <div className="flex w-fit rounded bg-gray-300 p-2 text-base font-semibold">
                        {selectedJob.jobType}
                      </div>
                      <div className="h-10 border-r border-solid border-gray-300"></div>
                      <button
                        className={`flex cursor-pointer gap-1 rounded px-3 py-2 text-base font-semibold ${
                          selectedJob?.closed
                            ? "cursor-not-allowed bg-gray-400 text-white"
                            : "bg-[#467BA4] text-[#FDB841]"
                        }`}
                        type="button"
                        onClick={handleApply}
                        disabled={selectedJob?.closed}
                      >
                        {selectedJob?.closed ? "Job Closed" : "Apply Now"}
                      </button>
                    </div>
                  </div>
                  <img
                    className="h-[150px] w-auto"
                    alt={selectedJob.title}
                    src={selectedJob.backgroundImage || placeholderImage}
                  />
                </div>
                <div className="mb-5">
                  <div className="font-Inter mb-1 flex items-center gap-1 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter mt-2 font-bold">
                      JOB DESCRIPTION
                    </p>
                  </div>

                  <div className="rounded-lg bg-white px-4">
                    <p className="font-Inter text-left text-black">
                      {selectedJob.jobDescription || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="font-Inter mb-1 flex items-center gap-2 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter font-bold">
                      QUALIFICATIONS
                    </p>
                  </div>

                  <div className="rounded-lg bg-white px-4">
                    <p className="font-Inter text-left text-black">
                      {selectedJob.qualifications || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="mb-1 flex justify-between px-4">
                    <div className="flex items-center gap-2">
                      <p className="font-Inter text-sm font-bold">
                        EXPERIENCE REQUIRED:
                      </p>
                      <p className="font-Inter text-black">
                        {selectedJob.experienceRequired || "N/A"} years
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="font-Inter text-sm font-bold">SALARY:</p>
                      <p className="font-Inter text-black">
                        {formatCurrency(selectedJob.salary || "N/A")}
                      </p>
                    </div>
                  </div>

                  <div className="mb-1 flex justify-between px-4">
                    <div className="flex items-center gap-2">
                      <p className="font-Inter text-sm font-bold">
                        EDUCATION REQUIREMENTS:
                      </p>
                      <p className="font-Inter text-black">
                        {selectedJob.educationRequirements || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="font-Inter mb-1 flex items-center gap-2 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter font-bold">BENEFITS</p>
                  </div>
                  <div className="rounded-lg bg-white px-6">
                    <p className="font-Inter text-left text-black">
                      {selectedJob.benefits || "N/A"}
                    </p>
                  </div>
                </div>

                <div className="flex w-full gap-2">
                  {/* Contact Information */}
                  <div className="w-1/2">
                    <div className="mb-2 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12z" />
                      </svg>
                      <p className="text-xl font-Inter font-bold">
                        CONTACT INFORMATION
                      </p>
                    </div>
                    <div className="rounded-lg bg-white px-4">
                      <div className="font-Inter space-y-1 text-left text-black">
                        {[
                          {
                            label: "Contact Name",
                            value: selectedJob.contactName,
                          },
                          {
                            label: "Contact Email",
                            value: selectedJob.contactEmail,
                          },
                          {
                            label: "Contact Phone",
                            value: selectedJob.contactPhone,
                          },
                        ].map((item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <p className="font-bold">{item.label}:</p>
                            <p>{item.value || "N/A"}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Employer Information */}
                  <div className="w-1/2">
                    <div className="mb-2 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12z" />
                      </svg>
                      <p className="text-xl font-Inter font-bold">
                        EMPLOYER INFORMATION
                      </p>
                    </div>
                    <div className="rounded-lg bg-white px-4">
                      <div className="font-Inter space-y-1 text-left text-black">
                        {[
                          {
                            label: "Company Name",
                            value: selectedJob.companyName,
                          },
                          { label: "Location", value: selectedJob.location },
                          { label: "Job Type", value: selectedJob.jobType },
                        ].map((item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <p className="font-bold">{item.label}:</p>
                            <p>{item.value || "N/A"}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="font-Inter flex h-full items-center justify-center text-gray-500">
              Select a job to view details
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

JobOpportunities.propTypes = {
  className: PropTypes.string,
};

export default JobOpportunities;
