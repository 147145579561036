import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Query } from "@syncfusion/ej2-data";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DisasterMgtService from "../../service/citizen/DisasterMgtService";
import disasterCategories from "../../disaster_categories.json";
import { ImageDialog } from "./AssistanceLog";

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
};

const filterSettings = {
  type: "CheckBox",
};

const DisasterManagement = ({ selectedDisasters, activeDisasters }) => {
  const [userRole, setUserRole] = useState(null);
  const [editing, setEditing] = useState({
    allowEditing: false,
    allowAdding: true,
    allowDeleting: false,
    mode: "Dialog",
    dialog: { cssClass: "custom-dialog" },
    allowEditOnDblClick: false,
  });

const createImageEditTemplate = (category) => ({
  create: () => {
    elem = document.createElement("div");
    return elem;
  },
  read: () => {
    return strm;
  },
  destroy: () => {
    if (uploadObj) {
      uploadObj.destroy();
    }
    strm = null;
  },
  write: (args) => {
    const path = {
      removeUrl: "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
      saveUrl: "https://services.syncfusion.com/react/production/api/FileUploader/Save",
    };

    elem.innerHTML = `
      <div class="flex flex-col justify-center w-full">
        <div id="imagesPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
          <!-- Images will be dynamically inserted here -->
        </div>
        <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4 mb-4" style="display: none;">
          <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
        </div>
        <div id="dropArea">
          <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
            <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
            </svg>
            Upload Images
          </label>
          <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
        </div>
      </div>
    `;

    const inputElement = elem.querySelector("#defaultUpload");
    const uploadLabel = elem.querySelector("#uploadLabel");
    const imagesPreviews = elem.querySelector("#imagesPreviews");
    const uploadProgressContainer = elem.querySelector("#uploadProgress");
    const uploadProgressBar = uploadProgressContainer.querySelector("div");

    uploadObj = new Uploader({
      asyncSettings: path,
      success: onUploadSuccess,
      failure: onUploadFailure,
      progress: onUploadProgress,
      multiple: true,
      allowedExtensions: ".jpeg, .png, .jpg",
      cssClass: "hidden",
    });

    uploadObj.appendTo(inputElement);

    uploadLabel.addEventListener("click", () => {
      inputElement.click();
    });

    inputElement.addEventListener("change", async (e) => {
      if (inputElement.files.length > 0) {
        uploadProgressContainer.style.display = "block";
        
        for (const file of inputElement.files) {
          try {
            const compressedFile = await compressImage(file, 1);
            const currentUserId = args.rowData?.editor_ID || userId;
            const filePath = `disaster-mgt/${category}/${currentUserId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(filePath, compressedFile);

            strm = strm 
              ? [...strm, { src: downloadURL, name: file.name }]
              : [{ src: downloadURL, name: file.name }];
            
            displayUploadedImages(strm);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        }
        
        uploadProgressContainer.style.display = "none";
        inputElement.value = ""; 
      }
    });

    function onUploadSuccess(args) {
      console.log("Upload success:", args);
    }

    function onUploadFailure(args) {
      console.error("Upload failed:", args);
    }

    function onUploadProgress(args) {
      const progress = Math.round((args.e.loaded / args.e.total) * 100);
      uploadProgressBar.style.width = `${progress}%`;
    }

    function displayUploadedImages(images) {
      if (!Array.isArray(images)) {
        console.error("Images is not an array:", images);
        return;
      }

      imagesPreviews.innerHTML = images
        .map((image, index) => `
          <div class="relative group">
            <img src="${image.src}" alt="${image.name}" class="w-20 h-20 object-cover rounded-md" />
            <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button onclick="removeImage(${index})" class="text-white hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        `)
        .join("");
    }

    // Function to remove an image
    window.removeImage = (index) => {
      strm.splice(index, 1);
      displayUploadedImages(strm);
    };

    // Display initial images if available
    const photoField = `photo_${category}`;
    if (args.rowData[photoField]) {
      strm = Array.isArray(args.rowData[photoField])
        ? args.rowData[photoField]
        : [args.rowData[photoField]];
      displayUploadedImages(strm);
    } else {
      imagesPreviews.innerHTML = "";
    }
  },
});

  // Use the function for both general and landscaping
  const generalEdit = createImageEditTemplate("general");
  const landscapingEdit = createImageEditTemplate("landscaping");
  const contentsEdit = createImageEditTemplate("contents");
  const vehicleEdit = createImageEditTemplate("vehicle");
  const homeRepairEdit = createImageEditTemplate("home_repair");

  // Update the grid templates
  const gridTemplate = (props) => {
    const images = Array.isArray(props.photo_general)
      ? props.photo_general
      : props.photo_general
      ? [props.photo_general]
      : [];
    return renderImagePreview(images);
  };

  const landscapingTemplate = (props) => {
    const images = Array.isArray(props.photo_landscaping)
      ? props.photo_landscaping
      : props.photo_landscaping
      ? [props.photo_landscaping]
      : [];
    return renderImagePreview(images);
  };

  const contentsTemplate = (props) => {
    const images = Array.isArray(props.photo_contents)
      ? props.photo_contents
      : props.photo_contents
      ? [props.photo_contents]
      : [];
    return renderImagePreview(images);
  };

  const vehicleTemplate = (props) => {
    const images = Array.isArray(props.photo_vehicle)
      ? props.photo_vehicle
      : props.photo_vehicle
      ? [props.photo_vehicle]
      : [];
    return renderImagePreview(images);
  };

  const homeRepairTemplate = (props) => {
    const images = Array.isArray(props.photo_home_repair)
      ? props.photo_home_repair
      : props.photo_home_repair
      ? [props.photo_home_repair]
      : [];
    return renderImagePreview(images);
  };

  // Helper function to render image preview
  const renderImagePreview = (images) => {
    return (
      <div
        className="image-name-container"
        onClick={() => handleImageClick(images)}
        style={{
          cursor: "pointer",
          padding: "10px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          textAlign: "center",
          minWidth: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px" }}
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
        <p className="text-sm">
          {images.length > 0
            ? `${images.length} image${images.length > 1 ? "s" : ""}`
            : "No images"}
        </p>
      </div>
    );
  };

  const { categories, subcategories } = disasterCategories;
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [homeRepairData, setHomeRepairData] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        fetchUserRole(currentUser.uid);
      } else {
        setData(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const role = userDoc.data().user_role;
        setUserRole(role);

        // Set editing permissions based on role
        if (role === "citizen" || role === "partner") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true, // Allow editing for citizens and partners
            allowAdding: true, // Always allow adding
            allowDeleting: false, // Disallow deleting
          }));
        } else if (role === "center") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true, // Allow editing for center role
            allowAdding: true, // Always allow adding
            allowDeleting: true, // Allow deleting for center role
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId, selectedDisasters, activeDisasters]);

  useEffect(() => {
    const fetchLastEditedTimes = async () => {
      const generalTime = await DisasterMgtService.getLastEditedTime(
        userId,
        "general"
      );
      const homeRepairTime = await DisasterMgtService.getLastEditedTime(
        userId,
        "home_repair"
      );
      const landscapingTime = await DisasterMgtService.getLastEditedTime(
        userId,
        "landscaping"
      );
      const vehicleTime = await DisasterMgtService.getLastEditedTime(
        userId,
        "vehicle"
      );
      const contentsTime = await DisasterMgtService.getLastEditedTime(
        userId,
        "contents"
      );

      setLasGeneralEditedTime(generalTime);
      setLastHomeRepairEditedTime(homeRepairTime);
      setLastLandscapingEditedTime(landscapingTime);
      setLastVehicleEditedTime(vehicleTime);
      setLastContentsEditedTime(contentsTime);
    };

    fetchLastEditedTimes();
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        const general = await DisasterMgtService.fetchRecords(
          userId,
          "general"
        );

        const filteredGeneral = general.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });

        setGeneralData(filteredGeneral.filter((item) => !item.is_deleted));

        const home = await DisasterMgtService.fetchRecords(
          userId,
          "home_repair"
        );

        const filteredHome = home.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });

        setHomeRepairData(filteredHome.filter((item) => !item.is_deleted));

        const landscaping = await DisasterMgtService.fetchRecords(
          userId,
          "landscaping"
        );

        const filteredLandscaping = landscaping.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });

        setLandscapingData(
          filteredLandscaping.filter((item) => !item.is_deleted)
        );

        const vehicle = await DisasterMgtService.fetchRecords(
          userId,
          "vehicle"
        );

        const filteredVehicle = vehicle.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });

        setVehicleData(filteredVehicle.filter((item) => !item.is_deleted));

        const contents = await DisasterMgtService.fetchRecords(
          userId,
          "contents"
        );

        const filteredContents = contents.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });

        setContentsData(filteredContents.filter((item) => !item.is_deleted));
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  let elem;
  let uploadObj;
  let strm;
  let selectedRow;

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  // State for General section

  const [isGeneralVisible, setIsGeneralVisible] = useState(true);
  const [lastGeneralEditedTime, setLasGeneralEditedTime] = useState(null);

  const toggleGeneral = (event) => {
    event.preventDefault();
    setIsGeneralVisible(!isGeneralVisible);
  };

  const generalGridRef = useRef(null);

  const handleAddClickGeneral = () => {
    if (generalGridRef.current) {
      generalGridRef.current.addRecord();
    }
  };

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    const storageRef = ref(storage, filePath);

    await uploadString(storageRef, dataUrl, "data_url");

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleImageClick = (images) => {
    setSelectedImages(images);
    setCurrentImageIndex(0);
    setIsImageDialogOpen(true);
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (typeof data[key] === 'boolean') {
          sanitizedData[key] = data[key];
        } else {
          sanitizedData[key] = data[key] ?? "";
        }
      }
    }
    return sanitizedData;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [currentGridRef, setCurrentGridRef] = useState(null);
  const updateColumnVisibility = (gridRef, visible) => {
    if (gridRef.current) {
      const cols = gridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = visible;
        }
      }
      gridRef.current.refreshColumns();
    }
  };

  // Update the showPopup function
  const showPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Hide the "value" column in all grids
    updateColumnVisibility(generalGridRef, true);
    updateColumnVisibility(homeRepairGridRef, true);
    updateColumnVisibility(vehicleGridRef, true);
    updateColumnVisibility(contentsGridRef, true);
    updateColumnVisibility(landscapingGridRef, true);
  };

  const actionBeginGeneral = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      selectedRow = { ...args.rowData };
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.generalID = generateUniqueId();
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.is_deleted = false;
        args.data.last_edited = new Date(); // Add this line

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(userId, sanitizedData, "general");

        // Update the last edited time
        setLasGeneralEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (
            args.rowData.editor_ID !== userId ||
            args.rowData.editor_type !== userRole
          ) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        args.data.last_edited = new Date(); // Add this line for edit action

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        // Update the last edited time
        setLasGeneralEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.generalID;
          const fileUrl = docData.photoGeneral;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "general"
          );
        }

        // Update the last edited time after deletion
        setLasGeneralEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteGeneral = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.generalID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "general"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getGeneral(userId);
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setGeneralData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  //Home Repair

  const [isHomeRepairVisible, setIsHomeRepairVisible] = useState(true);
  const [lastHomeRepairEditedTime, setLastHomeRepairEditedTime] =
    useState(null);

  const toggleHomeRepair = (event) => {
    event.preventDefault();
    setIsHomeRepairVisible(!isHomeRepairVisible);
  };

  const homeRepairGridRef = useRef(null);

  const handleAddClickHomeRepair = () => {
    if (homeRepairGridRef.current) {
      homeRepairGridRef.current.addRecord();
    }
  };

  //

  const actionBeginHomeRepair = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.homeRepairID = generateUniqueId();
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.is_deleted = false;
        args.data.last_edited = new Date(); // Add this line

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          userId,
          sanitizedData,
          "home_repair"
        );

        // Update the last edited time
        setLastHomeRepairEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (
            args.rowData.editor_ID !== userId ||
            args.rowData.editor_type !== userRole
          ) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }

        args.data.last_edited = new Date(); // Add this line for edit action

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        // Update the last edited time
        setLastHomeRepairEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.homeRepairID;
          const fileUrl = docData.photo_home_repair;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "home_repair"
          );
        }

        // Update the last edited time after deletion
        setLastHomeRepairEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteHomeRepair = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.homeRepairID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "home_repair"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getHomeRepair(userId);
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setHomeRepairData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };
  //Landscaping
  const [landscapingData, setLandscapingData] = useState([]);
  const [isLandscapingVisible, setIsLandscapingVisible] = useState(true);
  const [lastLandscapingEditedTime, setLastLandscapingEditedTime] =
    useState(null);

  const toggleLandscaping = (event) => {
    event.preventDefault();
    setIsLandscapingVisible(!isLandscapingVisible);
  };

  const landscapingGridRef = useRef(null);

  const handleAddClickLandscaping = () => {
    if (landscapingGridRef.current) {
      landscapingGridRef.current.addRecord();
    }
  };

  const actionBeginLandscaping = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.landscapingID = generateUniqueId();
        args.data.is_deleted = false;
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.last_edited = new Date(); // Add this line

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          userId,
          sanitizedData,
          "landscaping"
        );

        // Update the last edited time
        setLastLandscapingEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (
            args.rowData.editor_ID !== userId ||
            args.rowData.editor_type !== userRole
          ) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        args.data.last_edited = new Date(); // Add this line for edit action

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        // Update the last edited time
        setLastLandscapingEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.landscapingID;
          const fileUrl = docData.photo_landscaping;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "landscaping"
          );
        }

        // Update the last edited time after deletion
        setLastLandscapingEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteLandscaping = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.landscapingID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "landscaping"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getLandscaping(userId);
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setLandscapingData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  //Vehicle
  const [vehicleData, setVehicleData] = useState([]);
  const [isVehicleVisible, setIsVehicleVisible] = useState(true);
  const [lastVehicleEditedTime, setLastVehicleEditedTime] = useState(null);

  const toggleVehicle = (event) => {
    event.preventDefault();
    setIsVehicleVisible(!isVehicleVisible);
  };

  const vehicleGridRef = useRef(null);

  const handleAddClickVehicle = () => {
    if (vehicleGridRef.current) {
      vehicleGridRef.current.addRecord();
    }
  };

  const actionBeginVehicle = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.vehicleID = generateUniqueId();
        args.data.is_deleted = false;
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.last_edited = serverTimestamp();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(userId, sanitizedData, "vehicle");

        // Update the last edited time
        setLastVehicleEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (
            args.rowData.editor_ID !== userId ||
            args.rowData.editor_type !== userRole
          ) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        args.data.last_edited = new Date(); // Add this line for edit action

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = args.data.vehicleID;
        const sanitizedData = sanitizeData(args.data);

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "vehicle"
        );

        // Update the last edited time
        setLastVehicleEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.vehicleID;
          const fileUrl = docData.photo_vehicle;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "vehicle"
          );
        }

        // Update the last edited time after deletion
        setLastVehicleEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteVehicle = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.vehicleID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "vehicle"
        );
      }

      // Refresh the grid data
      try {
        // Assuming there's a method called getGeneral. Replace with the correct method name if different.
        const allData = await DisasterMgtService.getVehicle(userId);
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setVehicleData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  //Contents
  const [contentsData, setContentsData] = useState([]);
  const [isContentsVisible, setIsContentsVisible] = useState(true);
  const [lastContentsEditedTime, setLastContentsEditedTime] = useState(null);

  const toggleContents = (event) => {
    event.preventDefault();
    setIsContentsVisible(!isContentsVisible);
  };

  const contentsGridRef = useRef(null);

  const handleAddClickContents = () => {
    if (contentsGridRef.current) {
      contentsGridRef.current.addRecord();
    }
  };

  const actionBeginContents = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.is_deleted = false;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.contentsID = generateUniqueId();
        args.data.is_deleted = false;
        args.data.last_edited = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(userId, sanitizedData, "contents");

        // Update the last edited time
        setLastContentsEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (
            args.rowData.editor_ID !== userId ||
            args.rowData.editor_type !== userRole
          ) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        args.data.last_edited = new Date();
        args.data.is_deleted = false;
        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = args.data.contentsID;
        const sanitizedData = sanitizeData(args.data);

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "contents"
        );

        // Update the last edited time
        setLastContentsEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.contentsID;
          const fileUrl = docData.photo_contents;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() },
            "contents"
          );
        }

        // Update the last edited time after deletion
        setLastContentsEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteContents = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.contentsID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "contents"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getContents(userId);
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setContentsData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  const [disasterMap, setDisasterMap] = useState({});
  const [dialogContent, setDialogContent] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = {
            name: doc.data().disaster_name,
            status: doc.data().disaster_status
          };
          return acc;
        }, {});
  
        setDisasterMap(fetchedDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };
  
    fetchDisasters();
  }, []);

  const categoryTemplate = (props) => {
    const disasterCount = props.associatedDisasters
      ? props.associatedDisasters.length
      : 0;

    let editorTypeColor = "";
    let editorTypeLetter = "";
    switch (props.editor_type) {
      case "center":
        editorTypeColor = "bg-green-500";
        editorTypeLetter = "C";
        break;
      case "partner":
        editorTypeColor = "bg-yellow-500";
        editorTypeLetter = "P";
        break;
      case "citizen":
        editorTypeColor = "bg-blue-500";
        editorTypeLetter = "C";
        break;
      default:
        editorTypeColor = "bg-gray-500";
        editorTypeLetter = "?";
    }

    return (
      <div className="flex items-center">
        <span className="mr-2">{props.sub_category}</span>
        <div className="flex items-center space-x-1">
          {/* <span 
            className={`w-6 h-6 rounded-full ${editorTypeColor} flex items-center justify-center text-white text-xs font-bold`}
            title={`Editor Type: ${props.editor_type}`}
          >
            {editorTypeLetter}
          </span> */}
          {disasterCount > 0 && (
            <span
              className="cursor-pointer rounded bg-red-500 px-2 py-1 text-xs font-semibold text-white hover:bg-red-600"
              onClick={() => handleDisasterClick(props.associatedDisasters)}
              title={`${disasterCount} Associated Disaster${
                disasterCount > 1 ? "s" : ""
              }`}
            >
              {disasterCount}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleDisasterClick = (disasterIds) => {
    if (disasterIds && disasterIds.length > 0) {
      const disasterList = disasterIds
        .map(
          (id, index) =>
            `${index + 1}. ${disasterMap[id] || "Unknown Disaster"}`
        )
        .join("\n");
      setDialogContent(disasterList);
      setIsDialogOpen(true);
    } else {
      setDialogContent("No associated disasters.");
      setIsDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="relative flex w-full flex-col items-start justify-center leading-[normal] tracking-[normal]">
      {isDialogOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
            <h2 className="text-xl bg-blue-500 p-4 font-bold text-white">
              Associated Disasters
            </h2>
            <div className="px-6 py-4">
              <pre className="whitespace-pre-wrap text-base">
                {dialogContent}
              </pre>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={closeDialog}
                  className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p>
          The Damage Assessment form is a critical tool for evaluating the
          impact of a disaster on your community. Your responses will help
          prioritize recovery efforts and allocate resources where they are
          needed most. Please take a moment to accurately complete the form, as
          your input is essential to guiding effective disaster response and
          rebuilding efforts.
        </p>
      </div>
      <div className="w-full">
        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 text-sm font-bold text-blue-500">General</h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastGeneralEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
            {!selectedDisasters.some(id => disasterMap[id]?.status === "closed") && (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={handleAddClickGeneral}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>
            )}

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleGeneral}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isGeneralVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isGeneralVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={generalGridRef}
                dataSource={generalData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginGeneral}
                actionComplete={actionCompleteGeneral}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="generalID"
                    headerText="generalID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "1")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "1")
                          .map((sub) => sub.subcategoryName),
                        fields: { text: "sub_category", value: "sub_category" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="120"
                    format="C"
                    textAlign="Right"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="photo_general"
                    headerText="Photo Attachment"
                    width="120"
                    template={gridTemplate}
                    edit={generalEdit}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    visible={!selectedDisasters.some(id => disasterMap[id]?.status === "closed")}
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 text-sm font-bold text-blue-500">
                Home Repair and Replacement
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastHomeRepairEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
            {!selectedDisasters.some(id => disasterMap[id]?.status === "closed") && (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={handleAddClickHomeRepair}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>
            )}
              

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleHomeRepair}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isHomeRepairVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isHomeRepairVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={homeRepairGridRef}
                dataSource={homeRepairData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginHomeRepair}
                actionComplete={actionCompleteHomeRepair}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="homeRepairID"
                    headerText="homeRepairID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "2")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "2")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="120"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="photo_home_repair"
                    headerText="Photo Attachment"
                    width="120"
                    template={homeRepairTemplate}
                    edit={homeRepairEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    visible={!selectedDisasters.some(id => disasterMap[id]?.status === "closed")}
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Landscaping
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastLandscapingEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
            {!selectedDisasters.some(id => disasterMap[id]?.status === "closed") && (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={handleAddClickLandscaping}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>
            )}
              

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleLandscaping}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isLandscapingVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isLandscapingVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={landscapingGridRef}
                dataSource={landscapingData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginLandscaping}
                actionComplete={actionCompleteLandscaping}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="landscapingID"
                    headerText="landscapingID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "3")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "3")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="120"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="photo_landscaping"
                    headerText="Photo Attachment"
                    width="120"
                    template={landscapingTemplate}
                    edit={landscapingEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    visible={!selectedDisasters.some(id => disasterMap[id]?.status === "closed")}
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Vehicle(s)
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastVehicleEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              {!selectedDisasters.some(id => disasterMap[id]?.status === "closed") && (
                <button
                  type="button"
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={handleAddClickVehicle}
                >
                  <FaPlus className="h-3 w-3" />
                  Add
                </button>
              )}
              

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleVehicle}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isVehicleVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isVehicleVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={vehicleGridRef}
                dataSource={vehicleData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginVehicle}
                actionComplete={actionCompleteVehicle}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="vehicleID"
                    headerText="vehicleID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "4")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "4")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="120"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="photo_vehicle"
                    headerText="Photo Attachment"
                    width="120"
                    template={vehicleTemplate}
                    edit={vehicleEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    visible={!selectedDisasters.some(id => disasterMap[id]?.status === "closed")}
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Contents
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastContentsEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
            {!selectedDisasters.some(id => disasterMap[id]?.status === "closed") && (
                <button
                  type="button"
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={handleAddClickContents}
                >
                  <FaPlus className="h-3 w-3" />
                  Add
                </button>
              )}
              

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleContents}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isContentsVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isContentsVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={contentsGridRef}
                dataSource={contentsData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginContents}
                actionComplete={actionCompleteContents}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="contentsID"
                    headerText="contentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "5")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "5")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="120"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="photo_contents"
                    headerText="Photo Attachment"
                    width="120"
                    template={contentsTemplate}
                    edit={contentsEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    visible={!selectedDisasters.some(id => disasterMap[id]?.status === "closed")}
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>
      </div>
      <ImageDialog
        isOpen={isImageDialogOpen}
        onClose={() => setIsImageDialogOpen(false)}
        images={selectedImages}
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
      />
      <CustomPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        message={popupMessage}
      />
    </div>
  );
};

const CustomPopup = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
        <h2 className="text-xl bg-blue-500 p-4 font-bold text-white">Error</h2>
        <div className="px-6 py-4">
          <p className="text-lg">{message}</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisasterManagement;
