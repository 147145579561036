import React, { useState, useRef, useEffect } from "react";
import LockImage from "../../assets/lockbox.png";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  GridComponent,
  ColumnDirective,
  CommandColumn,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import { ImageDialog } from "../Disasters/AssistanceLog";
import LockBoxService from "../../service/citizen/LockboxService";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
  uploadBytes,
} from "firebase/storage";
import { Query } from "@syncfusion/ej2-data";
import { encrypt, decrypt } from "../../service/encryption/Encryption";
import ShowPin from "../Profile-Popup/ShowPin";

const sortSettings = {
  allowSorting: true,
};

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",

  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

const documentTypes = [
  { idName: "ID", id: "1" },
  { idName: "Credit Cards/Banking Information", id: "2" },
  { idName: "Insurance Information", id: "3" },
  { idName: "Photos", id: "4" },
  { idName: "Titles/Deed/Registration", id: "5" },
];

const typeParams = {
  params: {
    allowFiltering: true,
    dataSource: documentTypes,
    fields: { text: "idName", value: "idName" },
    query: new Query(),
  },
};

const PartnerLockbox = ({ className = "", onNavigateBack }) => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState("");
  const [correctPIN, setCorrectPIN] = useState("1111");
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [PhotosData, setPhotosData] = useState([]);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPinInstructions, setShowPinInstructions] = useState(
    localStorage.getItem("pinMessageShown") !== "true"
  );
  const [pinShow, setPinShow] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);

        const userDocc = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocc);
        // Fetch the user's PIN
        const userDocRef = doc(db, "partners", userDocSnap.data().partner_id);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().pin) {
          setCorrectPIN(decrypt(userDoc.data().pin));
          if (decrypt(userDoc.data().pin) === "7777") {
            setTimeout(() => setPinShow(true), 100);
          }
        }
        // If no PIN is set, it will use the default '1111'
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        console.log(data);

        const documents = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "documents_data"
        );

        const photos = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "photos_data"
        );

        const activeDocuments = documents.filter(
          (doc) => doc.is_deleted !== true
        );

        setDocumentsData(activeDocuments);

        const activePhotos = photos.filter(
          (photo) => photo.is_deleted !== true
        );

        setPhotosData(activePhotos);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleUnlock = async () => {
    if (enteredPIN === correctPIN) {
      setIsUnlocked(true);
      setShowPinInstructions(false);
      localStorage.setItem("pinMessageShown", "true");
    } else {
      setWrongPinMessage(true);
      setEnteredPIN("");
      setInputValues(["", "", "", ""]);
      inputRefs.current[0].focus();

      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000);
    }
  };

  const onInput = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
      const newValues = [...inputValues];
      newValues[index] = value;
      setInputValues(newValues);
      setEnteredPIN(newValues.join(""));

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onKeyDown = (index, event) => {
    if (event.key === "Backspace" && !inputValues[index]) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  const handleGoBack = () => {
    // Call the onNavigateBack function passed as a prop
    window.history.back();
    window.history.back();
  };

  let elem;
  let uploadObj;
  let strm;
  let selectedRow;
  const accessOptions = [
    { value: "Citizen", text: "Citizen" },
    { value: "Partners", text: "Partners" },
    { value: "DRC Center Arizona", text: "DRC Center Arizona" },
    { value: "DRC Center Pennsylvania", text: "DRC Center Pennsylvania" },
  ];
  const fields = { value: "value", text: "text" };

  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [wrongPinMessage, setWrongPinMessage] = useState(false);
  const inputRefs = useRef([]);

  const correctPin = "1111"; // Set your correct PIN here

  const checkPin = (newValues) => {
    const enteredPin = newValues.join("");
    if (enteredPin === correctPin) {
      setIsPinCorrect(true);
      setWrongPinMessage(false);
    } else if (enteredPin.length === 4) {
      setWrongPinMessage(true);
      // Clear input values
      setInputValues(["", "", "", ""]);
      // Focus on the first input
      inputRefs.current[0].focus();
      // Set a timeout to clear the wrong PIN message
      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000); // Message will disappear after 3 seconds
    } else {
      setWrongPinMessage(false);
    }
  };

  {
    /* Documents */
  }
  const [documentAccessData, setDocumentAccessData] = useState({ access: [] });

  const accessEditTemplateDocuments = (props) => {
    const currentAccess = (props && props.access) || [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const [isDocumentsVisible, setIsDocumentsVisible] = useState(true);
  const [maxDocumentsID, setMaxDocumentsID] = useState(
    documentsData.length > 0
      ? Math.max(...documentsData.map((item) => item.documentsID))
      : 0
  );

  const toggleDocuments = (event) => {
    event.preventDefault();
    setIsDocumentsVisible(!isDocumentsVisible);
  };

  const PhotosGridRef = useRef(null);

  const handleAddClickdocuments = () => {
    if (documentGridRef.current) {
      documentGridRef.current.addRecord();
    }
  };

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          // Resize the image maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          // Compress the image by reducing quality until it's below maxSizeMB
          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    const timestamp = Date.now();
    const uniqueFilePath = `${filePath.split(".")[0]}_${timestamp}.${
      filePath.split(".")[1]
    }`;
    const storageRef = ref(storage, uniqueFilePath);

    await uploadString(storageRef, dataUrl, "data_url");

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  async function uploadFileToStorage(filePath, fileData, fileType) {
    const storage = getStorage();
    const timestamp = Date.now();
    const uniqueFilePath = `${filePath.split(".")[0]}_${timestamp}.${
      filePath.split(".")[1]
    }`;
    const storageRef = ref(storage, uniqueFilePath);

    if (fileType.startsWith("image/")) {
      await uploadString(storageRef, fileData, "data_url");
    } else {
      await uploadBytes(storageRef, fileData);
    }

    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  }

  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const handleFileClick = (files) => {
    if (Array.isArray(files) && files.length > 0) {
      setSelectedFiles(files);
      setCurrentFileIndex(0);
      setIsFileDialogOpen(true);
    }
  };

  // Add this component to your render method
  const FileDialog = ({
    isOpen,
    onClose,
    files,
    currentIndex,
    setCurrentIndex,
  }) => {
    if (!isOpen || !Array.isArray(files) || files.length === 0) return null;

    const file = files[currentIndex];
    const isImage = file.type && file.type.startsWith("image/");
    const fileName = file.name || "Untitled File";

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : files.length - 1
      );
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex < files.length - 1 ? prevIndex + 1 : 0
      );
    };

    const handleBackgroundClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };

    return (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
        onClick={handleBackgroundClick}
      >
        <div className="relative flex h-[80vh] w-full max-w-4xl flex-col">
          <div className="absolute left-0 right-0 top-0 flex items-center justify-between rounded-t-lg bg-black bg-opacity-50 p-4 text-white">
            <h2 className="text-xl truncate font-bold">{fileName}</h2>
            <button
              onClick={onClose}
              className="text-3xl flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
            >
              &times;
            </button>
          </div>
          <div className="relative mt-16 flex-grow bg-black">
            {isImage ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  src={file.src}
                  alt={fileName}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
            ) : (
              <iframe
                src={file.src}
                title={fileName}
                className="absolute inset-0 h-full w-full"
              />
            )}
            {files.length > 1 && (
              <>
                <button
                  onClick={handlePrev}
                  className="absolute left-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
                >
                  &lt;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
                >
                  &gt;
                </button>
              </>
            )}
          </div>
          <div className="flex items-center justify-between rounded-b-lg bg-black bg-opacity-50 p-4 text-white">
            <p className="text-sm">
              {isImage ? "Image" : "Document"} {currentIndex + 1} of{" "}
              {files.length}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const documentsTemplate = (props) => {
    const files = Array.isArray(props.photodocuments)
      ? props.photodocuments
      : props.photodocuments
      ? [props.photodocuments]
      : [];

    const documentCount = files.length;

    return (
      <div
        className="image-name-container"
        onClick={() => documentCount > 0 && handleFileClick(files)}
        style={{
          cursor: documentCount > 0 ? "pointer" : "default",
          padding: "5px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          textAlign: "center",
          minWidth: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px" }}
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
          <line x1="16" y1="13" x2="8" y2="13"></line>
          <line x1="16" y1="17" x2="8" y2="17"></line>
          <polyline points="10 9 9 9 8 9"></polyline>
        </svg>
        <p className="text-sm">
          {documentCount > 0
            ? `${documentCount} document${documentCount > 1 ? "s" : ""}`
            : "None"}
        </p>
      </div>
    );
  };

  const documentsEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="documentsPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Documents will be dynamically inserted here -->
          </div>
          
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              Upload Documents
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const documentsPreviews = elem.querySelector("#documentsPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");

      uploadProgressContainer.style.cssText += `
      margin-top: 1rem;
      background-color: #e5e7eb;
      border-radius: 0.5rem;
      overflow: hidden;
    `;

      uploadProgressBar.style.cssText += `
      height: 0.5rem;
      background-color: #3b82f6;
      transition: width 0.3s ease;
      border-radius: 0.5rem;
    `;

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".pdf,.doc,.docx,.jpg,.jpeg,.png",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            // Show progress bar at 90% during processing
            uploadProgressContainer.style.display = "block";
            uploadProgressBar.style.width = "90%";
            uploadProgressBar.style.transition = "width 0.3s ease";

            const file = args.file.rawFile;
            let fileData = file;
            let isImage = file.type.startsWith("image/");

            if (isImage) {
              fileData = await compressImage(file, 1);
            }

            const filePath = `lockbox/documents/${userId}/${file.name}`;
            const downloadURL = await uploadFileToStorage(
              filePath,
              fileData,
              file.type
            );

            strm = strm
              ? [
                  ...strm,
                  { src: downloadURL, name: file.name, type: file.type },
                ]
              : [{ src: downloadURL, name: file.name, type: file.type }];

            uploadProgressBar.style.width = "100%";

            setTimeout(() => {
              displayUploadedDocuments(strm);

              setTimeout(() => {
                uploadProgressContainer.style.display = "none";
                uploadProgressBar.style.width = "0%";
                uploadProgressBar.style.transition = "none";
              }, 300);
            }, 500);
          } catch (error) {
            console.error("Error during upload:", error);
            uploadProgressBar.style.backgroundColor = "#ef4444";
            setTimeout(() => {
              uploadProgressContainer.style.display = "none";
              uploadProgressBar.style.width = "0%";
              uploadProgressBar.style.backgroundColor = "#3b82f6";
            }, 2000);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        const displayProgress = Math.min(progress, 80);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${displayProgress}%`;
        uploadProgressBar.style.transition = "width 0.3s ease";
      }

      function displayUploadedDocuments(documents) {
        if (!Array.isArray(documents)) {
          console.error("Documents is not an array:", documents);
          return;
        }

        documentsPreviews.innerHTML = `
          <div class="loading-placeholder w-20 h-20 bg-gray-100 rounded-md animate-pulse">
            <div class="flex items-center justify-center h-full">
              <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        `;

        setTimeout(() => {
          documentsPreviews.innerHTML = documents
            .map(
              (doc, index) => `
              <div class="relative group">
                <div class="w-20 h-20 flex items-center justify-center bg-gray-100 rounded-md">
                  ${getDocumentIcon(doc.type)}
                </div>
                <p class="text-xs text-center mt-1 truncate w-20">${
                  doc.name
                }</p>
                <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <button onclick="removeDocument(${index})" class="text-white hover:text-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            `
            )
            .join("");
        }, 300);
      }

      function getDocumentIcon(fileType) {
        if (fileType.startsWith("image/")) {
          return `<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>`;
        } else {
          return `<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>`;
        }
      }

      // Function to remove a document
      window.removeDocument = (index) => {
        strm.splice(index, 1);
        displayUploadedDocuments(strm);
      };

      // Display the initial documents if available
      if (args.rowData.photodocuments) {
        strm = Array.isArray(args.rowData.photodocuments)
          ? args.rowData.photodocuments
          : [args.rowData.photodocuments];
        displayUploadedDocuments(strm);
      } else {
        documentsPreviews.innerHTML = "";
      }
    },
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== undefined) {
        sanitizedData[key] = data[key];
      }
    }
    return sanitizedData;
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const actionBeginDocuments = async (args) => {
    if (args.requestType === "save" && args.action === "add") {
      args.data.documentsID = generateUniqueId();
      args.data.is_deleted = false;
      args.data.date = formatDate(args.data.date || new Date());

      if (args.data.date) {
        const dateObj = new Date(args.data.date);
        args.data.date = dateObj.toLocaleDateString("en-US");
      }

      const sanitizedData = sanitizeData(args.data);
      await LockBoxService.addDocumentToLockbox(
        userId,
        sanitizedData,
        "documents_data"
      );
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.id;
          // const fileUrl = docData.photodocuments;

          // if (fileUrl) {
          //   const storage = getStorage();
          //   const fileRef = ref(storage, fileUrl);

          //   await deleteObject(fileRef);
          // }

          await LockBoxService.softDeleteItemInLockbox(
            userId,
            documentId,
            "documents_data"
          );
        }
        fetchData();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const actionCompleteDocuments = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        const documentId = selectedRow.documentsID;

        if (args.data.date) {
          const dateObj = new Date(args.data.date);
          args.data.date = dateObj.toLocaleDateString("en-US");
        }

        args.data.photodocuments = args.data.photodocuments || [];
        const sanitizedData = sanitizeData(args.data);

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "documents_data"
        );

        const updatedData = documentsData.map((item) =>
          item.documentsID === documentId ? sanitizedData : item
        );

        setDocumentsData(updatedData);
        console.log("Data saved:", updatedData);
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
    if (args.requestType === "beginEdit") {
      const datepicker = args.form.elements["date"];
      if (datepicker && datepicker.ej2_instances[0]) {
        datepicker.ej2_instances[0].value = new Date(args.rowData.date);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  const handleAddClickDocument = () => {
    if (documentGridRef.current) {
      documentGridRef.current.addRecord();
    }
  };

  const accessEditTemplatePhotos = (props) => {
    const currentAccess = (props && props.access) || [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const [isPhotosVisible, setIsPhotosVisible] = useState(true);
  const [maxPhotosID, setMaxPhotosID] = useState(
    PhotosData.length > 0
      ? Math.max(...PhotosData.map((item) => item.PhotosID))
      : 0
  );

  const togglePhotos = (event) => {
    event.preventDefault();
    setIsPhotosVisible(!isPhotosVisible);
  };

  const documentGridRef = useRef(null);

  const handleAddClickPhotos = () => {
    if (PhotosGridRef.current) {
      PhotosGridRef.current.addRecord();
    }
  };

  const photosTemplate = (props) => {
    const images = Array.isArray(props.photoPhotos)
      ? props.photoPhotos
      : props.photoPhotos
      ? [props.photoPhotos]
      : [];
    return renderImagePreview(images, props);
  };

  const handleImageClick = (images, documentData) => {
    console.log("documentData: ", documentData);
    const formattedImages = images.map((image) => {
      if (typeof image === "string") {
        return { src: image };
      }
      return {
        src: image.src || image,
        name: image.name || "",
        originalName: image.originalName || "",
        title: documentData.title || "",
      };
    });
    setSelectedImages(formattedImages);
    setCurrentImageIndex(0);
    setIsImageDialogOpen(true);
  };

  const renderImagePreview = (images, documentData) => {
    return (
      <div
        className="image-name-container"
        onClick={() => handleImageClick(images, documentData)}
        style={{
          cursor: "pointer",
          padding: "5px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          textAlign: "center",
          minWidth: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px" }}
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
        <p className="text-sm">
          {images.length > 0
            ? `${images.length} image${images.length > 1 ? "s" : ""}`
            : "No images"}
        </p>
      </div>
    );
  };

  const PhotosEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="photosPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Photos will be dynamically inserted here -->
          </div>
          
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              Upload Photos
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const photosPreviews = elem.querySelector("#photosPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");

      uploadProgressContainer.style.cssText += `
        margin-top: 1rem;
        background-color: #e5e7eb;
        border-radius: 0.5rem;
        overflow: hidden;
      `;

      uploadProgressBar.style.cssText += `
        height: 0.5rem;
        background-color: #3b82f6;
        transition: width 0.3s ease;
        border-radius: 0.5rem;
      `;

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            // Show progress bar at 90% during processing
            uploadProgressContainer.style.display = "block";
            uploadProgressBar.style.width = "90%";
            uploadProgressBar.style.transition = "width 0.3s ease";

            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `lockbox/photos/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );

            strm = strm
              ? [...strm, { src: downloadURL, name: file.name }]
              : [{ src: downloadURL, name: file.name }];

            // Complete the progress bar
            uploadProgressBar.style.width = "100%";

            // Wait for transition before updating display
            setTimeout(() => {
              displayUploadedPhotos(strm);
              // Reset and hide progress bar
              setTimeout(() => {
                uploadProgressContainer.style.display = "none";
                uploadProgressBar.style.width = "0%";
                uploadProgressBar.style.transition = "none";
              }, 300);
            }, 500);
          } catch (error) {
            console.error("Error during upload:", error);
            // Show error state
            uploadProgressBar.style.backgroundColor = "#ef4444";
            setTimeout(() => {
              uploadProgressContainer.style.display = "none";
              uploadProgressBar.style.width = "0%";
              uploadProgressBar.style.backgroundColor = "#3b82f6";
            }, 2000);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        // Only update progress up to 80% during upload
        const displayProgress = Math.min(progress, 80);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${displayProgress}%`;
        uploadProgressBar.style.transition = "width 0.3s ease";
      }

      function displayUploadedPhotos(photos) {
        if (!Array.isArray(photos)) {
          console.error("Photos is not an array:", photos);
          return;
        }

        // Add loading state
        photosPreviews.innerHTML = `
          <div class="loading-placeholder w-20 h-20 bg-gray-100 rounded-md animate-pulse">
            <div class="flex items-center justify-center h-full">
              <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        `;

        // Use setTimeout to ensure the loading state is visible
        setTimeout(() => {
          photosPreviews.innerHTML = photos
            .map(
              (photo, index) => `
              <div class="relative group">
                <img src="${photo.src}" alt="${photo.name}" class="w-20 h-20 object-cover rounded-md" />
                <p class="text-xs text-center mt-1 truncate w-20">${photo.name}</p>
                <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <button onclick="removePhoto(${index})" class="text-white hover:text-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            `
            )
            .join("");
        }, 300);
      }

      // Function to remove a photo
      window.removePhoto = (index) => {
        strm.splice(index, 1);
        displayUploadedPhotos(strm);
      };

      // Display the initial photos if available
      if (args.rowData.photoPhotos) {
        strm = Array.isArray(args.rowData.photoPhotos)
          ? args.rowData.photoPhotos
          : [args.rowData.photoPhotos];
        displayUploadedPhotos(strm);
      } else {
        photosPreviews.innerHTML = "";
      }
    },
  };

  let grid;
  const actionBeginPhotos = async (args) => {
    if (PhotosGridRef.current && args.requestType === "beginEdit") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate") {
          col.visible = false; // Hide modifiedDate when editing
        }
      }
    }

    if (PhotosGridRef.current && args.requestType === "add") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate") {
          col.visible = false; // Hide modifiedDate when adding
        }
      }
    }

    if (PhotosGridRef.current && args.requestType === "save") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate") {
          col.visible = true; // Show modifiedDate after saving
        }
      }
    }

    if (args.requestType === "save" && args.action === "add") {
      args.data.PhotosID = generateUniqueId();
      args.data.is_deleted = false;

      // Format the modifiedDate field to MM/DD/YYYY
      const dateObj = new Date();
      args.data.modifiedDate = dateObj.toLocaleDateString("en-US");

      const sanitizedData = sanitizeData(args.data);

      try {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "photos_data"
        );
        console.log("Photo added successfully:", args.data);
      } catch (error) {
        console.error("Error adding photo:", error);
      }
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = args.data[0].id;
          const fileUrl = docData.photoPhotos;

          // if (fileUrl) {
          //   const storage = getStorage();
          //   const fileRef = ref(storage, fileUrl);

          //   await deleteObject(fileRef);
          //   console.log("File deleted from storage:", fileUrl);
          // }

          console.log("Attempting to delete document with ID:", documentId);

          await LockBoxService.softDeleteItemInLockbox(
            userId,
            args.data[0].id,
            "photos_data"
          );
          console.log("Photo status set to true:", documentId);
        }
        // Refresh the grid data after updating
        fetchData();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const actionCompletePhotos = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        const documentId = selectedRow.PhotosID;

        if (args.data.modifiedDate) {
          const dateObj = new Date(args.data.modifiedDate);
          args.data.modifiedDate = dateObj.toLocaleDateString("en-US");
        }

        args.data.photoPhotos = args.data.photoPhotos || [];
        const sanitizedData = sanitizeData(args.data);

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "photos_data"
        );

        const updatedData = PhotosData.map((item) =>
          item.PhotosID === documentId ? sanitizedData : item
        );

        setPhotosData(updatedData);
        console.log("Photo updated successfully:", updatedData);
      } catch (error) {
        console.error("Error updating photo:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  if (!isUnlocked) {
    return (
      <>
        {pinShow ? (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
            <ShowPin
              isOpen={pinShow}
              onClose={() => setPinShow(false)}
              firstTime={true}
            />
          </div>
        ) : (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
            <div className="absolute right-4 top-4">
              <button
                onClick={() => setShowPinInstructions(!showPinInstructions)}
                className="hover:bg-gray-100 inline-flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-sm text-gray-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Show PIN instructions"
              >
                ?
              </button>
              {showPinInstructions && (
                <div className="absolute right-0 top-10 z-10 w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
                  <p className="text-gray-600 mb-2 text-sm font-medium">
                    To view your Lockbox PIN:
                  </p>
                  <ol className="text-gray-600 ml-4 list-decimal text-left text-sm">
                    <li>Click on your profile icon in the top right</li>
                    <li>Select "Show Lockbox PIN"</li>
                    <li>Enter your account password to reveal the PIN</li>
                  </ol>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
              <img
                src={LockImage}
                alt="Lockbox"
                className="mb-6 h-[290px] w-[290px]"
              />

              <div className="mb-4 text-center text-sm font-medium text-red-600">
                <strong>Note:</strong> For secure access, your Lockbox PIN can
                be viewed in "My Profile" menu <br />
                by selecting 'Show Lockbox PIN'.
              </div>

              <div className="mb-4 flex space-x-8">
                {inputValues.map((value, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength="1"
                    className="lockbox-input text-2xl h-12 w-12 rounded-xl border border-gray-300 bg-gray-300 text-center"
                    pattern="[0-9]"
                    value={value}
                    onChange={(event) => onInput(index, event)}
                    onKeyDown={(event) => onKeyDown(index, event)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              {wrongPinMessage && (
                <div className="mb-4 text-sm text-red-600">
                  Wrong PIN entered, please try again.
                </div>
              )}
              <div className="flex w-full space-x-4">
                <button
                  onClick={handleGoBack}
                  className="btn-cancel flex flex-1 cursor-pointer items-center justify-center px-4 py-2"
                >
                  <span className="mr-2">←</span> Go Back
                </button>
                <button
                  onClick={handleUnlock}
                  className="btn-default flex flex-1 cursor-pointer items-center justify-center px-4 py-2"
                >
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <g id="Shield Done">
                      <path
                        id="Fill 1"
                        opacity="0.4"
                        d="M9.08649 20.5C8.96266 20.5 8.83884 20.4716 8.72709 20.4137L5.12599 18.5496C4.10415 18.0201 3.30481 17.4259 2.68063 16.7336C1.31449 15.2195 0.554402 13.276 0.542321 11.2599L0.500038 4.62426C0.495004 3.85842 0.989312 3.17103 1.72826 2.91215L8.3405 0.606788C8.73313 0.466564 9.17106 0.464603 9.57073 0.599924L16.2081 2.82684C16.9511 3.07493 17.4535 3.75742 17.4575 4.52228L17.4998 11.1628C17.5129 13.176 16.779 15.1274 15.434 16.6581C14.8168 17.3602 14.0245 17.9632 13.0128 18.5025L9.44388 20.4088C9.33314 20.4686 9.21032 20.499 9.08649 20.5Z"
                        fill="white"
                      />
                      <path
                        id="Fill 4"
                        d="M8.31936 12.8209C8.12607 12.8219 7.93278 12.7523 7.78378 12.6091L5.86695 10.7656C5.57097 10.4793 5.56795 10.0145 5.86091 9.72617C6.15387 9.4369 6.63207 9.43396 6.92906 9.71931L8.30829 11.0451L11.6758 7.72479C11.9698 7.43552 12.448 7.43258 12.744 7.71793C13.041 8.00426 13.044 8.47004 12.751 8.75735L8.85193 12.6022C8.70494 12.7474 8.51266 12.8199 8.31936 12.8209Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="mt-2 flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Lockbox
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">DOCUMENTS</h2>
            <p className="relative mt-2  text-sm font-medium leading-[21px]">
              The Lockbox is a secure space for nonprofit partners to store
              critical documents related to disaster preparedness and recovery.
              Safely uploading and organizing these documents ensures easy
              access during emergencies and promotes efficient coordination
              across all recovery efforts. Please utilize the Lockbox to protect
              and manage your important files.
            </p>
          </div>
        </header>
        <div className="mt-2 flex w-full items-center justify-end">
          <button
            type="button"
            className="btn-default flex cursor-pointer gap-2 px-3 py-1.5"
            onClick={handleAddClickDocument}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div>

        <div className="mt-2 flex flex-col gap-4 self-stretch">
          <GridComponent
            ref={documentGridRef}
            dataSource={documentsData}
            allowSorting={true}
            sortSettings={sortSettings}
            editSettings={editing}
            filterSettings={filterSettings}
            allowFiltering={true}
            actionBegin={actionBeginDocuments}
            actionComplete={actionCompleteDocuments}
            allowPaging={true}
            pageSettings={pageSettings}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="documentsID"
                headerText="documentsID"
                isPrimaryKey={true}
                visible={false}
              />
              <ColumnDirective
                field="is_deleted"
                headerText="Status"
                visible={false}
              />
              <ColumnDirective
                field="type"
                headerText="Type"
                width="100"
                editType="dropdownedit"
                edit={typeParams}
              />
              <ColumnDirective
                field="description"
                headerText="Description"
                width="100"
              />
              <ColumnDirective
                field="date"
                headerText="Date"
                width="100"
                format="MM/dd/yyyy"
                editType="datepickeredit"
                edit={datepickerparams}
              />
              <ColumnDirective
                field="photodocuments"
                headerText="Image"
                width="100"
                template={documentsTemplate}
                edit={documentsEdit}
              />
              {/* <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="100"
                    editTemplate={accessEditTemplateDocuments}
                  /> */}
              <ColumnDirective
                headerText="Actions"
                width="120"
                commands={[
                  {
                    type: "Edit",
                    buttonOption: {
                      content: '<i class="fas fa-edit"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                  {
                    type: "Delete",
                    buttonOption: {
                      content: '<i class="fas fa-trash-alt"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                ]}
              />
            </ColumnsDirective>
            <Inject
              services={[Page, Edit, Toolbar, Sort, Filter, ColumnMenu]}
            />
          </GridComponent>
        </div>
      </section>

      <section className="mt-2 flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">PHOTOS</h2>
            <p className="relative mt-2  text-sm font-medium leading-[21px]">
              Securely upload images of your building and facilities before a
              disaster strikes. These photos serve as important documentation to
              support recovery efforts and insurance claims in the event of
              damage. Please take the time to upload clear, current images to
              safeguard your assets.
            </p>
          </div>
        </header>
        <div className="flex w-full justify-end">
          <button
            type="button"
            className="btn-default flex cursor-pointer gap-2 px-3 py-1.5"
            onClick={handleAddClickPhotos}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div>

        <div className="mt-2 flex flex-col gap-4 self-stretch">
          <GridComponent
            ref={PhotosGridRef}
            dataSource={PhotosData}
            allowSorting={true}
            sortSettings={sortSettings}
            editSettings={editing}
            filterSettings={filterSettings}
            allowFiltering={true}
            actionComplete={actionCompletePhotos}
            actionBegin={actionBeginPhotos}
            allowPaging={true}
            pageSettings={pageSettings}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="PhotosID"
                headerText="PhotosID"
                isPrimaryKey={true}
                visible={false}
              />
              <ColumnDirective
                field="is_deleted"
                headerText="Status"
                visible={false}
              />
              <ColumnDirective field="title" headerText="Title" width="100" />
              <ColumnDirective
                field="description"
                headerText="Description"
                width="100"
              />
              <ColumnDirective
                field="photoPhotos"
                headerText="Image"
                width="100"
                template={photosTemplate}
                edit={PhotosEdit}
              />

              <ColumnDirective
                field="modifiedDate"
                headerText="Modified"
                width="100"
                format="MM/dd/yyyy"
                editType="datepickeredit"
                edit={datepickerparams}
                visible={true}
              />
              <ColumnDirective
                field="access"
                headerText="People with Access"
                width="100"
                editTemplate={accessEditTemplatePhotos}
              />
              <ColumnDirective
                headerText="Actions"
                width="120"
                commands={[
                  {
                    type: "Edit",
                    buttonOption: {
                      content: '<i class="fas fa-edit"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                  {
                    type: "Delete",
                    buttonOption: {
                      content: '<i class="fas fa-trash-alt"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                ]}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Edit,
                Toolbar,
                Sort,
                Filter,
                ColumnMenu,
                CommandColumn,
              ]}
            />
          </GridComponent>
        </div>
      </section>
      <FileDialog
        isOpen={isFileDialogOpen}
        onClose={() => setIsFileDialogOpen(false)}
        files={selectedFiles}
        currentIndex={currentFileIndex}
        setCurrentIndex={setCurrentFileIndex}
      />
      <ImageDialog
        isOpen={isImageDialogOpen}
        onClose={() => setIsImageDialogOpen(false)}
        images={selectedImages}
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
      />
    </div>
  );
};

export default PartnerLockbox;
