import React, { useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../pages/Disasters/DisasterContext";
import DisasterManagement from "./Disasters/DisasterManagement";
import AssistanceLog from "./Disasters/AssistanceLog";
import RecoveryPlanDisasters from "./Disasters/RecoveryPlan";
import Journals from "./Disasters/Journals";
import ContactsDisaster from "./Disasters/ContactsDisaster";
import ImmediateResponseSurvey from "./Disasters/ImmediateResponseSurvey";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const Disasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("All");

  const [selectedDisasters, setSelectedDisasters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);
  const [showInactive, setShowInactive] = useState(false);
  const [showBlur, setShowBlur] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        setIsLoading(true);

        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        const userCounty = userDoc.data()?.county;

        if (!userCounty) {
          console.warn("User county not found");
          setIsLoading(false);
          return;
        }

        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            name: doc.data().disaster_name,
            status: doc.data().disaster_status,
            disaster_type: doc.data().disaster_type,
            county: doc.data().disaster_county,
            state: doc.data().disaster_state,
            affected_counties: doc.data().affected_counties || [],
            disaster_stage: doc.data().disaster_stage,
          }))
          .filter((disaster) => {
            return disaster.affected_counties.includes(userCounty);
          });

        const activeDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "active"
        );
        const inactiveDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "inactive"
        );
        const closedDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "closed"
        );

        const sortedDisasters = [
          ...activeDisasters,
          ...inactiveDisasters,
          ...closedDisasters,
        ];

        setTimeout(() => {
          setShowBlur(activeDisasters.length === 0);
          setIsLoading(false);
        }, 1000);

        setActiveDisasters(activeDisasters.map((disaster) => disaster.id));
        setDisasterOptions(sortedDisasters);
        if (activeDisasters.length > 0) {
          setSelectedDisasters([activeDisasters[0].id]);
        }
      } catch (error) {
        console.error("Error fetching disasters:", error);
        setTimeout(() => {
          setShowBlur(true);
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchDisasters();
  }, [auth.currentUser?.uid]);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      if (disaster === "All") {
        return ["All"];
      }
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster && d !== "All")
        : [...prev.filter((d) => d !== "All"), disaster];
      return newSelection.length === 0 ? ["All"] : newSelection;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [caseNumber, setCaseNumber] = useState("");

  useEffect(() => {
    const fetchCaseNumber = async () => {
      if (auth.currentUser?.uid && selectedDisasters.length > 0) {
        // Clear case number if "All" is selected
        if (selectedDisasters.includes("All")) {
          setCaseNumber("");
          return;
        }

        try {
          // Get all survey responses for the user
          const surveyQuery = query(
            collection(db, `users/${auth.currentUser.uid}/survey_responses`)
          );

          const querySnapshot = await getDocs(surveyQuery);
          let foundCaseNumber = "";

          // Look for a survey that contains any of the selected disasters
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.disasters) {
              const surveyDisasterIds = data.disasters.map(
                (d) => d.disaster_id
              );

              // Check if any of the selected disasters are in this survey
              const selectedDisastersInSurvey = selectedDisasters.filter((id) =>
                surveyDisasterIds.includes(id)
              );

              // If all selected disasters are in this survey response
              if (
                selectedDisastersInSurvey.length === selectedDisasters.length
              ) {
                foundCaseNumber = data.case_number;
                console.log(
                  "Found case number that covers all selected disasters:",
                  foundCaseNumber
                );
              }
            }
          });

          setCaseNumber(foundCaseNumber);
        } catch (error) {
          console.error("Error fetching case number:", error);
          setCaseNumber("");
        }
      } else {
        setCaseNumber("");
      }
    };

    fetchCaseNumber();
  }, [auth.currentUser?.uid, selectedDisasters]);

  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Recovery Plan",
    "Contacts",
    "Journals",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  if (showBlur) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
          <div className="flex w-full flex-col items-center justify-center gap-2 space-x-4">
            <p className="text-[40px] font-bold text-[#348BFF]">
              This section will be activated when a disaster is declared
            </p>
            <button
              onClick={handleGoBack}
              className="mx-auto flex w-24 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-center text-blue-500"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="mb-4 flex w-full flex-row items-center justify-between">
        <div className="mt-3 flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <a className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Recovery Tracker
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[55px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Disasters
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[120px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            {activeDisasterTab}
          </a>
        </div>

        {/* Legend */}
        <div className="flex items-center space-x-4 text-xs">
          <div className="flex items-center">
            <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-green-500 font-bold text-white">
              C
            </span>
            <span>Center</span>
          </div>
          <div className="flex items-center">
            <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-yellow-500 font-bold text-white">
              P
            </span>
            <span>Partner</span>
          </div>
          <div className="flex items-center">
            <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 font-bold text-white">
              S
            </span>
            <span>Survivor</span>
          </div>
        </div>
      </div>

      {/* Disaster selector and tabs */}
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch mq1600:flex-wrap">
        {/* Disaster selector */}
        {/* <div className="relative flex items-center gap-2" ref={dropdownRef}>
          <span className="text-sm font-bold text-red-500">DISASTERS</span>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm w-[450px] relative"
            >
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="truncate px-6">
                  {selectedDisasters.includes("All") 
                    ? "All Disasters" 
                    : selectedDisasters.length > 0
                      ? disasterOptions
                          .filter(disaster => selectedDisasters.includes(disaster.id))
                          .map(disaster => disaster.name)
                          .join(", ")
                      : "Select Disasters"}
                </span>
              </span>
              <span className="ml-auto">▼</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-10">
                {disasterOptions.map((disaster) => (
                  <label key={disaster.id} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedDisasters.includes(disaster.id)}
                      onChange={() => handleDisasterChange(disaster.id)}
                      className="mr-2"
                    />
                    {disaster.name}
                  </label>
                ))}
              </div>
            )}
          </div>
        </div> */}
        <div className="flex items-center gap-2 w-full" ref={dropdownRef}>
          <span className="text-sm font-bold text-red-600">DISASTERS</span>
          <div className="relative w-1/2">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="relative flex w-[450px] items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm"
            >
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="truncate px-6">
                  {selectedDisasters.length > 0 ? (
                    <div className="flex w-full items-center justify-center gap-4">
                      <span>
                        {
                          disasterOptions.find(
                            (disaster) => disaster.id === selectedDisasters[0]
                          )?.name
                        }
                      </span>
                      {(() => {
                        const disaster = disasterOptions.find(
                          (d) => d.id === selectedDisasters[0]
                        );
                        return (
                          disaster && (
                            <div
                              className={`
                    flex items-center gap-2 rounded-md px-3 py-1
                    ${
                      disaster.status === "active"
                        ? "bg-[#E6FEEB] text-[#0A8525]"
                        : disaster.status === "inactive"
                        ? "bg-[#FFEAEA] text-[#CE2222]"
                        : "bg-[#E3E3E3] text-[#7B7B7B]"
                    }
                  `}
                            >
                              Status
                              <span
                                className={`h-2 w-2 rounded-full ${
                                  disaster.status === "active"
                                    ? "bg-[#0A8525]"
                                    : disaster.status === "inactive"
                                    ? "bg-[#CE2222]"
                                    : "bg-[#7B7B7B]"
                                }`}
                              ></span>
                            </div>
                          )
                        );
                      })()}
                    </div>
                  ) : (
                    "Select a Disaster"
                  )}
                </span>
              </span>
              <span className="ml-auto">▼</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 right-0 z-10 mt-1 rounded border border-gray-300 bg-white shadow-lg">
                <div className="border-b border-gray-200 p-2">
                  <label className="text-gray-600 flex cursor-pointer items-center text-sm">
                    <input
                      type="checkbox"
                      checked={showInactive}
                      onChange={(e) => setShowInactive(e.target.checked)}
                      className="mr-2"
                    />
                    Show Inactive/Closed Disasters
                  </label>
                </div>

                <div className="max-h-60 overflow-y-auto">
                  {disasterOptions
                    .filter((disaster) =>
                      showInactive ? true : disaster.status === "active"
                    )
                    .map((disaster) => (
                      <div
                        key={disaster.id}
                        className={`hover:bg-gray-100 cursor-pointer px-4 py-2 ${
                          selectedDisasters[0] === disaster.id
                            ? "bg-blue-50"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedDisasters([disaster.id]);
                          setIsDropdownOpen(false);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <span>{disaster.name}</span>
                          <div
                            className={`flex items-center gap-1 ${
                              disaster.status === "active"
                                ? "text-[#0A8525]"
                                : disaster.status === "inactive"
                                ? "text-[#CE2222]"
                                : "text-[#7B7B7B]"
                            }`}
                          >
                            <span className="text-xs">
                              {disaster.status === "active"
                                ? "Active"
                                : disaster.status === "inactive"
                                ? "Inactive"
                                : "Closed"}
                            </span>
                            <span
                              className={`h-2 w-2 rounded-full ${
                                disaster.status === "active"
                                  ? "bg-[#0A8525]"
                                  : disaster.status === "inactive"
                                  ? "bg-[#CE2222]"
                                  : "bg-[#7B7B7B]"
                              }`}
                            ></span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="relative w-1/2 text-right font-Inter">
            {caseNumber && (
                <div className="w-full flex flex-col items-end">
                  <p className="text-sm font-semibold text-gray-600">
                    Your case number for{" "}
                    {selectedDisasters.length > 1 ? "these disasters" : "this disaster"}{" "}
                    is:
                    <span className="text-[16px] font-bold text-blue-700"> {caseNumber}</span>
                  </p>
                  <p className="mt-1 text-sm text-blue-600">
                    {selectedDisasters.length > 1
                      ? "This case number covers all selected disasters"
                      : ""}
                  </p>
                </div>
            )}
          </div>
        </div>
        {/* Tabs */}
        <div className="flex flex-wrap gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`rounded-full px-4 py-1 ${
                activeDisasterTab === tab
                  ? "btn-default"
                  : "btn-default-border hover:bg-blue-100"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="relative inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        {/* <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p> */}
      </div>

      {activeDisasterTab === "Immediate Response Survey" && (
        <ImmediateResponseSurvey
          selectedDisasters={selectedDisasters}
          activeDisasters={disasterOptions}
          parentSetCaseNumber={setCaseNumber}
        />
      )}
      {activeDisasterTab === "Damage Assessment" && (
        <DisasterManagement
          selectedDisasters={selectedDisasters}
          activeDisasters={activeDisasters}
          disasterOptions={disasterOptions.filter((d) => d.id !== "All")}
        />
      )}
      {activeDisasterTab === "Assistance Log" && (
        <AssistanceLog
          selectedDisasters={selectedDisasters}
          activeDisasters={activeDisasters}
        />
      )}
      {activeDisasterTab === "Recovery Plan" ||
        (activeDisasterTab === "Recovery Tracker" && (
          <RecoveryPlanDisasters
            selectedDisasters={selectedDisasters}
            activeDisasters={disasterOptions}
          />
        ))}
      {activeDisasterTab === "Contacts" && <ContactsDisaster />}
      {activeDisasterTab === "Journals" && (
          <Journals disasterStage={disasterOptions.find(disaster => disaster.id === selectedDisasters[0])?.disaster_stage} />
      )}
    </div>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default Disasters;
