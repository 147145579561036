import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import placeholderImage from "../../icons/Opportunities/opportunities.png";
import { db } from "../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
  getFirestore,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
  FaChevronRight,
  FaUpload,
} from "react-icons/fa";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const PartnerJobOpportunities = ({ className = "", onNavigateBack }) => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [isApplicantsModalOpen, setIsApplicantsModalOpen] = useState(false);
  const [applicantsJobId, setApplicantsJobId] = useState(null);

  const [modalState, setModalState] = useState({
    isOpen: false,
    mode: null,
    job: null,
  });

  const [openSections, setOpenSections] = useState({
    description: true,
    applicants: true,
  });

  const [applications, setApplications] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [files, setFiles] = useState([]);

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const filteredJobs = jobs.filter((job) =>
    job.job.title.toLowerCase().includes(searchTitle.toLowerCase())
  );

  const handleJobSubmit = (jobData) => {
    if (modalState.mode === "add") {
      setJobs((prevJobs) => [...prevJobs, { job: jobData }]);
    } else if (modalState.mode === "edit") {
      setJobs((prevJobs) =>
        prevJobs.map((j) => (j.job.id === jobData.id ? { job: jobData } : j))
      );
    }
    setModalState({ isOpen: false, mode: null, job: null });
  };

  const openConfirmationModal = (type, jobId) => {
    setConfirmationType(type);
    setCurrentJobId(jobId);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationType("");
    setCurrentJobId(null);
  };

  const handleDeleteJob = async () => {
    try {
      await updateDoc(doc(db, "opportunities", currentJobId), {
        is_deleted: true,
      });
      setJobs(jobs.filter((job) => job.job.id !== currentJobId));
      console.log(`Job with ID ${currentJobId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting job:", error);
    } finally {
      closeConfirmationModal();
    }
  };

  const handleToggleClose = async (jobId) => {
    try {
      const jobToUpdate = jobs.find((job) => job.job.id === jobId);
      if (!jobToUpdate) {
        throw new Error("Job not found");
      }
      const updatedCloseStatus = !jobToUpdate.job.closed;
      const jobDocRef = doc(db, "opportunities", jobId);
      await updateDoc(jobDocRef, { closed: updatedCloseStatus });

      fetchJobs();
    } catch (error) {
      console.error("Error toggling job close status:", error);
    }
  };

  const openApplicantsModal = (jobId) => {
    setApplicantsJobId(jobId);
    setIsApplicantsModalOpen(true);
  };

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return "$0.00";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const convertToDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "N/A";

    const date = timestamp.toDate();
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const db = getFirestore();

  const fetchJobs = async () => {
    if (!userData.id) {
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      const userIds = [userData.id, userData.citizen_id].filter((id) => id);

      if (userIds.length > 0) {
        const jobsQuery = query(
          collection(db, "opportunities"),
          where("user_id", "in", userIds),
          where("type", "==", "job"),
          where("is_deleted", "==", false)
        );

        const querySnapshot = await getDocs(jobsQuery);
        const fetchedJobs = querySnapshot.docs.map((doc) => ({
          job: doc.data(),
        }));

        console.log("fetchedJobs: ", fetchedJobs);
        setJobs(fetchedJobs);
      } else {
        console.log("No valid user IDs found");
        setJobs([]);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [userData]);

  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0].job);
    }
  }, [jobs]);

  useEffect(() => {
    const fetchApplications = async () => {
      if (!selectedJob?.id) return;

      try {
        const applicationsRef = collection(
          db,
          "opportunities",
          selectedJob.id,
          "applications"
        );
        const applicationsSnapshot = await getDocs(applicationsRef);
        const fetchedApplications = applicationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setApplications(fetchedApplications);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    fetchApplications();
  }, [selectedJob]);

  const JobCard = ({ job }) => {
    const [applicationsCount, setApplicationsCount] = useState(0);
    const isSelected = selectedJob && selectedJob.id === job.id;

    const fetchApplicationsCount = async () => {
      try {
        const applicationsRef = collection(
          db,
          "opportunities",
          job.id,
          "applications"
        );
        const applicationsSnapshot = await getDocs(applicationsRef);
        setApplicationsCount(applicationsSnapshot.size);
      } catch (error) {
        console.error("Error fetching applications count:", error);
      }
    };

    useEffect(() => {
      fetchApplicationsCount();
    }, [job.id]);

    return (
      <div
        className={`flex w-full flex-col items-end justify-start gap-[5px] rounded-mini border bg-white ${
          isSelected ? "job-card-selected" : "job-card"
        }`}
        onClick={() => setSelectedJob(job)}
      >
        <div className="w-full px-4 py-2">
          <div className="flex flex-col gap-1">
            <div className="flex w-full justify-between">
              <div className="w-2/3 space-y-1">
                <b className="block truncate text-base">{job.title}</b>
                <div className="flex items-center gap-1">
                  <span className="text-xs font-medium">
                    Application Deadline:
                  </span>
                  <span className="text-xs">
                    {convertToDate(job.applicationDeadline)}
                  </span>
                </div>
                <p className="text-xs">{job.location}</p>

                <div className="flex items-center justify-between">
                  <div className="w-fit rounded bg-gray-300 p-2 text-xs font-semibold">
                    {job.jobType}
                  </div>
                  <div className="flex w-fit rounded bg-gray-300 p-2 text-xs font-semibold">
                    {applicationsCount} Applicants
                  </div>
                </div>
              </div>
              <img
                className="z-[1] h-[75px] w-[75px] object-cover mx-auto"
                alt={job.title}
                src={job.backgroundImage || placeholderImage}
              />
            </div>
            <div className="relative">
              <div className="text-sm">
                {job.jobDescription.length > 120
                  ? `${job.jobDescription.substring(0, 120)}...`
                  : job.jobDescription}
              </div>
              <button
                type="button"
                className="absolute bottom-0 right-0 flex cursor-pointer gap-1 bg-white text-blue-600 justify-end underline w-[55px]"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalState({
                    isOpen: true,
                    mode: "edit",
                    job: selectedJob,
                  });
                }}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const JobModal = ({ isOpen, onClose, onSubmit, job = null, mode }) => {
    const isEditMode = mode === "edit";

    const [formData, setFormData] = useState(
      job || {
        title: "",
        companyName: "",
        location: "",
        jobType: "Full-time",
        jobDescription: "",
        qualifications: "",
        educationRequirements: "",
        experienceRequired: null,
        salary: null,
        salaryType: "Salary",
        benefits: "",
        applicationDeadline: new Date(),
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        center_id: userData?.center_id || "",
        user_id: userData?.id || userData?.citizen_id || "",
        date: new Date(),
        is_deleted: false,
        published: false,
        closed: false,
        type: "job",
        backgroundImage: "",
      }
    );

    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
      if (isEditMode && job) {
        setFormData({
          ...job,
          applicationDeadline: job.applicationDeadline?.toDate
            ? job.applicationDeadline.toDate()
            : new Date(),
          published: job.published || false,
        });
      }
    }, [job, isEditMode]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleDropDownChange = (e) => {
      setFormData({ ...formData, jobType: e.value });
    };

    const handleSalaryTypeChange = (e) => {
      setFormData({ ...formData, salaryType: e.value });
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      setIsUploading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `backgroundImages/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("File upload error:", error);
          setIsUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData({ ...formData, backgroundImage: downloadURL });
          setIsUploading(false);
        }
      );
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        const jobData = {
          ...formData,
          center_id: userData?.center_id || "",
          user_id: userData?.id || userData?.citizen_id || "",
          type: "job",
          published: formData.published,
        };

        if (isEditMode) {
          const jobDocRef = doc(db, "opportunities", formData.id);

          // Handle Delete Job
          if (formData.is_deleted) {
            await updateDoc(jobDocRef, { is_deleted: true });
            setJobs(jobs.filter((job) => job.job.id !== formData.id));
            onClose();
            return;
          }

          // Handle Close Job and Publish status along with other updates
          await updateDoc(jobDocRef, {
            ...jobData,
            closed: formData.closed,
            published: formData.published,
          });

          // Update the jobs state to reflect changes immediately
          setJobs((prevJobs) =>
            prevJobs.map((j) =>
              j.job.id === formData.id
                ? {
                    job: {
                      ...j.job,
                      ...jobData,
                      closed: formData.closed,
                      published: formData.published,
                    },
                  }
                : j
            )
          );

          // Update selected job if it's the one being edited
          if (selectedJob && selectedJob.id === formData.id) {
            setSelectedJob((prev) => ({
              ...prev,
              ...jobData,
              closed: formData.closed,
              published: formData.published,
            }));
          }
        } else {
          const jobDocRef = doc(collection(db, "opportunities"));
          jobData.id = jobDocRef.id;
          await setDoc(jobDocRef, jobData);
        }

        onSubmit(jobData);
        onClose();
      } catch (error) {
        console.error("Error saving job:", error);
      }
    };

    if (!isOpen) return null;

    const jobTypeOptions = [
      { text: "Full-time", value: "Full-time" },
      { text: "Part-time", value: "Part-time" },
      { text: "Contract", value: "Contract" },
      { text: "Temporary", value: "Temporary" },
      { text: "Internship", value: "Internship" },
    ];

    const salaryTypeOptions = [
      { text: "Monthly", value: "Monthly" },
      { text: "Hourly", value: "Hourly" },
    ];

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-[75%] rounded-lg bg-white shadow-lg">
          <div className="flex items-center justify-between bg-[#467BA4] p-4">
            <h2 className="font-Inter text-[#FDB841]">
              {isEditMode ? "Edit Job Opportunity" : "Add Job Opportunity"}
            </h2>
            <button
              className="font-Inter cursor-pointer bg-[#467BA4] text-[25px] text-[#FDB841]"
              onClick={onClose}
            >
              ×
            </button>
          </div>
          <div className="max-h-[80vh] overflow-y-auto p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex gap-2">
                  <div className="w-[75%]">
                    <div>
                      <TextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="title"
                        placeholder="Title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="flex gap-2 mt-3">
                      <TextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="companyName"
                        placeholder="Company Name"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                      />
                      <div className="w-1/2">
                        <TextBoxComponent
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          name="location"
                          placeholder="Location"
                          value={formData.location}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-[25%]">
                    {/* Logo Upload Section */}
                    <div className="flex gap-1">
                      <div className="w-[70%] justify-center flex items-center">
                        {formData.backgroundImage ? (
                          <img
                            src={formData.backgroundImage}
                            alt="Company Logo Preview"
                            className="h-[100px] rounded border border-gray-300 object-cover shadow-sm"
                          />
                        ) : (
                          <div className="flex items-center justify-center h-[100px] w-full bg-gray-300 rounded border border-gray-300">
                            <p className="text-center text-gray-700">
                              Company<br />Logo
                            </p>
                          </div>
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          id="fileUpload"
                          onChange={handleFileUpload}
                          className="hidden"
                        />
                      </div>
                      <div className="w-[30%] flex justify-end">
                        <div className="flex flex-col items-end">
                          <label
                            htmlFor="fileUpload"
                            className="flex cursor-pointer bg-white text-blue-600 underline text-[10px] mt-[40px]"
                          >
                            <FaUpload className="h-3 w-3 mr-1" />
                            {formData.backgroundImage ? "Edit Logo" : "Upload Logo"}
                          </label>
                          {isUploading && (
                            <p className="font-Inter text-[8px] text-blue-500 mt-1">
                              Uploading image...
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex gap-2">
                    <div className="w-[36.5%]">
                        <TextBoxComponent
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          name="educationRequirements"
                          placeholder="Education Requirements"
                          value={formData.educationRequirements}
                          onChange={handleChange}
                          required
                        />
                    </div>
                    <div className="w-[12%]">
                        <NumericTextBoxComponent
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          name="experienceRequired"
                          placeholder="Years Experience"
                          value={formData.experienceRequired}
                          change={(e) =>
                            setFormData({
                              ...formData,
                              experienceRequired: e.value,
                            })
                          }
                          format="##.##"
                          max={99}
                          min={0}
                          decimals={0}
                          required
                          showSpinButton={false}
                        />
                    </div>
                    <div className="w-[24.5%]">
                        <DropDownListComponent
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          dataSource={jobTypeOptions}
                          fields={{ text: "text", value: "value" }}
                          placeholder="Job Type"
                          value={formData.jobType}
                          change={handleDropDownChange}
                          required
                        />
                    </div>
                    <div className="w-[25%]">
                      <DatePickerComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="applicationDeadline"
                        placeholder="Application Deadline"
                        value={formData.applicationDeadline}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            applicationDeadline: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                </div>

                {/* Experience Required and Job Type in same line */}
                <div className="flex gap-2">
                  <div className="w-[20.5%]">
                      <NumericTextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="salary"
                        placeholder="Salary"
                        value={formData.salary}
                        format="C2"
                        max={1000000}
                        min={0}
                        decimals={2}
                        change={(e) =>
                          setFormData({ ...formData, salary: e.value })
                        }
                        required
                        showSpinButton={false}
                      />
                  </div>
                  <div className="w-[15%]">
                      <DropDownListComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        dataSource={salaryTypeOptions}
                        fields={{ text: "text", value: "value" }}
                        placeholder="Type"
                        value={formData.salaryType}
                        change={handleSalaryTypeChange}
                        required
                      />
                  </div>
                </div>

                {/* Job Description - Full width */}
                <div className="w-full">
                  <TextAreaComponent
                    cssClass="e-outline "
                    floatLabelType="Auto"
                    name="jobDescription"
                    placeholder="Job Description"
                    value={formData.jobDescription}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                  />
                </div>

                {/* Qualifications - Full width */}
                <div className="w-full">
                  <TextAreaComponent
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="qualifications"
                    placeholder="Qualifications"
                    value={formData.qualifications}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                  />
                </div>

                {/* Qualifications - Full width */}
                <div className="w-full">
                  <TextAreaComponent
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="benefits"
                    placeholder="Benefits"
                    value={formData.benefits}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                  />
                </div>

                {/* Contact Information Section */}
                <div className="space-y-4">
                  <p className="font-Inter text-sm font-semibold text-[#467BA4]">
                    Contact Information
                  </p>
                  <div className="flex gap-2">
                    <div className="w-1/2">
                        <TextBoxComponent
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          name="contactName"
                          placeholder="Name"
                          value={formData.contactName}
                          onChange={handleChange}
                          required
                        />
                    </div>
                    <div className="w-[25%]">
                      <TextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="contactEmail"
                        placeholder="Email"
                        value={formData.contactEmail}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="w-[25%]">
                      <TextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="contactPhone"
                        placeholder="Phone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* After Contact Information section */}
                <div className="flex items-center justify-between border-t">
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id="closeJob"
                        checked={formData.closed}
                        onChange={(e) =>
                          setFormData({ ...formData, closed: e.target.checked })
                        }
                        className="h-4 w-4 rounded border-gray-300"
                      />
                      <label
                        htmlFor="closeJob"
                        className="text-gray-700 text-sm font-medium"
                      >
                        Close Job
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id="deleteJob"
                        checked={formData.is_deleted}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            is_deleted: e.target.checked,
                          })
                        }
                        className="h-4 w-4 rounded border-gray-300"
                      />
                      <label
                        htmlFor="deleteJob"
                        className="text-gray-700 text-sm font-medium"
                      >
                        Delete Job
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-gray-700 text-sm font-medium">
                      Publish
                    </span>
                    <SwitchComponent
                      cssClass="e-switch-toggle"
                      checked={formData.published}
                      change={(e) =>
                        setFormData({ ...formData, published: e.checked })
                      }
                    />
                  </div>
                </div>
              </div>



              <div className="mt-6 flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="btn-cancel font-Inter flex cursor-pointer gap-1 px-4 py-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-default font-Inter flex cursor-pointer gap-1 px-4 py-2"
                >
                  {isEditMode ? "Save Changes" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const handleStatusChange = async (appId, newStatus) => {
    try {
      const applicationRef = doc(
        db,
        "opportunities",
        selectedJob.id,
        "applications",
        appId
      );
      await updateDoc(applicationRef, { status: newStatus });
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === appId ? { ...app, status: newStatus } : app
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleAttachmentClick = (index, attachments) => {
    const formattedFiles = attachments.map((attachment) => {
      return {
        name: attachment.name,
        src: attachment.url,
        type: attachment.name.endsWith(".pdf")
          ? "application/pdf"
          : attachment.name.match(/\.(jpeg|jpg|png|gif)$/)
          ? "image/"
          : "application/octet-stream",
      };
    });
    setFiles(formattedFiles);
    setSelectedIndex(index);
    setIsDialogOpen(true);
  };

  const FileDialog = ({
    isOpen,
    onClose,
    files,
    currentIndex,
    setCurrentIndex,
  }) => {
    if (!isOpen || !Array.isArray(files) || files.length === 0) return null;

    const file = files[currentIndex];
    const isImage = file.type && file.type.startsWith("image/");
    const fileName = file.name || "Untitled File";

    return (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
        onClick={onClose}
      >
        <div className="relative flex h-[80vh] w-full max-w-4xl flex-col">
          <div className="absolute left-0 right-0 top-0 flex items-center justify-between rounded-t-lg bg-black bg-opacity-50 p-4 text-white">
            <h2 className="text-xl truncate font-bold">{fileName}</h2>
            <button
              onClick={onClose}
              className="text-3xl flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
            >
              &times;
            </button>
          </div>
          <div className="relative mt-16 flex-grow bg-black">
            {isImage ? (
              <img
                src={file.src}
                alt={fileName}
                className="max-h-full max-w-full object-contain"
              />
            ) : (
              <iframe
                src={file.src}
                title={fileName}
                className="absolute inset-0 h-full w-full"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const ConfirmationModal = ({ type, onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div>
          <div className="flex w-[800px] justify-between rounded-t-lg bg-red-800 p-4">
            <p className="font-Inter font-semibold text-[#FDB841]">
              {type === "delete"
                ? "Delete Job Opportunity"
                : "Close Job Opportunity"}
            </p>
          </div>
          <div className="max-h-[80vh] w-[800px] overflow-y-auto rounded-b-lg bg-white p-5 shadow-lg">
            <div>
              <p className="font-Inter">
                Are you sure you want to{" "}
                {type === "delete"
                  ? "delete this job opportunity?"
                  : "close this job opportunity?"}
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="btn-cancel font-Inter flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn-default font-Inter flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={onConfirm}
                >
                  {type === "delete" ? "Delete" : "Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-Inter font-bold text-blue-500">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      {isConfirmationModalOpen && (
        <ConfirmationModal
          type={confirmationType}
          onConfirm={
            confirmationType === "delete" ? handleDeleteJob : handleToggleClose
          }
          onCancel={closeConfirmationModal}
        />
      )}

      <JobModal
        isOpen={modalState.isOpen}
        mode={modalState.mode}
        job={modalState.job}
        onClose={() => setModalState({ isOpen: false, mode: null, job: null })}
        onSubmit={handleJobSubmit}
      />
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <p className="font-Inter relative inline-block min-w-[80px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Opportunities
          </p>
          <div className="font-Inter relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <p className="font-Inter relative inline-block min-w-[77px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Job Opportunities
          </p>
        </div>

        <div className="flex w-full justify-end">
          <button
            type="button"
            className="btn-default font-Inter flex cursor-pointer gap-1 px-3 py-1.5"
            onClick={() =>
              setModalState({ isOpen: true, mode: "add", job: null })
            }
          >
            <FaPlus className="h-3 w-3" />
            Add New Job
          </button>
        </div>
      </div>
      <div className="flex h-[calc(100vh-150px)] w-full">
        <div className="job-card-cont w-[400px] min-w-[400px] overflow-y-auto border-r border-solid border-gray-300">
          <div className="border-b border-solid border-gray-300 pb-2">
            <input
              type="text"
              placeholder="Search a Job"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="font-Inter w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-[#467BA4] focus:outline-none"
            />
          </div>
          <div className="job-card-cont grid grid-cols-1 gap-2">
            {filteredJobs.map((job) => (
              <JobCard key={job.job.id} job={job.job} />
            ))}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {selectedJob ? (
            <div className="h-full">
              <div className="w-full">
                <div className="mb-5 flex h-[150px] items-center justify-between border-b border-solid border-gray-300 p-2">
                  <div className="w-full space-y-1">
                    <b className="block truncate text-[25px] text-[#FDB841]">
                      {selectedJob.title}
                    </b>
                    <div className="flex items-center gap-1">
                      <span className="text-base font-medium">
                        Application Deadline:
                      </span>
                      <span className="text-base">
                        {convertToDate(selectedJob.applicationDeadline)}
                      </span>
                    </div>
                    <p className="text-base">{selectedJob.location}</p>

                    <div className="flex w-fit rounded bg-gray-300 p-2 text-base font-semibold">
                      {selectedJob.jobType}
                    </div>
                  </div>
                  <img
                    className="h-[150px] w-auto mx-auto"
                    alt={selectedJob.title}
                    src={selectedJob.backgroundImage || placeholderImage}
                  />
                </div>
                <div className="mb-2">
                  <div
                    className="font-Inter mb-1 flex cursor-pointer items-center gap-1 text-[#467ba4]"
                    onClick={() => toggleSection("description")}
                  >
                    <svg
                      className={`h-6 w-6 transform transition-transform ${
                        openSections.description ? "rotate-90" : ""
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6 6L14 10L6 14V6Z" />
                    </svg>
                    <p className="text-xl font-Inter mt-2 font-bold">
                      JOB DESCRIPTION
                    </p>
                  </div>

                  {openSections.description && (
                    <>
                      <div className="rounded-lg bg-white px-4">
                        <p className="font-Inter text-left text-black">
                          {selectedJob.jobDescription || "N/A"}
                        </p>
                      </div>

                      <div className="mb-3 mt-3 pl-4">
                        <div className="font-Inter mb-1 flex items-center gap-2">
                          <svg
                            className="h-4 w-4"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 7V3H2v18h20V7H12z" />
                          </svg>
                          <p className="font-Inter text-sm font-bold">
                            QUALIFICATIONS
                          </p>
                        </div>
                        <div className="rounded-lg bg-white px-4">
                          <p className="font-Inter text-left text-black">
                            {selectedJob.qualifications || "N/A"}
                          </p>
                        </div>
                      </div>

                      <div className="mb-5 pl-4">
                          <div className="font-Inter mb-1 flex items-center gap-2">
                            <svg
                              className="h-4 w-4"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 7V3H2v18h20V7H12z" />
                            </svg>
                            <p className="font-Inter text-sm font-bold">
                              SALARY
                            </p>
                          </div>
                          <div className="rounded-lg bg-white px-6">
                            <p className="font-Inter text-left text-black">
                              {formatCurrency(selectedJob.salary)} / {selectedJob.salaryType}
                            </p>
                          </div>
                      </div>

                      <div className="mb-5 pl-4">
                        <div className="font-Inter mb-1 flex items-center gap-2">
                          <svg
                            className="h-4 w-4"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 7V3H2v18h20V7H12z" />
                          </svg>
                          <p className="font-Inter text-sm font-bold">
                            BENEFITS
                          </p>
                        </div>
                        <div className="rounded-lg bg-white px-6">
                          <p className="font-Inter text-left text-black">
                            {selectedJob.benefits || "N/A"}
                          </p>
                        </div>
                      </div>

                      <div className="flex w-full gap-2 pl-4">
                        <div className="w-1/2">
                          <div className="mb-2 flex items-center gap-2">
                            <svg
                              className="h-4 w-4"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 7V3H2v18h20V7H12z" />
                            </svg>
                            <p className="font-Inter text-sm font-bold">
                              CONTACT INFORMATION
                            </p>
                          </div>
                          <div className="rounded-lg bg-white px-4">
                            <div className="font-Inter space-y-1 text-left text-black">
                              {[
                                {
                                  label: "Name",
                                  value: selectedJob.contactName,
                                },
                                {
                                  label: "Email",
                                  value: selectedJob.contactEmail,
                                },
                                {
                                  label: "Phone",
                                  value: selectedJob.contactPhone,
                                },
                              ].map((item, index) => (
                                <div key={index} className="flex items-center gap-2">
                                  <p className="font-bold" style={{ width: '60px' }}>{item.label}:</p>
                                  <p>{item.value || "N/A"}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* Employer Information */}
                        <div className="w-1/2">
                          <div className="mb-2 flex items-center gap-2">
                            <svg
                              className="h-4 w-4"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 7V3H2v18h20V7H12z" />
                            </svg>
                            <p className="font-Inter text-sm font-bold">
                              EMPLOYER INFORMATION
                            </p>
                          </div>
                          <div className="rounded-lg bg-white px-4">
                            <div className="font-Inter space-y-1 text-left text-black">
                              {[
                                {
                                  label: "Name",
                                  value: selectedJob.companyName,
                                },
                                {
                                  label: "Location",
                                  value: selectedJob.location,
                                },
                              ].map((item, index) => (
                                <div
                                  key={index}
                                  className="flex items-center gap-2"
                                >
                                  <p className="font-bold" style={{ width: '70px' }}>{item.label}:</p>
                                  <p>{item.value || "N/A"}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Add this after the Job Description section's closing div */}
                {applications && applications.length > 0 && (
                  <div className="mb-5">
                    <div
                      className="font-Inter mb-1 flex cursor-pointer items-center gap-1 text-[#467ba4]"
                      onClick={() => toggleSection("applicants")}
                    >
                      <svg
                        className={`h-6 w-6 transform transition-transform ${
                          openSections.applicants ? "rotate-90" : ""
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M6 6L14 10L6 14V6Z" />
                      </svg>
                      <p className="text-xl font-Inter mt-2 font-bold">
                        List of Applicants
                      </p>
                    </div>

                    {openSections.applicants && (
                      <div className="w-full rounded-lg bg-white px-4">
                        <div className="lg:grid-cols-3 grid grid-cols-1 gap-4 md:grid-cols-2">
                          {applications.map((app, appIndex) => (
                            <div
                              key={appIndex}
                              className="mb-2 rounded-lg border border-solid border-gray-300 bg-white p-4 shadow-md"
                            >
                              <div className="flex w-full justify-between">
                                <h3 className="text-lg mb-2 font-bold text-[#467BA4]">
                                  {app.first_name || "N/A"}{" "}
                                  {app.last_name || "N/A"}
                                </h3>
                                <div className="w-36">
                                  <DropDownListComponent
                                    id={`status-${appIndex}`}
                                    dataSource={[
                                      "For Review",
                                      "Reviewed",
                                      "For Interview",
                                      "Approved",
                                      "Rejected",
                                    ]}
                                    placeholder="Select Status"
                                    value={app.status || "For Review"}
                                    change={(e) =>
                                      handleStatusChange(app.id, e.value)
                                    }
                                    cssClass="e-outline"
                                  />
                                </div>
                              </div>
                              <p className="text-gray-600 text-sm">
                                <span className="font-semibold">Email:</span>{" "}
                                {app.email || "N/A"}
                              </p>
                              <p className="text-gray-600 text-sm">
                                <span className="font-semibold">
                                  Phone:
                                </span>{" "}
                                {app.mobile_number || "N/A"}
                              </p>
                              <p className="text-gray-600 text-sm">
                                <span className="font-semibold">
                                  Application Letter:
                                </span>{" "}
                                {app.applicationLetter || "N/A"}
                              </p>
                              <div className="mt-2">
                                <p className="text-gray-600 pb-2 text-sm font-semibold">
                                  Attachments:
                                </p>
                                {app.attachments &&
                                app.attachments.length > 0 ? (
                                  <div className="flex flex-wrap gap-2">
                                    {app.attachments.map((attachment, idx) => (
                                      <button
                                        key={idx}
                                        onClick={() =>
                                          handleAttachmentClick(
                                            idx,
                                            app.attachments
                                          )
                                        }
                                        className="flex items-center gap-2 rounded-lg border border-blue-500 bg-blue-50 px-4 py-2 text-blue-600 shadow-sm transition-all duration-200 hover:bg-blue-100 hover:shadow-md"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#3B82F6"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{ marginRight: "8px" }}
                                        >
                                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                          <polyline points="14 2 14 8 20 8"></polyline>
                                          <line
                                            x1="16"
                                            y1="13"
                                            x2="8"
                                            y2="13"
                                          ></line>
                                          <line
                                            x1="16"
                                            y1="17"
                                            x2="8"
                                            y2="17"
                                          ></line>
                                          <polyline points="10 9 9 9 8 9"></polyline>
                                        </svg>
                                        <span className="font-medium">
                                          {attachment.name}
                                        </span>
                                      </button>
                                    ))}
                                  </div>
                                ) : (
                                  <p className="text-sm text-gray-500">
                                    No attachments available
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="font-Inter flex h-full items-center justify-center text-gray-500">
              Select a job to view details
            </div>
          )}
        </div>
      </div>
      <FileDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        files={files}
        currentIndex={selectedIndex}
        setCurrentIndex={setSelectedIndex}
      />
    </div>
  );
};

PartnerJobOpportunities.propTypes = {
  className: PropTypes.string,
};

export default PartnerJobOpportunities;
