import React, { createContext, useContext, useState, useEffect } from "react";

const GeneralContext = createContext();

export const GeneralProvider = ({ children }) => {
  const [dupAddressData, setDupAddressData] = useState(() => {
    const savedData = localStorage.getItem("dupAddressData");
    return savedData ? JSON.parse(savedData) : null;
  });
  const [email, setEmail] = useState(() => {
    const savedEmail = localStorage.getItem("email");
    return savedEmail ? savedEmail : null;
  });
  const [employeeId, setEmployeeId] = useState(() => {
    const savedEmployeeId = localStorage.getItem("employeeId");
    return savedEmployeeId ? savedEmployeeId : null;
  });

  const setEmailfunc = (email) => {
    console.log("setting email: ", email);
    setEmail(email);
    localStorage.setItem("email", email);
  };

  const setDupAddress = (data) => {
    console.log("setting dupAddressData: ", data);
    setDupAddressData(data);
    localStorage.setItem("dupAddressData", JSON.stringify(data));
  };

  const clearDupAddress = () => {
    console.log("clearing dupAddressData");
    setDupAddressData(null);
    localStorage.removeItem("dupAddressData");
  };

  const setEmployeeIdfunc = (employeeId) => {
    console.log("setting employeeId: ", employeeId);
    setEmployeeId(employeeId);
    localStorage.setItem("employeeId", employeeId);
  };

  useEffect(() => {
    console.log("dupAddressData: ", dupAddressData);
    console.log("email: ", email);
  }, [dupAddressData, email]);

  return (
    <GeneralContext.Provider
      value={{
        dupAddressData,
        email,
        setDupAddress,
        clearDupAddress,
        setEmailfunc,
        employeeId,
        setEmployeeIdfunc,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => {
  const context = useContext(GeneralContext);
  if (!context) {
    throw new Error("useGeneralContext must be used within a GeneralProvider");
  }
  return context;
};
