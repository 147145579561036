import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaPlus, FaDownload, FaUserPlus } from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
  DetailRow,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  getFirestore,
  collection,
  query,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore"; // Import Firestore functions
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { getStorage, ref, deleteObject } from "firebase/storage";
import DisasterMgtService from "../../../service/citizen/DisasterMgtService";
import disasterCategories from "../../../disaster_categories.json";
import { ImageDialog } from "./CenterCaseAssistanceLog";

const sortSettings = {
  allowSorting: true,
};

const editing = {
  // allowDeleting: true,
  // allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
};

const filterSettings = {
  type: "Menu",
};

// Initialize Firebase Firestore
const db = getFirestore();
const auth = getAuth();

const CenterRecoveryPlanDisasters = ({
  className = "",
  selectedDisasters,
  activeDisasters,
}) => {
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  // State for Recovery Assistance Data
  const [recoveryAssistanceData, setRecoveryAssistanceData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);

  let elem;
  let uploadObj;
  let strm;
  let selectedRow;

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  const [accessedUserId, setAccessedUserId] = useState(null);

  // Add this useEffect to get the accessed user ID
  useEffect(() => {
    const fetchAccessedUser = async () => {
      const auth = getAuth();
      if (!auth.currentUser?.uid) return;

      try {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        const userData = userDoc.data();
        setAccessedUserId(userData.accessed_userId);
      } catch (error) {
        console.error("Error fetching accessed user:", error);
      }
    };

    fetchAccessedUser();
  }, []);

  // Update the fetchData function to use accessedUserId
  const fetchData = async () => {
    try {
      if (!accessedUserId) return;

      const userDocRef = doc(db, "users", accessedUserId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        const filterBySelectedDisasters = (item) => {
          if (selectedDisasters.length === 0) {
            return false;
          }
          return (
            item.associatedDisasters &&
            item.associatedDisasters.some((disasterId) =>
              selectedDisasters.includes(disasterId)
            )
          );
        };

        // Use accessedUserId instead of userId
        const general = await DisasterMgtService.fetchRecords(
          accessedUserId,
          "general"
        );
        const home = await DisasterMgtService.fetchRecords(
          accessedUserId,
          "home_repair"
        );
        const landscaping = await DisasterMgtService.fetchRecords(
          accessedUserId,
          "landscaping"
        );
        const vehicle = await DisasterMgtService.fetchRecords(
          accessedUserId,
          "vehicle"
        );
        const contents = await DisasterMgtService.fetchRecords(
          accessedUserId,
          "contents"
        );

        // Combine and transform the data for Recovery Plan
        const recoveryPlanData = [
          ...general.map((item) => {
            const donated = item.value || 0;
            const funding = item.funding || 500;
            const fundingAmount = donated + funding;
            const fundingGap = Math.abs(item.estimate - fundingAmount);

            return {
              ...item,
              RecoveryPlanID: item.generalID,
              category: "General",
              subcategory: item.sub_category,
              estimate: item.estimate,
              donated: donated,
              photoRecovery: item.photoGeneral,
              funding: funding,
              fundingAmount: fundingAmount,
              fundingGap: fundingGap,
            };
          }),
          ...home.map((item) => {
            const donated = item.value || 0;
            const funding = item.funding || 0;
            const fundingAmount = donated + funding;
            const fundingGap = Math.abs(item.estimate - fundingAmount);

            return {
              ...item,
              RecoveryPlanID: item.homeRepairID,
              category: "Home Repair and Replacement",
              subcategory: item.sub_category,
              estimate: item.estimate,
              donated: donated,
              // photoRecovery: item.photo_home_repair,
              funding: funding,
              fundingAmount: fundingAmount,
              fundingGap: fundingGap,
            };
          }),
        ];

        setRecoveryAssistanceData(
          recoveryPlanData.filter((item) => !item.is_deleted)
        );

        const generalData = general
          .filter(filterBySelectedDisasters)
          .map((item) => {
            const donated = item.value || 0;
            const fundingAmount = item.fundingAmount || 0;
            const fundingGap = fundingAmount + donated - item.estimate; // Corrected calculation

            return {
              ...item,
              generalID: item.generalID,
              category: "General",
              subcategory: item.sub_category,
              estimate: item.estimate,
              donated: donated,
              photoRecovery: item.photo_general,
              funding: item.funding,
              fundingAmount: fundingAmount,
              fundingGap: fundingGap,
              fundingGapColor: fundingGap < 0 ? "red" : "green",
            };
          });
        setGeneralData(generalData.filter((item) => !item.is_deleted));

        const homeRepairData = home
          .filter(filterBySelectedDisasters)
          .map((item) => {
            const donated = item.value || 0;
            const fundingAmount = item.fundingAmount || 0;
            const fundingGap = fundingAmount + donated - item.estimate; // Corrected calculation

            return {
              ...item,
              homeRepairID: item.homeRepairID,
              category: "Home Repair and Replacement",
              subcategory: item.sub_category,
              estimate: item.estimate,
              donated: donated,
              photoRecovery: item.photo_home_repair,
              funding: item.funding,
              fundingAmount: fundingAmount,
              fundingGap: fundingGap,
              fundingGapColor: fundingGap < 0 ? "red" : "green",
            };
          });
        setHomeRepairData(homeRepairData.filter((item) => !item.is_deleted));

        const landscapingData = landscaping.map((item) => {
          const donated = item.value || 0;
          const fundingAmount = item.fundingAmount || 0;
          const fundingGap = fundingAmount + donated - item.estimate; // Corrected calculation

          return {
            ...item,
            landscapingID: item.landscapingID,
            category: "Landscaping",
            subcategory: item.sub_category,
            estimate: item.estimate,
            donated: donated,
            photoRecovery: item.photo_landscaping,
            funding: item.funding,
            fundingAmount: fundingAmount,
            fundingGap: fundingGap,
            fundingGapColor: fundingGap < 0 ? "red" : "green",
          };
        });
        setLandscapingData(landscapingData.filter((item) => !item.is_deleted));

        const vehicleData = vehicle
          .filter(filterBySelectedDisasters)
          .map((item) => {
            const donated = item.value || 0;
            const fundingAmount = item.fundingAmount || 0;
            const fundingGap = fundingAmount + donated - item.estimate; // Corrected calculation

            return {
              ...item,
              vehicleID: item.vehicleID,
              category: "Vehicle",
              subcategory: item.sub_category,
              estimate: item.estimate,
              donated: donated,
              photoRecovery: item.photo_vehicle,
              funding: item.funding,
              fundingAmount: fundingAmount,
              fundingGap: fundingGap,
              fundingGapColor: fundingGap < 0 ? "red" : "green",
            };
          });
        setVehicleData(vehicleData.filter((item) => !item.is_deleted));

        const contentsData = contents
          .filter(filterBySelectedDisasters)
          .map((item) => {
            const donated = item.value || 0;
            const fundingAmount = item.fundingAmount || 0;
            const fundingGap = fundingAmount + donated - item.estimate; // Corrected calculation

            return {
              ...item,
              contentsID: item.contentsID,
              category: "Contents",
              subcategory: item.sub_category,
              estimate: item.estimate,
              donated: donated,
              photoRecovery: item.photo_contents,
              funding: item.funding,
              fundingAmount: fundingAmount,
              fundingGap: fundingGap,
              fundingGapColor: fundingGap < 0 ? "red" : "green",
            };
          });
        setContentsData(contentsData.filter((item) => !item.is_deleted));
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    if (accessedUserId) {
      fetchData();
    }
  }, [accessedUserId, selectedDisasters]);
  const fundingGapTemplate = (props) => {
    const color = props.fundingGapColor;
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(props.fundingGap));
    return <span style={{ color: color }}>{formattedValue}</span>;
  };

  const [showPopup, setShowPopup] = useState(false);

  const toggleGiveAccess = () => {
    setShowPopup(!showPopup);
  };

  const onDialogClose = () => {
    setShowPopup(false);
  };

  const footerTemplate = () => {
    return (
      <div className="flex justify-end gap-3.5 p-2">
        <button
          className="text-gray-700 hover:bg-gray-100 cursor-pointer rounded border border-gray-300 px-3 py-1.5 font-semibold transition-all duration-300 ease-in-out hover:border-gray-500 hover:shadow-sm"
          onClick={toggleGiveAccess}
        >
          Cancel
        </button>
        <button className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white transition-all duration-300 ease-in-out hover:bg-blue-600 hover:shadow-md">
          Give Access
        </button>
      </div>
    );
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  // State for General section
  const [RecoveryPlanData, setRecoveryPlanData] = useState([]);
  const [isRecoveryPlanVisible, setIsRecoveryPlanVisible] = useState(true);
  const [lastRecoveryPlanEditedTime, setLasRecoveryPlanEditedTime] =
    useState(null);
  const [maxRecoveryPlanID, setMaxRecoveryPlanID] = useState(
    RecoveryPlanData.length > 0
      ? Math.max(...RecoveryPlanData.map((item) => item.RecoveryPlanID))
      : 0
  );

  const toggleRecoveryPlan = (event) => {
    event.preventDefault();
    setIsRecoveryPlanVisible(!isRecoveryPlanVisible);
  };

  const RecoveryPlanGridRef = useRef(null);

  const handleAddClickRecoveryPlan = () => {
    if (RecoveryPlanGridRef.current) {
      RecoveryPlanGridRef.current.addRecord();
    }
  };

  const actionBeginRecoveryPlan = (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      if (args.data.funding) {
        console.log("Funding field updated:", args.data.funding);

        // Update the recoveryAssistanceData state
        setRecoveryAssistanceData((prevData) =>
          prevData.map((item) =>
            item.RecoveryPlanID === args.data.RecoveryPlanID
              ? { ...item, funding: args.data.funding }
              : item
          )
        );
      }
    }
  };

  /*
  const imageEditRecoveryPlan = {
      create: () => {
          elem = document.createElement('div');
          return elem;
      },
      read: () => {
          return strm;
      },
      destroy: () => {
          if (uploadObj) {
              uploadObj.destroy();
          }
          strm = null;
      },
      write: args => {
          const path = {
              removeUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Remove',
              saveUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Save'
          };

          elem.innerHTML = `
              <div class="flex flex-col justify-center w-full">
                  <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Photo Attachment</label>
                  <div class="flex flex-col items-center">
                      <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                          <!-- Image will be dynamically inserted here -->
                      </div>
                      <div id="dropArea">
                          <label class="upload-button" id="uploadLabel">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="17 8 12 3 7 8"></polyline>
                                  <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                              Upload Image
                          </label>
                          <input type="file" id="defaultUpload" style="display:none;" />
                      </div>
                  </div>
              </div>
          `;

          const inputElement = elem.querySelector('#defaultUpload');
          const uploadLabel = elem.querySelector('#uploadLabel');
          const imagePreview = elem.querySelector('#imagePreview');

          uploadObj = new Uploader({
              asyncSettings: path,
              success: onUploadSuccess,
              failure: onUploadFailure,
              multiple: false,
              allowedExtensions: ".jpeg, .png, .jpg",
              cssClass: "hidden"
          });

          uploadObj.appendTo(inputElement);

          uploadLabel.addEventListener('click', () => {
              inputElement.click();
          });

          inputElement.addEventListener('change', () => {
              if (inputElement.files.length > 0) {
                  uploadObj.upload(inputElement.files);
              }
          });

          function onUploadSuccess(args) {
              if (args.operation === 'upload') {
                  const file = args.file.rawFile;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = function () {
                      strm = reader.result;
                      displayUploadedImage(strm, args.file.name);
                  };
                  reader.onerror = function (error) {
                      console.log('Error: ', error);
                  };
              }
          }

          function onUploadFailure(args) {
              console.log('File failed to upload');
          }

          function displayUploadedImage(imageSrc, fileName) {
              imagePreview.innerHTML = `
                  <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
                  <p class="text-sm text-blue-500 mt-2">${fileName}</p>
              `;
          }

          // Display the current image if available
          if (args.rowData.photoRecoveryPlan) {
              displayUploadedImage(args.rowData.photoRecoveryPlan, 'Current Image');
          } else {
              imagePreview.innerHTML = '';
          }
      }
  };
  */

  /*

  const actionBeginRecoveryPlan = (args) => {
      if (args.requestType === 'save' && args.action === 'add') {
        const newRecoveryPlanID = maxRecoveryPlanID + 1;
        args.data.RecoveryPlanID = newRecoveryPlanID;
        setMaxRecoveryPlanID(newRecoveryPlanID);
      }
      if (args.requestType === 'beginEdit') {
          selectedRow = args.rowData;
      }
  };

  const actionCompleteRecoveryPlan = (args) => {

      if (args.requestType === 'save' && args.action === 'edit') {
          const updatedData = RecoveryPlanData.map(item =>
              item.RecoveryPlanID === selectedRow.RecoveryPlanID ? args.data : item
          );
          console.log('Data saved successfully:', args.data);
          setRecoveryPlanData(updatedData);
      }
  };

  */

  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (isDialogOpen && dialogRef.current) {
      dialogRef.current.show();
    } else if (!isDialogOpen && dialogRef.current) {
      dialogRef.current.hide();
    }
  }, [isDialogOpen]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  // Update the grid templates
  const gridTemplate = (props) => {
    const images = Array.isArray(props.photo_general)
      ? props.photo_general
      : props.photo_general
      ? [props.photo_general]
      : [];
    return renderImagePreview(images);
  };

  const landscapingTemplate = (props) => {
    const images = Array.isArray(props.photo_landscaping)
      ? props.photo_landscaping
      : props.photo_landscaping
      ? [props.photo_landscaping]
      : [];
    return renderImagePreview(images);
  };

  const contentsTemplate = (props) => {
    const images = Array.isArray(props.photo_contents)
      ? props.photo_contents
      : props.photo_contents
      ? [props.photo_contents]
      : [];
    return renderImagePreview(images);
  };

  const vehicleTemplate = (props) => {
    const images = Array.isArray(props.photo_vehicle)
      ? props.photo_vehicle
      : props.photo_vehicle
      ? [props.photo_vehicle]
      : [];
    return renderImagePreview(images);
  };

  const homeRepairTemplate = (props) => {
    const images = Array.isArray(props.photo_home_repair)
      ? props.photo_home_repair
      : props.photo_home_repair
      ? [props.photo_home_repair]
      : [];
    return renderImagePreview(images);
  };

  // Helper function to render image preview
  const renderImagePreview = (images) => {
    return (
      <div
        className="image-name-container"
        onClick={() => handleImageClick(images)}
        style={{
          cursor: "pointer",
          padding: "10px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          textAlign: "center",
          minWidth: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px" }}
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
        <p className="text-sm">
          {images.length > 0
            ? `${images.length} image${images.length > 1 ? "s" : ""}`
            : "No images"}
        </p>
      </div>
    );
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleImageClick = (images) => {
    setSelectedImages(Array.isArray(images) ? images : [images]);
    setCurrentImageIndex(0);
    setIsImageDialogOpen(true);
  };

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const { categories, subcategories } = disasterCategories;

  //General
  const [generalData, setGeneralData] = useState([]);
  const [isGeneralVisible, setIsGeneralVisible] = useState(true);
  const [lastGeneralEditedTime, setLastGeneralEditedTime] = useState(null);

  const toggleGeneral = (event) => {
    event.preventDefault();
    setIsGeneralVisible(!isGeneralVisible);
  };

  const generalGridRef = useRef(null);

  const actionBeginGeneral = async (args) => {
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.generalID;
          const fileUrl = docData.photo_general;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "general"
          );
        }

        // Update the last edited time after deletion
        setLastGeneralEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteGeneral = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = selectedRow.generalID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "general"
        );
      }

      const documentId = args.data.generalID;
      let updatedData = { ...args.data };
      const sanitizedData = sanitizeData(updatedData);
      // Refresh the grid data
      try {
        // Update the home repair record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "general"
        );

        // Refresh the grid data
        const updatedGeneralData = await DisasterMgtService.getGeneral(userId);
        setGeneralData(updatedGeneralData);
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
      await fetchData();
    }
  };

  //Home Repair
  const [isHomeRepairVisible, setIsHomeRepairVisible] = useState(true);
  const [lastHomeRepairEditedTime, setLastHomeRepairEditedTime] =
    useState(null);
  const [homeRepairData, setHomeRepairData] = useState([]);

  const toggleHomeRepair = (event) => {
    event.preventDefault();
    setIsHomeRepairVisible(!isHomeRepairVisible);
  };

  const homeRepairGridRef = useRef(null);

  const actionBeginHomeRepair = async (args) => {
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.homeRepairID;
          const fileUrl = docData.photo_home_repair;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "home_repair"
          );
        }

        // Update the last edited time after deletion
        setLastHomeRepairEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteHomeRepair = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = selectedRow.homeRepairID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "home_repair"
        );
      }

      const documentId = args.data.homeRepairID;
      let updatedData = { ...args.data };
      const sanitizedData = sanitizeData(updatedData);
      // Refresh the grid data
      try {
        // Update the home repair record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "home_repair"
        );

        // Refresh the grid data
        const updatedHomeRepairData = await DisasterMgtService.getHomeRepair(
          userId
        );
        setHomeRepairData(updatedHomeRepairData);
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
  };

  //Landscaping
  const [isLandscapingVisible, setIsLandscapingVisible] = useState(true);
  const [lastLandscapingEditedTime, setLastLandscapingEditedTime] =
    useState(null);
  const [landscapingData, setLandscapingData] = useState([]);

  const toggleLandscaping = (event) => {
    event.preventDefault();
    setIsLandscapingVisible(!isLandscapingVisible);
  };

  const landscapingGridRef = useRef(null);

  const actionBeginLandscaping = async (args) => {
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.landscapingID;
          const fileUrl = docData.photo_landscaping;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "landscaping"
          );
        }

        // Update the last edited time after deletion
        setLastLandscapingEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteLandscaping = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = selectedRow.landscapingID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "landscaping"
        );
      }

      const documentId = args.data.landscapingID;
      let updatedData = { ...args.data };
      const sanitizedData = sanitizeData(updatedData);
      // Refresh the grid data
      try {
        // Update the home repair record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "landscaping"
        );

        // Refresh the grid data
        const updatedLandscapingData = await DisasterMgtService.getLandscaping(
          userId
        );
        setLandscapingData(updatedLandscapingData);
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
      await fetchData();
    }
  };

  //Landscaping
  const [vehicleData, setVehicleData] = useState([]);
  const [isVehicleVisible, setIsVehicleVisible] = useState(true);
  const [lastVehicleEditedTime, setLastVehicleEditedTime] = useState(null);

  const toggleVehicle = (event) => {
    event.preventDefault();
    setIsVehicleVisible(!isVehicleVisible);
  };

  const vehicleGridRef = useRef(null);

  const actionBeginVehicle = async (args) => {
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.vehicleID;
          const fileUrl = docData.photo_vehicle;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "vehicle"
          );
        }

        // Update the last edited time after deletion
        setLastVehicleEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteVehicle = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = selectedRow.vehicleID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "vehicle"
        );
      }

      const documentId = args.data.vehicleID;
      let updatedData = { ...args.data };
      const sanitizedData = sanitizeData(updatedData);
      // Refresh the grid data
      try {
        // Update the home repair record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "vehicle"
        );

        // Refresh the grid data
        const updatedVehicleData = await DisasterMgtService.getVehicle(userId);
        setVehicleData(updatedVehicleData);
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
      await fetchData();
    }
  };

  //Contents
  const [contentsData, setContentsData] = useState([]);
  const [isContentsVisible, setIsContentsVisible] = useState(true);
  const [lastContentsEditedTime, setLastContentsEditedTime] = useState(null);

  const toggleContents = (event) => {
    event.preventDefault();
    setIsContentsVisible(!isContentsVisible);
  };

  const contentsGridRef = useRef(null);

  const actionBeginContents = async (args) => {
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.contentsID;
          const fileUrl = docData.photo_contents;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: new Date() }, // Add last_edited here
            "contents"
          );
        }

        // Update the last edited time after deletion
        setLastContentsEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteContents = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = selectedRow.contentsID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "contents"
        );
      }

      const documentId = args.data.contentsID;
      let updatedData = { ...args.data };
      const sanitizedData = sanitizeData(updatedData);
      // Refresh the grid data
      try {
        // Update the home repair record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "contents"
        );

        // Refresh the grid data
        const updatedContentsData = await DisasterMgtService.getContents(
          userId
        );
        setContentsData(updatedContentsData);
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
      await fetchData();
    }
  };

  const toolbarOptions = ["Edit", "Update", "Cancel"];
  const [disasterMap, setDisasterMap] = useState({});
  const [dialogContent, setDialogContent] = useState("");
  const [isDialogDisasterOpen, setIsDialogDisasterOpen] = useState(false);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.reduce((acc, doc) => {
          if (doc.data().disaster_status === "active") {
            acc[doc.id] = doc.data().disaster_name;
          }
          return acc;
        }, {});

        setDisasterMap(fetchedDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  const categoryTemplate = (props) => {
    const disasterCount = props.associatedDisasters
      ? props.associatedDisasters.length
      : 0;

    let editorTypeColor = "";
    let editorTypeLetter = "";
    switch (props.editor_type) {
      case "center":
        editorTypeColor = "bg-green-500";
        editorTypeLetter = "C";
        break;
      case "partner":
        editorTypeColor = "bg-yellow-500";
        editorTypeLetter = "P";
        break;
      case "citizen":
        editorTypeColor = "bg-blue-500";
        editorTypeLetter = "C";
        break;
      default:
        editorTypeColor = "bg-gray-500";
        editorTypeLetter = "?";
    }

    return (
      <div className="flex items-center">
        <span className="mr-2">{props.sub_category}</span>
        <div className="flex items-center space-x-1">
          {/* <span 
            className={`w-6 h-6 rounded-full ${editorTypeColor} flex items-center justify-center text-white text-xs font-bold`}
            title={`Editor Type: ${props.editor_type}`}
          >
            {editorTypeLetter}
          </span> */}
          {disasterCount > 0 && (
            <span
              className="cursor-pointer rounded bg-red-500 px-2 py-1 text-xs font-semibold text-white hover:bg-red-600"
              onClick={() => handleDisasterClick(props.associatedDisasters)}
              title={`${disasterCount} Associated Disaster${
                disasterCount > 1 ? "s" : ""
              }`}
            >
              {disasterCount}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleDisasterClick = (disasterIds) => {
    if (disasterIds && disasterIds.length > 0) {
      const disasterList = disasterIds
        .map(
          (id, index) =>
            `${index + 1}. ${disasterMap[id] || "Unknown Disaster"}`
        )
        .join("\n");
      setDialogContent(disasterList);
      setIsDialogDisasterOpen(true);
    } else {
      setDialogContent("No associated disasters.");
      setIsDialogDisasterOpen(true);
    }
  };

  const closeDialogDisaster = () => {
    setIsDialogDisasterOpen(false);
  };

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start py-[15px] ${className}`}
    >
      {isDialogDisasterOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
            <h2 className="text-xl bg-blue-500 p-4 font-bold text-white">
              Associated Disasters
            </h2>
            <div className="px-6 py-4">
              <pre className="whitespace-pre-wrap text-base">
                {dialogContent}
              </pre>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={closeDialogDisaster}
                  className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p>
          The Recovery Tracker is a vital document that outlines the steps and
          strategies for rebuilding and restoring your community after a
          disaster. By completing this plan, you contribute to a coordinated,
          efficient recovery process. Please provide detailed information to
          ensure the most effective path forward for long-term recovery and
          resilience.
        </p>
      </div>
      <div className="w-full">
        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs bg-white p-2">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div></div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-2 text-white shadow transition duration-200 hover:bg-blue-600"
              >
                <FaDownload className="mr-2 h-3.5 w-3.5" />
                Download Unmet Needs Report
              </button>
              <div>
                <button
                  type="button"
                  className="flex cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-2 text-white shadow transition duration-200 hover:bg-blue-600"
                  onClick={toggleGiveAccess}
                >
                  <FaUserPlus className="mr-2 h-4 w-4" />
                  Give Access
                </button>

                <DialogComponent
                  visible={showPopup}
                  width="480px"
                  isModal={true}
                  closeOnEscape={true}
                  header="Give Access"
                  footerTemplate={footerTemplate}
                  target={document.body}
                  close={onDialogClose}
                  cssClass="e-grid-dialog"
                >
                  <div className="flex flex-col px-4 py-2">
                    <div className="flex">
                      <div className="text-gray-700 my-auto mr-4 font-semibold">
                        I give permission for
                      </div>
                      <input
                        className="input-style grow rounded-xl bg-zinc-50 py-2.5 pl-3.5 pr-2.5 text-zinc-500"
                        placeholder="Name of Person Receiving"
                      />
                    </div>
                    <div className="mt-5 flex">
                      <div className="text-gray-700 my-auto grow font-semibold">
                        from
                      </div>
                      <input
                        className="input-style grow rounded-xl bg-zinc-50 py-2.5 pl-3.5 pr-2.5 text-zinc-500"
                        placeholder="Organization"
                      />
                    </div>
                    <div className="text-gray-700 mt-5 font-semibold">
                      access to (until my recovery plan is closed):
                    </div>
                    <ul>
                      <li className="mb-3 flex items-center">
                        <input type="checkbox" id="item1" className="mr-2" />
                        <label htmlFor="item1">
                          View and/or update information to my Damage
                          Assessment, Assistance Log, and/or Recovery Plan.
                        </label>
                      </li>
                      <li className="mb-3 flex items-center">
                        <input type="checkbox" id="item2" className="mr-2" />
                        <label htmlFor="item2">
                          View and respond to Case Management Notes made by
                          myself, my case manager, or a nonprofit partner.
                        </label>
                      </li>
                      <li className="mb-3 flex items-center">
                        <input type="checkbox" id="item3" className="mr-2" />
                        <label htmlFor="item3">
                          View My Past Recovery Plans.
                        </label>
                      </li>
                    </ul>
                    <div className="mt-5 flex">
                      <div className="my-auto font-semibold text-neutral-700">
                        Send access code to
                      </div>
                      <input
                        className="input-style grow rounded-xl bg-zinc-50 py-2.5 pl-3.5 pr-2.5 text-zinc-500"
                        placeholder="Organization Email Address"
                      />
                    </div>
                  </div>
                </DialogComponent>
              </div>
            </div>
          </header>
        </section>

        <section className=" box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 text-sm font-bold text-blue-500">General</h2>
              <p className="font-poppins text-xs font-medium text-gray-500"></p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleGeneral}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isGeneralVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isGeneralVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={generalGridRef}
                dataSource={generalData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginGeneral}
                actionComplete={actionCompleteGeneral}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="generalID"
                    headerText="generalID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="200"
                    visible={false}
                    allowEditing={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "1")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    template={categoryTemplate}
                    width="250"
                    editType="dropdownedit"
                    allowEditing={false}
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "1")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="200"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="photo_general"
                    headerText="Photo Attachment"
                    width="200"
                    template={gridTemplate}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Donated Goods/Services"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />

                  <ColumnDirective
                    field="funding"
                    headerText="Other Source of Funding"
                    width="200"
                    allowFiltering={false}
                    allowEditing={true}
                  />
                  <ColumnDirective
                    field="fundingAmount"
                    headerText="Amount of Other Funding"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="fundingGap"
                    headerText="Funding Gap"
                    width="200"
                    allowFiltering={false}
                    format="C"
                    allowEditing={false}
                    template={fundingGapTemplate}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    CommandColumn,
                    Sort,
                    Filter,
                    DetailRow,
                  ]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Home Repair and Replacement
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500"></p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleHomeRepair}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isHomeRepairVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isHomeRepairVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={homeRepairGridRef}
                dataSource={homeRepairData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginHomeRepair}
                actionComplete={actionCompleteHomeRepair}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="homeRepairID"
                    headerText="homeRepairID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="200"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "2")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    template={categoryTemplate}
                    width="250"
                    editType="dropdownedit"
                    allowEditing={false}
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "2")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="200"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="photo_home_repair"
                    headerText="Photo Attachment"
                    width="200"
                    template={homeRepairTemplate}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Donated Goods/Services"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />

                  <ColumnDirective
                    field="funding"
                    headerText="Other Source of Funding"
                    width="200"
                    allowFiltering={false}
                    allowEditing={true}
                  />
                  <ColumnDirective
                    field="fundingAmount"
                    headerText="Amount of Other Funding"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="fundingGap"
                    headerText="Funding Gap"
                    width="200"
                    allowFiltering={false}
                    format="C"
                    allowEditing={false}
                    template={fundingGapTemplate}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    CommandColumn,
                    Sort,
                    Filter,
                    DetailRow,
                  ]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Landscaping
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500"></p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleLandscaping}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isLandscapingVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isLandscapingVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={landscapingGridRef}
                dataSource={landscapingData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginLandscaping}
                actionComplete={actionCompleteLandscaping}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="landscapingID"
                    headerText="landscapingID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="200"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "3")
                        .categoryName
                    }
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    template={categoryTemplate}
                    width="250"
                    editType="dropdownedit"
                    allowEditing={false}
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "3")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="200"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="photo_landscaping"
                    headerText="Photo Attachment"
                    width="200"
                    template={landscapingTemplate}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Donated Goods/Services"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />

                  <ColumnDirective
                    field="funding"
                    headerText="Other Source of Funding"
                    width="200"
                    allowFiltering={false}
                    allowEditing={true}
                  />
                  <ColumnDirective
                    field="fundingAmount"
                    headerText="Amount of Other Funding"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="fundingGap"
                    headerText="Funding Gap"
                    width="200"
                    allowFiltering={false}
                    format="C"
                    allowEditing={false}
                    template={fundingGapTemplate}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    CommandColumn,
                    Sort,
                    Filter,
                    DetailRow,
                  ]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Vehicle(s)
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500"></p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleVehicle}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isVehicleVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isVehicleVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={vehicleGridRef}
                dataSource={vehicleData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginVehicle}
                actionComplete={actionCompleteVehicle}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="vehicleID"
                    headerText="vehicleID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    allowEditing={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "4")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    allowEditing={false}
                    template={categoryTemplate}
                    width="250"
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "4")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="200"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="photo_vehicle"
                    headerText="Photo Attachment"
                    width="200"
                    template={vehicleTemplate}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Donated Goods/Services"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />

                  <ColumnDirective
                    field="funding"
                    headerText="Other Source of Funding"
                    width="200"
                    allowFiltering={false}
                    allowEditing={true}
                  />
                  <ColumnDirective
                    field="fundingAmount"
                    headerText="Amount of Other Funding"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="fundingGap"
                    headerText="Funding Gap"
                    width="200"
                    allowFiltering={false}
                    format="C"
                    allowEditing={false}
                    template={fundingGapTemplate}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    CommandColumn,
                    Sort,
                    Filter,
                    DetailRow,
                  ]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Contents
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500"></p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleContents}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isContentsVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isContentsVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={contentsGridRef}
                dataSource={contentsData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginContents}
                actionComplete={actionCompleteContents}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="contentsID"
                    headerText="contentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    allowEditing={false}
                    width="120"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "5")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    template={categoryTemplate}
                    width="250"
                    editType="dropdownedit"
                    allowEditing={false}
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "5")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="200"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="photo_contents"
                    headerText="Photo Attachment"
                    width="200"
                    template={contentsTemplate}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Donated Goods/Services"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    allowEditing={false}
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />

                  <ColumnDirective
                    field="funding"
                    headerText="Other Source of Funding"
                    width="200"
                    allowFiltering={false}
                    allowEditing={true}
                  />
                  <ColumnDirective
                    field="fundingAmount"
                    headerText="Amount of Other Funding"
                    width="200"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="fundingGap"
                    headerText="Funding Gap"
                    width="200"
                    allowFiltering={false}
                    format="C"
                    allowEditing={false}
                    template={fundingGapTemplate}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    CommandColumn,
                    Sort,
                    Filter,
                    DetailRow,
                  ]}
                />
              </GridComponent>
            </div>
          )}
        </section>
        <ImageDialog
          isOpen={isImageDialogOpen}
          onClose={() => setIsImageDialogOpen(false)}
          images={selectedImages}
          currentIndex={currentImageIndex}
          setCurrentIndex={setCurrentImageIndex}
        />
      </div>
    </div>
  );
};

export default CenterRecoveryPlanDisasters;
