import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const SurveyQuestion = ({
  question,
  type,
  options,
  onResponseChange,
  userResponse,
  followUp,
  section,
  disabled,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [followUpResponse, setFollowUpResponse] = useState("");

  useEffect(() => {
    if (type === "checkbox") {
      setCheckedItems(userResponse?.answer || {});
    } else if (type === "text") {
      setInputValue(userResponse?.answer || "");
    }

    if (
      userResponse?.answer === "Other" ||
      (type === "checkbox" && userResponse?.answer?.Other)
    ) {
      setShowFollowUp(true);
      setFollowUpResponse(userResponse.followUp || "");
    } else {
      setShowFollowUp(false);
      setFollowUpResponse("");
    }
  }, [userResponse, type]);

  const handleRadioChange = (option) => {
    const isOther = option === "Other";
    setShowFollowUp(isOther);
    setFollowUpResponse(isOther ? userResponse?.followUp || "" : "");

    onResponseChange(question, {
      answer: option,
      followUp: isOther ? followUpResponse : undefined,
    });
  };

  const handleCheckboxChange = (option) => {
    if (option === "Check All") {
      const allChecked =
        Object.values(checkedItems).every(Boolean) &&
        Object.keys(checkedItems).length === options.length - 1;
      const newCheckedItems = {};
      options.forEach((opt) => {
        if (opt !== "Check All") {
          newCheckedItems[opt] = !allChecked;
        }
      });
      setCheckedItems(newCheckedItems);
      onResponseChange(question, { answer: newCheckedItems });
      return;
    }
    
    const newCheckedItems = {
      ...checkedItems,
      [option]: !checkedItems[option],
    };
    setCheckedItems(newCheckedItems);
    const isOther = option === "Other" && newCheckedItems[option];
    setShowFollowUp(isOther);
    if (!isOther) setFollowUpResponse("");
    onResponseChange(question, {
      answer: newCheckedItems,
      followUp: isOther ? followUpResponse : undefined,
    });
  };

  const handleFollowUpChange = (event) => {
    const value = event.target.value;
    setFollowUpResponse(value);
    onResponseChange(question, {
      answer: userResponse?.answer,
      followUp: value,
    });
  };

  const renderRadioButtons = () => {
    return options.map((option, index) => (
      <label
        key={index}
        className="mb-2 flex w-full cursor-pointer items-start"
      >
        <input
          className="form-radio h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] appearance-none border-gray-300 text-blue-600"
          type="radio"
          name={question.replace(/\s+/g, "-").toLowerCase()}
          value={option}
          checked={userResponse?.answer === option}
          onChange={() => handleRadioChange(option)}
          disabled={disabled}
        />
        <span className="ml-2 break-words text-sm text-gray-800">{option}</span>
      </label>
    ));
  };

  const renderCheckboxes = () => {
    if (question === "Do you have insurance on your home or contents?") {
      return (
        <div className="flex flex-col gap-2">
          {options.includes("Check All") && (
            <label key="check-all" className="flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={
                  Object.values(checkedItems).every(Boolean) &&
                  Object.keys(checkedItems).length === options.length - 1
                }
                onChange={() => handleCheckboxChange("Check All")}
                disabled={disabled}
              />
              <span className="text-gray-700 ml-2 text-sm">Check All</span>
            </label>
          )}
          {options
            .filter((opt) => opt !== "Check All")
            .map((option, index) => (
              <label key={index} className="flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  checked={checkedItems[option] || false}
                  onChange={() => handleCheckboxChange(option)}
                  disabled={disabled}
                />
                <span className="text-gray-700 ml-2 text-sm">{option}</span>
              </label>
            ))}
        </div>
      );
    } else if (question === "There is visible damage to the home's:") {
      return (
        <div className="grid grid-cols-3 gap-4">
          {options.includes("Check All") && (
            <label key="check-all" className="flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={
                  Object.values(checkedItems).every(Boolean) &&
                  Object.keys(checkedItems).length === options.length - 1
                }
                onChange={() => handleCheckboxChange("Check All")}
                disabled={disabled}
              />
              <span className="text-gray-700 ml-2 text-sm">Check All</span>
            </label>
          )}
          {options
            .filter((opt) => opt !== "Check All")
            .map((option, index) => (
              <label key={index} className="flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  checked={checkedItems[option] || false}
                  onChange={() => handleCheckboxChange(option)}
                  disabled={disabled}
                />
                <span className="text-gray-700 ml-2 text-sm">{option}</span>
              </label>
            ))}
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-2">
        {options.map((option, index) => (
          <label key={index} className="flex cursor-pointer items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              checked={checkedItems[option] || false}
              onChange={() => handleCheckboxChange(option)}
              disabled={disabled}
            />
            <span className="text-gray-700 ml-2 text-sm">{option}</span>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="box-border flex w-full flex-col overflow-hidden rounded-mini border border-gainsboro-300 bg-ghostwhite-100 shadow">
      <div className="bg-gray-100 pb-3 pl-6 pr-6 pt-6 text-sm font-semibold text-darkslategray-100">
        {section && (
          <h3 className="text-lg mb-4 font-bold text-blue-800">{section}</h3>
        )}
        {question}
      </div>
      <div className="mb-4 px-6">
        {type === "radio" && (
          <div className="space-y-2">{renderRadioButtons()}</div>
        )}
        {type === "checkbox" && (
          <div className="flex flex-col gap-2">{renderCheckboxes()}</div>
        )}
        {type === "text" && (
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="w-full rounded border p-2"
            disabled={disabled}
          />
        )}
        {showFollowUp && (
          <div className="ml-4 mt-2">
            <label className="mb-2 block font-medium">Please specify:</label>
            <input
              type="text"
              value={followUpResponse}
              onChange={handleFollowUpChange}
              className="w-full rounded border p-2"
              disabled={disabled}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SurveyQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["radio", "checkbox", "text"]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onResponseChange: PropTypes.func.isRequired,
  userResponse: PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    followUp: PropTypes.string,
  }),
  followUp: PropTypes.shape({
    condition: PropTypes.string,
    question: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  section: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SurveyQuestion;
