import PropTypes from "prop-types";
import { ReactComponent as LibraryIcon } from "../icons/home/library.svg";
import { ReactComponent as MapsIcon } from "../icons/home/maps.svg";
import { ReactComponent as JobOpportunitiesIcon } from "../icons/home/job-opportunities.svg";
import { ReactComponent as VolunteerOpportunitiesIcon } from "../icons/home/volunteer-opportunities.svg";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort, Edit } from "@syncfusion/ej2-react-grids";
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { doc, getDoc, Query, updateDoc, setDoc } from "firebase/firestore";
import DisasterDashboard from "./center-pov/DisasterDeclaration/DisasterDashboard.js";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AnalyticsIcon } from "../icons/home/analytics.svg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../components/AuthContext";
import { auth } from "../firebase";
import { decrypt } from "../service/encryption/Encryption";
import { useGeneralContext } from "../context/GeneralContext";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const CitizenHome = ({ className = "" }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [activeData, setActiveData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [disasterNames, setDisasterNames] = useState([]);
  const [selectedDisaster, setSelectedDisaster] = useState("");
  const [selectedDisasterId, setSelectedDisasterId] = useState("");
  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const datepickerparams = {
    params: {
      value: new Date(),
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: true,
    },
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };
  const { login, setRoleBasedRoute } = useAuth();
  const { employeeId, setEmployeeIdfunc } = useGeneralContext();

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await getDoc(doc(db, "users", userId));

        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          const userData = userDoc.data();
          setUserRole(role);

          // if (!userData?.center_id || !userData?.county) {
          //   console.error("Error: center_id or county is undefined");
          //   return;
          // }

          try {
            let q;

            q = query(
              collection(db, "declared-disaster"),
              where("disaster_status", "==", "active"),
              where("is_deleted", "==", false),
              where("center_id", "==", userData?.center_id),
              where("affected_counties", "array-contains", userData?.county)
            );

            const querySnapshot = await getDocs(q);
            const disasters = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              name: doc.data().disaster_name,
            }));

            setDisasterNames(disasters);

            const activeData = querySnapshot.docs.map((doc) => ({
              disaster_id: doc.id,
              disaster_name: doc.data().disaster_name,
              disaster_status: doc.data().disaster_status,
              start_date: doc.data().start_date
                ? doc.data().start_date.toDate().toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })
                : null,
              county: doc.data().affected_counties,
            }));

            setActiveData(activeData);
            if (disasters.length > 0) {
              setSelectedDisaster(disasters[0].name);
              setSelectedDisasterId(disasters[0].id);
            }
            setLoading(false);
          } catch (error) {
            console.error("Error fetching disaster names:", error);
          }
        }
      }
      // Check if the URL contains an employee_id parameter
      const urlParams = new URLSearchParams(window.location.search);
      const employeeId = urlParams.get("employee_id");
      console.log("Employee ID:", employeeId);
      let login2 = sessionStorage.getItem("login");

      if (login2 && employeeId) {
        console.log("login", login2);
        const loginData = JSON.parse(login2);
        const creds = await signInWithEmailAndPassword(
          auth,
          loginData.email,
          decrypt(loginData.password)
        );

        setRoleBasedRoute(loginData.orgType);
        login(creds.user.accessToken);
        sessionStorage.clear();
        localStorage.setItem("employeeId", employeeId);
        setEmployeeIdfunc(employeeId);
      }
    };

    fetchUserData();
  }, []);

  const handleNavigate = (route) => {
    navigate(`${route}`);
  };

  if (loading) {
    return <div className=""></div>;
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Home
        </a>
      </div>
      {/* Home Resource Start */}

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <div>
          <h2 className="mb-1 text-sm font-bold text-blue-500">RESOURCES</h2>
        </div>
        <div className="box-border flex max-w-full flex-row flex-wrap items-start justify-center gap-[27px] self-stretch rounded-3xs px-0 py-0 font-inter text-gray-500">
          <div
            onClick={() => handleNavigate("/library/certificationvideos")}
            className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-between gap-[-2px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[30px] py-[42px] text-left  text-base text-darkslateblue [row-gap:20px] hover:cursor-pointer  mq450:flex-wrap`}
          >
            <div className="flex w-[251.8px] flex-col items-start justify-center gap-[20px]">
              <b className="relative inline-block min-w-[91px] leading-[20px] ">
                Visit Library
              </b>
              <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                Learn more about specific types of disasters, response, and
                recovery.
              </div>
            </div>
            <LibraryIcon className="relative h-[65px] w-[65px] shrink-0 overflow-hidden mq450:ml-0" />
          </div>
          <div
            onClick={() => handleNavigate("/map")}
            className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-start gap-[8px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[29px] py-[42px] text-left  text-base text-darkslateblue hover:cursor-pointer mq450:flex-wrap ${className}`}
          >
            <div className="flex min-w-[155px] flex-1 flex-col items-start justify-center gap-[20px]">
              <a className="relative inline-block min-w-[70px] font-bold leading-[20px] text-[inherit] [text-decoration:none]">
                Visit Map
              </a>
              <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                See active long term recovery groups and local resources.
              </div>
            </div>
            <MapsIcon className="relative h-[70px] w-[70px] shrink-0 overflow-hidden" />
          </div>

          <div
            onClick={() => handleNavigate("/opportunities/jobopportunities")}
            className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-start gap-[8px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[29px] py-[42px] text-left  text-base text-darkslateblue hover:cursor-pointer mq450:flex-wrap ${className}`}
          >
            <div className="flex min-w-[152px] flex-1 flex-col items-start justify-center gap-[20px]">
              <a className="relative font-bold leading-[20px] text-[inherit] [text-decoration:none]">
                Visit Job Opportunities
              </a>
              <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                Explore job opportunities available in a disaster area.
              </div>
            </div>
            <JobOpportunitiesIcon className="relative h-[75px] w-[75px] shrink-0 overflow-hidden" />
          </div>

          <div
            onClick={() =>
              handleNavigate("/opportunities/volunteeropportunities")
            }
            className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-start gap-[8px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[29px] py-[42px] text-left  text-base text-darkslateblue hover:cursor-pointer mq450:flex-wrap ${className}`}
          >
            <div className="flex min-w-[155px] flex-1 flex-col items-start justify-center gap-[20px]">
              <b className="relative leading-[20px]">
                Visit Volunteer Opportunities
              </b>
              <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                Explore volunteer opportunities available in a disaster area.
              </div>
            </div>
            <VolunteerOpportunitiesIcon className="relative h-[70px] w-[70px] shrink-0 overflow-hidden" />
          </div>
        </div>
      </section>
      {/* Home Resource End */}

      {/* Active Disaster Grid Start */}
      <section className="mt-4 w-full rounded-md border border-gray-200 bg-white pr-4">
        <h1 className="mb-3 text-base font-bold text-blue-500">
          ACTIVE DISASTERS
        </h1>

        <GridComponent
          dataSource={activeData}
          allowSorting={true}
          sortSettings={sortSettings}
          filterSettings={filterSettings}
          allowFiltering={true}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="disaster_id"
              headerText="Disaster ID"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="disaster_name"
              headerText="Disaster"
              width="150"
              textAlign="Left"
              editType="stringedit"
            />
            <ColumnDirective
              field="disaster_status"
              headerText="Status"
              width="100"
              textAlign="Left"
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: [
                    { text: "Active", value: "Active" },
                    { text: "Inactive", value: "Inactive" },
                  ],
                  fields: { text: "text", value: "value" },
                  query: new Query(),
                },
              }}
            />
            <ColumnDirective
              field="start_date"
              headerText="Date"
              width="100"
              format="MM/dd/yyyy"
              editType="datepickeredit"
              edit={datepickerparams}
            />
            <ColumnDirective
              field="county"
              headerText="Affected Counties"
              width="100"
              editType="stringedit"
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Edit,
              CommandColumn,
              Toolbar,
              Filter,
              ColumnMenu,
            ]}
          />
        </GridComponent>
      </section>
      {/* Active Disaster Grid End */}
    </div>
  );
};

export default CitizenHome;
