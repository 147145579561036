import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import {
  FaEdit,
  FaTrash,
  FaEye,
  FaInfoCircle,
  FaTimes,
} from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { USStates } from "./../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import "./../style.css";

const Journals = ({ className = "", disasterStage }) => {
  console.log("Disaster Stage in Journals:", disasterStage);

  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [data, setData] = useState([]);
  const [disasterJournals, setDisasterJournals] = useState([]);
  const gridRef = useRef(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const now = new Date();
  const [editing, setEditing] = useState({
      allowEditing: false,
      allowAdding: true,
      allowDeleting: false,
      mode: "Dialog",
      dialog: { cssClass: "custom-dialog" },
      allowEditOnDblClick: false,
  });
  const [showGuidePopup, setShowGuidePopup] = useState(false);

  const [openSections, setOpenSections] = useState({
      phase1: true,
      phase3: true,
  });

  const toggleSection = (section, event) => {
    event.stopPropagation();
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const GuidePopup = ({ onClose }) => {
    const popupRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          popupRef.current &&
          (popupRef.current.contains(event.target) || event.target.closest("[data-ignore-close]"))
        ) {
          return; // Ignore clicks inside the popup or on toggle button
        }

        popupRef.current.classList.add("animate-popup-out");
        setTimeout(onClose, 500);
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    return (
      <div
        ref={popupRef}
        className="absolute top-[50px] right-0 h-[calc(100vh-50px)] w-[500px] bg-white border border-gray-200 shadow-2xl p-4 z-[9999]">
        <div className="flex items-center justify-between border-b pb-2 mb-2">
          <h3 className="font-bold text-lg">Journal Writing Guide</h3>
          <button
            className="text-red-600 bg-white hover:text-gray-700 text-26xl font-bold"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        </div>

        <div className="flex flex-col font-Inter overflow-y-auto mb-4 max-h-[calc(100vh-120px)]">
            <div className="font-Inter w-full flex justify-between items-center gap-1 text-white bg-[#349bf1] px-3">
              <p className="text-xl font-Inter mt-2 mb-2 font-bold">PHASE 1</p>
              <svg
                className={`h-6 w-6 transform transition-transform cursor-pointer ${
                  openSections.phase1 ? "rotate-90" : "rotate-180"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                onClick={(event) => toggleSection("phase1", event)}
                data-ignore-close="true"
              >
                <path d="M6 6L14 10L6 14V6Z" />
              </svg>
            </div>
            {openSections.phase1 && (
                <>
                    <div className="flex px-2 mt-2">
                        <img src={require('./../../assets/journals-icons/before-disaster.svg').default} alt="Before Disaster" width="20" height="20" />
                        <p className="font-base text-[#188AEB] px-1">Before the Disaster</p>
                    </div>
                    <ol className="font-sm mt-2 list-decimal pl-[30px]">
                        <li className="mb-1">What was your daily life like before the disaster?</li>
                        <li className="mb-1">Did you have any emergency plans or preparations in place? If so, what were they?</li>
                        <li className="mb-1">What concerns, if any, did you have about disasters before this event?</li>
                    </ol>
                    <div className="flex px-2 mt-2">
                        <img src={require('./../../assets/journals-icons/during-disaster.svg').default} alt="Before Disaster" width="20" height="20" />
                        <p className="font-base text-[#188AEB] px-1">During the Disaster</p>
                    </div>
                    <ol className="font-sm mt-2 list-decimal pl-[30px]">
                        <li className="mb-1">How did you first realize the disaster was happening?</li>
                        <li className="mb-1">Where were you when the disaster occurred, and what were your first actions?</li>
                        <li className="mb-1">What immediate steps did you take to protect yourself and your loved ones?</li>
                        <li className="mb-1">What emotions did you experience during the disaster?</li>
                        <li className="mb-1">Were you able to communicate with anyone for help or information? If so, how?</li>
                    </ol>
                    <div className="flex px-2 mt-2">
                        <img src={require('./../../assets/journals-icons/immediate-aftermath.svg').default} alt="Before Disaster" width="20" height="20" />
                        <p className="font-base text-[#188AEB] px-1">Immediate Aftermath</p>
                    </div>
                    <ol className="font-sm mt-2 list-decimal pl-[30px]">
                        <li className="mb-1">What was the first thing you noticed after the disaster had passed?</li>
                        <li className="mb-1">What were the biggest challenges you faced in the first few hours or days?</li>
                        <li className="mb-1">Did you receive any assistance? If so, from whom (family, neighbors, first responders, organizations, etc.)?</li>
                        <li className="mb-1">What personal items, belongings, or spaces were lost or damaged?</li>
                        <li className="mb-1">How did the disaster affect your ability to meet basic needs (food, water, shelter, medical care)?</li>
                        <li className="mb-1">What has been the biggest impact to you personally?</li>
                    </ol>
                </>
            )}
            <div className="font-Inter w-full mt-3 flex justify-between items-center gap-1 text-white bg-[#349bf1] px-3">
              <p className="text-xl font-Inter mt-2 mb-2 font-bold">PHASE 2</p>
              <svg
                className={`h-6 w-6 transform transition-transform cursor-pointer ${
                  openSections.phase2 ? "rotate-90" : "rotate-180"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                onClick={(event) => toggleSection("phase2", event)}
                data-ignore-close="true"
              >
                <path d="M6 6L14 10L6 14V6Z" />
              </svg>
            </div>
            {openSections.phase2 && (
                <>
                    <div className="flex px-2 mt-2">
                        <img src={require('./../../assets/journals-icons/ongoing-impact-recovery.svg').default} alt="Before Disaster" width="20" height="20" />
                        <p className="font-base text-[#188AEB] px-1">Ongoing Impact & Recovery</p>
                    </div>
                    <ol className="font-sm mt-2 list-decimal pl-[30px]">
                        <li className="mb-1">How has the disaster affected your daily life (work, school, home, relationships, etc.)?</li>
                        <li className="mb-1">What are the biggest challenges you continue to face?</li>
                        <li className="mb-1">Have you been able to rebuild or recover in any way? If so, how?</li>
                        <li className="mb-1">What types of support have you received since the disaster, and from whom?</li>
                        <li className="mb-1">How has your mental and emotional health been affected by this experience?</li>
                    </ol>
                </>
            )}
            <div className="font-Inter w-full mt-3 flex justify-between items-center gap-1 text-white bg-[#349bf1] px-3">
              <p className="text-xl font-Inter mt-2 mb-2 font-bold">PHASE 3</p>
              <svg
                className={`h-6 w-6 transform transition-transform cursor-pointer ${
                  openSections.phase3 ? "rotate-90" : "rotate-180"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                onClick={(event) => toggleSection("phase3", event)}
                data-ignore-close="true"
              >
                <path d="M6 6L14 10L6 14V6Z" />
              </svg>
            </div>
            {openSections.phase3 && (
                <>
                    <div className="flex px-2 mt-2">
                        <img src={require('./../../assets/journals-icons/reflections-looking-forward.svg').default} alt="Before Disaster" width="20" height="20" />
                        <p className="font-base text-[#188AEB] px-1">Reflections & Looking Forward</p>
                    </div>
                    <ol className="font-sm mt-2 list-decimal pl-[30px]">
                        <li className="mb-1">What lessons have you learned from this experience?</li>
                        <li className="mb-1">Has your perspective on disasters, preparedness, or community changed?</li>
                        <li className="mb-1">What advice would you give to someone who might experience a similar disaster?</li>
                        <li className="mb-1">What do you need most to fully recover?</li>
                        <li className="mb-4">What are your hopes for the future?</li>
                    </ol>
                </>
            )}
        </div>
      </div>
    );
  };

  const sortSettings = {
      allowSorting: true,
    };
    const filterSettings = {
      type: "CheckBox",
    };
    const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

    const pageSettings = { pageSize: 10 };

  const fetchJournals = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "disaster-journals"));
        const journalsData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((journal) => !journal.is_deleted && journal.userId === userId);

        setDisasterJournals(journalsData);

        setEditing((prevEditing) => ({
          ...prevEditing,
          allowAdding: journalsData.length > 0,
        }));
      } catch (error) {
        console.error("Error fetching Journals:", error);
      }
  };

  useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUserId(currentUser.uid);
          fetchUserRole(currentUser.uid);
        } else {
          setData(null);
          setUserRole(null);
        }
      });

      return () => unsubscribe();
  }, []);

  useEffect(() => {
      if (userId) {
        fetchJournals();
      }
  }, [userId]);

  const fetchUserRole = async (uid) => {
      try {
        const userDoc = await getDoc(doc(db, "users", uid));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);

          if (role === "citizen" || role === "partner") {
            setEditing(prevEditing => ({
              ...prevEditing,
              allowEditing: true,
              allowAdding: true,
              allowDeleting: false
            }));
          } else if (role === "center") {
            setEditing(prevEditing => ({
              ...prevEditing,
              allowEditing: true,
              allowAdding: true,
              allowDeleting: true
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
  };

  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = now.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
  });

  const generateJournalId = () => {
     return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleAddJournal = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const getEditorTypeColor = (editorType) => {
      switch (editorType) {
        case 'center': return 'green-500';
        case 'partner': return 'yellow-500';
        case 'citizen': return 'blue-500';
        default: return 'gray-500';
      }
  };

  const getEditorTypeLetter = (editorType) => {
      switch (editorType) {
        case 'center': return 'C';
        case 'partner': return 'P';
        case 'citizen': return 'S';
        default: return '?';
      }
  };

  const getEditorTypeLabel = (editorType) => {
      switch (editorType) {
        case 'center': return 'Center';
        case 'partner': return 'Partner';
        case 'citizen': return 'Survivor';
        default: return 'Unknown';
      }
  };

  const privacyOptions = [
    { value: "Public" },
    { value: "Private" },
    { value: "Restricted" },
  ];

  const showPopup = (message) => {
      setPopupMessage(message);
      setIsPopupOpen(true);
  };

  const actionBegin = async (args) => {
      if (args.requestType === "add") {
          const currentDate = new Date();
          const formattedDate = `${
              currentDate.getMonth() + 1
          }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
          const formattedTime = currentDate.toTimeString().split(' ')[0];

          args.data.journalDate = formattedDate;
          args.data.journalTime = formattedTime;
      }

      if (args.requestType === "save") {
          const data = args.data;

          const disaster = data.disaster || "";
          const description = data.description || "";
          const date = data.journalDate || formattedDate;
          const time = data.journalTime || formattedTime;

          if (!disaster.trim() || !description.trim()) {
              args.cancel = true;
              alert("Please fill in disaster and description before saving.");
              return;
          }

          data.journalId = generateJournalId();
          data.userId = userId;
          data.userRole = userRole;
          data.editorId = userId;
          data.editorType = userRole;
          data.journalDate = date;
          data.journalTime = time;

          try {
              if (args.action === "add") {
                  data.inserted_at = serverTimestamp();
                  const docRef = await addDoc(
                      collection(db, "disaster-journals"),
                      data
                  );
                  data.id = docRef.id;
              } else if (args.action === "edit") {
                  if (userRole === "citizen" || userRole === "partner") {
                    if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
                      args.cancel = true;
                      showPopup("You don't have permission to edit this record.");
                      return;
                    }
                  }
                  args.rowData = { ...args.rowData };
              }

              setDisasterJournals((prevJournals) => {
                  const index = prevJournals.findIndex(
                      (journal) => journal.id === data.id
                  );
                  if (index !== -1) {
                      return prevJournals.map((journal) =>
                          journal.id === data.id ? { ...journal, ...data } : journal
                      );
                  } else {
                      return [...prevJournals, data];
                  }
              });
          } catch (error) {
              console.error("Error saving document:", error);
          }
      }
      else if (args.requestType === "delete") {
          try {
              const deleteData = args.data[0];
              const docRef = doc(db, "disaster-journals", deleteData.id);

              const confirmDelete = window.confirm(
                  `Are you sure you want to delete the journal entry for disaster "${deleteData.disaster}"?`
              );

              if (confirmDelete) {
                  await updateDoc(docRef, { is_deleted: true });

                  setDisasterJournals((prevJournals) =>
                      prevJournals.filter((journal) => journal.id !== deleteData.id)
                  );

                  alert("Journal entry successfully deleted.");
              } else {
                  args.cancel = true;
              }
          } catch (error) {
              console.error("Error deleting document:", error);
              alert("An error occurred while deleting the journal entry.");
          }
      }
  };

  const handleSaveJournal = async (e) => {
    if (e.key === "Enter") {
      const description = e.target.value.trim();
      if (!description) return;

      const now = new Date();

      const journalEntry = {
        description,
        journalId: generateJournalId(),
        userId,
        userRole,
        editorId: userId,
        editorType: userRole,
        timestamp: serverTimestamp(),
        inserted_at: serverTimestamp(),
      };

      try {
        const docRef = await addDoc(collection(db, "disaster-journals"), journalEntry);
        setDisasterJournals((prevJournals) => [...prevJournals, { ...journalEntry, id: docRef.id }]);
        e.target.value = "";
      } catch (error) {
        console.error("Error saving journal entry:", error);
      }
    }
  };

  const dateTemplate = (props) => {
    if (!props.timestamp) return null;

    const dateObj = props.timestamp.toDate();
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' }).toUpperCase();
    const year = dateObj.getFullYear();

    return (
        <div className="flex">
            <div className="flex items-center space-x-1">
                <span
                    className={`w-6 h-6 rounded-full bg-${getEditorTypeColor(props.editorType)} flex items-center justify-center text-white text-xs font-bold`}
                    title={`Editor Type: ${getEditorTypeLabel(props.editorType)}`}
                >
                    {getEditorTypeLetter(props.editorType)}
                </span>
            </div>
            <div className="flex w-[35px] flex-col items-start justify-start ml-2">
                <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]">
                    {day}
                </a>
                <b className="relative flex w-[34px] items-center text-xs">
                    {month}
                </b>
                <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]" style={{ fontSize: '10px' }}>
                    {year}
                </a>
            </div>
        </div>
    );
};

  useEffect(() => {
    if (showGuidePopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showGuidePopup]);

  useEffect(() => {
    // Set initial open sections based on disasterStage
    if (disasterStage === "Relief" || disasterStage === "Response") {
      setOpenSections({
        phase1: true,
        phase2: true,
        phase3: false,
      });
    } else if (disasterStage === "Recovery") {
      setOpenSections({
        phase1: false,
        phase2: false,
        phase3: true,
      });
    } else {
      setOpenSections({
        phase1: true,
        phase2: false,
        phase3: false,
      });
    }
  }, [disasterStage]);

  return (
    <div
        className={`flex w-[1628px] max-w-full flex-col items-start justify-start bg-white text-left leading-[normal] tracking-[normal] text-heading-title-color mb-0 ${className}`}
    >
        <div className="relative mb-2 inline-block max-w-full font-poppins text-[15px] leading-[21px] text-darkslategray-200">
           <p>
              The Disaster Journal provides a space for you to share your personal
              experiences and reflections throughout the recovery process. Your
              story is a vital part of understanding the full impact of the disaster
              and will help shape future recovery efforts. Please take the time to
              document your journey and insights.
           </p>
        </div>
        <div className="flex flex-end mb-2 w-full">
          <div className="w-1/2">
             <h2 className="text-[18px] font-bold text-blue-500">
                JOURNAL
             </h2>
          </div>
          <div className="w-1/2 flex justify-end">
             <button
                className="flex items-center gap-1 text-sm btn-default-border p-2 bg-white hover:cursor-pointer"
                onClick={() => {
                    setShowGuidePopup(!showGuidePopup);
                    console.log("Guide Popup State:", !showGuidePopup);
                }}
             >
                <FaInfoCircle className="text-[#467ba4] text-base"/>
                Show Guide
             </button>
          </div>
        </div>
        <div className="flex flex-col h-[calc(100vh-325px)]">
          <div className="flex-1 overflow-y-auto mb-2">
            <DisasterJournalList disasterJournals={disasterJournals} setDisasterJournals={setDisasterJournals} />
          </div>
          <div className="flex w-full mt-auto journal-textarea-cont">
            <div className="w-full">
              <textarea
                placeholder="Recording your experiences can be a powerful way to document what you've been through. Write it here."
                className="w-full h-[100px] font-Inter outline-none p-2 resize-none"
                onKeyDown={handleSaveJournal}
              />
            </div>
          </div>
        </div>
        {showGuidePopup && <GuidePopup onClose={() => setShowGuidePopup(false)} />}
    </div>
  );
};

const DisasterJournalList = ({ disasterJournals, setDisasterJournals }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedDescription, setEditedDescription] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [journalToDelete, setJournalToDelete] = useState(null);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return '';

    const date = timestamp.toDate();
      return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
    };
    const sortedJournals = [...disasterJournals].sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 0;
      return a.timestamp.seconds - b.timestamp.seconds;
    });

  const handleEditClick = (entry) => {
    setEditingId(entry.id);
    setEditedDescription(entry.description);
  };

  const handleSaveEdit = async (entry) => {
    try {
      const docRef = doc(db, "disaster-journals", entry.id);
      await updateDoc(docRef, {
        description: editedDescription
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.map(journal =>
          journal.id === entry.id
            ? { ...journal, description: editedDescription }
            : journal
        )
      );

      setEditingId(null);
    } catch (error) {
      console.error("Error updating journal:", error);
      alert("Failed to update journal entry");
    }
  };

  const handleDeleteClick = (entry) => {
    setJournalToDelete(entry);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
        const docRef = doc(db, "disaster-journals", journalToDelete.id);
        await updateDoc(docRef, {
            is_deleted: true
        });

        setDisasterJournals((prevJournals) =>
            prevJournals.filter(
                journal => journal.id !== journalToDelete.id
            )
        );

        setShowDeleteDialog(false);
        setJournalToDelete(null);
    } catch (error) {
        console.error("Error deleting journal:", error);
        alert("Failed to delete journal entry");
    }
};

  return (
    <div className="disaster-journals">
      {sortedJournals.map((entry, index) => (
        <div key={index} className="flex mb-2">
          <div className="inline-block rounded p-2 bg-[#F5F5F5] min-w-[200px]">
            <div className="text-left mb-2">
              {editingId === entry.id ? (
                <textarea
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  className="w-full p-2 rounded"
                  rows="3"
                />
              ) : (
                <p className="whitespace-pre-wrap" style={{ fontSize: '12px' }}>{entry.description}</p>
              )}
            </div>
            <div className="flex justify-end">
              {editingId !== entry.id && (
                <span className="text-xs text-gray-500">
                  {formatTimestamp(entry.timestamp)}
                </span>
              )}
              {editingId === entry.id ? (
                <>
                  <button
                    onClick={() => handleSaveEdit(entry)}
                    className="text-green-600 ml-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setEditingId(null)}
                    className="text-gray-600 ml-2"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => handleEditClick(entry)}
                    className="flex cursor-pointer gap-1 text-[#377db7] justify-end underline ml-2"
                  >
                    <FaPencilAlt className="h-3 w-3" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteClick(entry)}
                    className="flex cursor-pointer gap-1 text-red-600 justify-end underline ml-1"
                  >
                    <FaTrash className="h-3 w-3" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      ))}

      {showDeleteDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg">
            <p>Are you sure you want to delete this journal entry?</p>
            <div className="mt-4 flex justify-end gap-2">
              <button
                onClick={handleConfirmDelete}
                className="bg-red-600 text-white px-4 py-2 rounded"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setShowDeleteDialog(false);
                  setJournalToDelete(null);
                }}
                className="bg-gray-300 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Journals;