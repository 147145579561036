import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import Profile from "./Profile";
import Password from "./Password";
import Pin from "./Pin";
import ShowPin from "./ShowPin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { useGeneralContext } from "../../context/GeneralContext";
const Menu = ({ isOpen, onClose, initialData }) => {
  const [userData, setUserData] = useState(initialData || {});
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [pinShow, setPinShow] = useState(false);
  const menuRef = useRef(null);
  const { logout, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [hasOrganizations, setHasOrganizations] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showOrgs, setShowOrgs] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [employeeRole, setEmployeeRole] = useState(null);
  const [userDataId, setUserDataId] = useState(null);
  const { login } = useAuth();
  const [role, setRole] = useState(null);
  const { employeeId, setEmployeeIdfunc } = useGeneralContext();
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const userDataId = userDocSnap.id;
            setUserDataId(userDataId);
            setRole(userData.user_role);

            // Only check employee data for partner and center roles
            if (
              userData.user_role === "partner" ||
              userData.user_role === "center"
            ) {
              const collectionName =
                userData.user_role === "partner" ? "partners" : "centers";

              // Query employees subcollection directly across all organizations
              const employeesQuery = query(
                collection(
                  db,
                  `${collectionName}/${
                    userData.user_role === "partner"
                      ? userData.partner_id
                      : userData.center_id
                  }/employees`
                ),
                where("user_id", "==", employeeId)
              );

              const employeeSnapshot = await getDocs(employeesQuery);

              if (!employeeSnapshot.empty) {
                const employeeData = employeeSnapshot.docs[0].data();
                console.log(
                  `Found ${collectionName} employee data:`,
                  employeeData
                );
                setEmployeeData(employeeData);
                setRoles(employeeData.roles);
              } else {
                console.log("No employee data found");
              }
            }

            if (userData.user_role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              console.log("Center");
              const centerDocSnap = await getDoc(centerDocRef);
              if (centerDocSnap.exists()) {
                setUserData(centerDocSnap.data());

                console.log("Center data:", centerDocSnap.data());
              }
            } else if (userData.user_role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDocSnap = await getDoc(partnerDocRef);
              if (partnerDocSnap.exists()) {
                setUserData(partnerDocSnap.data());

                console.log("Partner data:", partnerDocSnap.data());
              }
            } else {
              setUserData(userData);
              console.log("User data:", userData);
              console.log("User data id:", userDataId);
              setUserRole(userData.user_role);

              fetchUserOrganizations(user);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchUserOrganizations = async (user) => {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userDocData = userDocSnap.data();
        if (userDocData) {
          console.log("User exists");
          const organizationsCollectionRef = collection(
            db,
            "users",
            user.uid,
            "organizations"
          );
          const organizationsCollectionSnap = await getDocs(
            organizationsCollectionRef
          );
          //
          if (!organizationsCollectionSnap.empty) {
            setHasOrganizations(true);
            const orgDocs = organizationsCollectionSnap.docs.map((doc) =>
              doc.data()
            );
            setOrganizations(orgDocs);
            console.log("Organizations:", orgDocs);
            console.log("Role:", role);
          } else {
            console.log("User does not have organizations");
          }
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen || showProfile || showPassword || showPin || pinShow) {
      const fetchUserData = async () => {
        const auth = getAuth();
        const db = getFirestore();
        const user = auth.currentUser;

        if (user) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              if (userData.user_role === "center") {
                const centerDocRef = doc(db, "centers", userData.center_id);
                const centerDocSnap = await getDoc(centerDocRef);
                if (centerDocSnap.exists()) {
                  setUserData(centerDocSnap.data());
                }
              } else if (userData.user_role === "partner") {
                const partnerDocRef = doc(db, "partners", userData.partner_id);
                const partnerDocSnap = await getDoc(partnerDocRef);
                if (partnerDocSnap.exists()) {
                  setUserData(partnerDocSnap.data());
                }
              } else {
                setUserData(userData);
              }
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      };

      fetchUserData();
    }
  }, [isOpen, showProfile, showPassword, showPin, pinShow]);

  if (!isOpen && !showProfile && !showPassword && !showPin && !pinShow)
    return null;

  const handleProfileUpdate = (updatedData) => {
    setUserData(updatedData);
  };

  const handleProfileClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowProfile(true), 100); // Open the Profile popup after a short delay
  };

  const handlePasswordClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPassword(true), 100); // Open the Password popup after a short delay
  };

  const handlePinShow = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setPinShow(true), 100); // Open the Pin popup after a short delay
  };

  const handlePinClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPin(true), 100); // Open the Pin popup after a short delay
  };

  const logoutClicked = () => {
    logout();
    navigate("/login");
    onClose(); // Close the menu after logging out
  };

  const switchCenterCitizenPov = () => {
    if (role == "center") {
      setRoleBasedRoute("citizen");
    } else {
      setRoleBasedRoute("center");
    }
    navigate("/home");
    onClose();
  };

  const switchPartnerCitizenPov = () => {
    if (role == "partner") {
      setRoleBasedRoute("citizen");
    } else {
      setRoleBasedRoute("partner");
    }
    navigate("/home");
    onClose();
  };

  const switchToAnOrg = async (org) => {
    console.log("Org:", org);
    let password;

    const db = getFirestore();
    const q = query(collection(db, "users"), where("email", "==", org.email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const userDocSnap = querySnapshot.docs[0];
      const userData = userDocSnap.data();
      console.log("User dataaa:", userData);
      password = userData.password;
    }

    const url = `/home?employee_id=${userDataId}`;
    sessionStorage.setItem(
      "login",
      JSON.stringify({
        email: org.email,
        password: password,
        orgType: org.org_type,
      })
    );
    console.log("url", url);
    window.open(url, "_blank"); // Open a new tab and redirect to login page with credentials
    onClose();
  };

  const switchRole = async (role) => {
    console.log("Switching role:", role);
    setEmployeeRole(role);
    setEmployeeData({ ...employeeData, default_role: role });

    const db = getFirestore();
    const collectionName = role === "partner" ? "partners" : "centers";

    // Check if we have both organization_id and employee_doc_id
    if (employeeData?.organization_id && employeeData?.employee_doc_id) {
      const employeeDocRef = doc(
        db,
        collectionName,
        employeeData.organization_id,
        "employees",
        employeeData.employee_doc_id
      );
      await updateDoc(employeeDocRef, { default_role: role });
      console.log("Employee data updated successfully");
      return;
    }

    // Fallback to search if we don't have complete reference info
    const collectionRef = collection(db, collectionName);
    const collectionSnapshot = await getDocs(collectionRef);

    for (const doc of collectionSnapshot.docs) {
      const employeesRef = collection(doc.ref, "employees");
      const snapshot = await getDocs(
        query(employeesRef, where("user_id", "==", employeeId))
      );

      if (!snapshot.empty) {
        const employeeDoc = snapshot.docs[0];
        // Store both IDs for future updates
        const updatedData = {
          default_role: role,
          organization_id: doc.id,
          employee_doc_id: employeeDoc.id,
        };
        await updateDoc(employeeDoc.ref, updatedData);
        // Update local state with the new IDs
        setEmployeeData((prev) => ({ ...prev, ...updatedData }));
        console.log("Employee data updated successfully");
        break;
      }
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-start justify-end overflow-auto bg-black bg-opacity-50 pt-14">
          <div
            ref={menuRef}
            className="mr-2 w-[400px] overflow-hidden rounded-b-lg bg-white shadow-xl"
          >
            <div>
              <h2 className="bg-blue px-4 pb-6 pt-6 text-base font-semibold">
                My Profile
              </h2>
            </div>
            <div className="flex items-center justify-center border-b p-4">
              {userData.profile_image ? (
                <img
                  src={userData.profile_image}
                  alt="Profile"
                  className="mr-4 h-16 w-16 rounded-full"
                />
              ) : (
                <div className="user-profile-thumbnail mr-4 flex h-16 w-16 items-center justify-center rounded-full">
                  {userData.first_name
                    ? userData.first_name.charAt(0).toUpperCase()
                    : "?"}
                </div>
              )}
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-lg pb-2 font-semibold text-black">
                  {userData.first_name
                    ? `${userData.first_name} ${userData.last_name || ""}`
                    : "User Name"}
                </h3>
                <p className="text-sm text-gray-800">
                  {userData.email || "user@example.com"}
                </p>
              </div>
            </div>
            <nav className="p-4">
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={handleProfileClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Profile
                  </button>
                </li>
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                <li>
                  <button
                    onClick={handlePasswordClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Change Password
                  </button>
                </li>
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                {role === "center" && (
                  <li>
                    <button
                      onClick={switchCenterCitizenPov}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Switch to {role == "center" ? "Citizen" : "Center"} POV
                    </button>
                  </li>
                )}
                {role === "center" && (
                  <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                )}

                {role === "partner" && (
                  <li>
                    <button
                      onClick={switchPartnerCitizenPov}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Switch to {role == "partner" ? "Citizen" : "Partner"} POV
                    </button>
                  </li>
                )}
                {role === "partner" && (
                  <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                )}

                {role === "citizen" && hasOrganizations && (
                  <li>
                    <button
                      onClick={() => setShowOrgs(!showOrgs)}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Switch Account
                    </button>
                    {showOrgs && (
                      <ul className="ml-4">
                        {organizations.map((org) => (
                          <li key={org.id}>
                            <button
                              onClick={() => switchToAnOrg(org)}
                              className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                            >
                              {org.org_type === "partner"
                                ? org.name_of_org
                                : org.name_of_hub}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
                {/* {role === "partner" && (
                  <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                )} */}
                {(role === "center" || role === "partner") &&
                  hasOrganizations && (
                    <li>
                      <button
                        onClick={() => setShowRoles(!showRoles)}
                        className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                      >
                        Switch Role
                      </button>
                      {showRoles && (
                        <ul className="ml-4">
                          {roles.map((roleOption) => (
                            <li key={roleOption}>
                              <button
                                onClick={() => switchRole(roleOption)}
                                className={`flex w-full cursor-pointer items-center justify-between rounded px-4 py-2 text-left text-[20px] font-semibold ${
                                  employeeData?.default_role === roleOption
                                    ? "bg-blue-100 text-blue-800"
                                    : "bg-white text-gray-800 hover:bg-gray-200"
                                }`}
                              >
                                <span>{roleOption}</span>
                                {employeeData?.default_role === roleOption && (
                                  <svg
                                    className="h-6 w-6 text-green-500"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                                  </svg>
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )}

                {(role === "citizen" ||
                  role === "partner" ||
                  role === "center") &&
                  hasOrganizations && (
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                  )}

                {(role === "citizen" || role === "partner") && (
                  <li>
                    <button
                      onClick={handlePinShow}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Show Lockbox PIN
                    </button>
                  </li>
                )}
                {(role === "citizen" || role === "partner") && (
                  <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                )}
              </ul>
              <div className="flex items-center justify-center pt-4">
                <button
                  onClick={logoutClicked}
                  className="btn-default block cursor-pointer px-4 py-2 hover:bg-blue-700"
                >
                  Sign Out
                </button>
              </div>
            </nav>
          </div>
        </div>
      )}
      {showProfile && userData && (
        <Profile
          isOpen={showProfile}
          onClose={() => setShowProfile(false)}
          data={userData}
          onUpdate={handleProfileUpdate}
        />
      )}
      {showPassword && (
        <Password
          isOpen={showPassword}
          onClose={() => setShowPassword(false)}
        />
      )}
      {(role === "citizen" || role === "partner") && showPin && (
        <Pin isOpen={showPin} onClose={() => setShowPin(false)} />
      )}
      {(role === "citizen" || role === "partner") && pinShow && (
        <ShowPin
          isOpen={pinShow}
          onClose={() => setPinShow(false)}
          firstTime={false}
        />
      )}
    </>
  );
};

export default Menu;
