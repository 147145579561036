import React, { useState, useRef, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Search,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db } from "../../../firebase";
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  getDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import mailer from "../../../service/api/mailer";
const filterSettings = {
  type: "CheckBox",
};

const sortSettings = {
  allowSorting: true,
};

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  showDeleteConfirmDialog: true,
  allowDeleting: true,
  allowEditOnDblClick: false,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const CaseManagement = () => {
  const [selectedDisasters, setSelectedDisasters] = useState(["All"]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([
    { id: "All", name: "All Disasters" },
  ]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Open");

  // Add this useEffect to fetch employees when dialog opens
  useEffect(() => {
    if (assignDialogOpen) {
      fetchEmployees();
    }
  }, [assignDialogOpen]);

  // Add this function to fetch employees
  const fetchEmployees = async () => {
    try {
      const employeesRef = collection(
        db,
        "centers",
        userData.center_id,
        "employees"
      );
      const employeesSnapshot = await getDocs(employeesRef);
      const employeesData = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        fullName: `${doc.data().first_name} ${doc.data().last_name}`,
      }));
      setEmployees(employeesData);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status,
        }));

        const activeDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "active"
        );

        setActiveDisasters(activeDisasters.map((disaster) => disaster.id));
        setDisasterOptions([
          { id: "All", name: "All Disasters" },
          ...activeDisasters,
        ]);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      if (disaster === "All") {
        return ["All"];
      }
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster && d !== "All")
        : [...prev.filter((d) => d !== "All"), disaster];
      return newSelection.length === 0 ? ["All"] : newSelection;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [gridData, setGridData] = useState([]);
  const [userData, setUserData] = useState(null);

  const mapCitizenData = async (citizens) => {
    const mappedCitizens = [];

    await Promise.all(
      citizens.map(async (citizen) => {
        try {
          // Fetch survey responses for this citizen
          const surveyQuery = query(
            collection(db, `users/${citizen.id}/survey_responses`)
          );
          const surveySnapshot = await getDocs(surveyQuery);

          // Group disasters by case number
          const caseGroups = new Map();

          surveySnapshot.forEach((doc) => {
            const surveyData = doc.data();
            if (surveyData.disasters && surveyData.case_number) {
              if (!caseGroups.has(surveyData.case_number)) {
                caseGroups.set(surveyData.case_number, []);
              }
              surveyData.disasters.forEach((disaster) => {
                caseGroups.get(surveyData.case_number).push(disaster);
              });
            }
          });

          // Fetch case management data
          for (const [caseNumber, disasters] of caseGroups) {
            // Check if case exists in case_management
            const caseManagementQuery = query(
              collection(db, "case_management"),
              where("case_number", "==", caseNumber)
            );
            const caseManagementSnapshot = await getDocs(caseManagementQuery);
            const caseManagementData =
              caseManagementSnapshot.docs[0]?.data() || {};

            mappedCitizens.push({
              id: citizen.id,
              name: `${citizen.first_name} ${citizen.last_name}`,
              address: `${citizen.address1}${
                citizen.address2 ? `, ${citizen.address2}` : ""
              }, ${citizen.city}, ${citizen.state}, ${citizen.zip}`,
              mobile_number: citizen.mobile_number,
              email: citizen.email,
              citizen_id: citizen.citizen_id,
              case_number: caseNumber,
              disaster_id: disasters[0].disaster_id,
              disaster_name: disasters.map((d) => d.disaster_name).join(", "),
              // Add case management fields
              assigned_to: caseManagementData.assigned_to || "",
              assigned_company: caseManagementData.assigned_company || "",
              supervisor: caseManagementData.supervisor || "",
              assignment_date: caseManagementData.assignment_date || "",
              status: caseManagementData.status || "Open",
            });
          }
        } catch (error) {
          console.error(`Error processing citizen ${citizen.id}:`, error);
        }
      })
    );

    return mappedCitizens;
  };

  useEffect(() => {
    const fetchCitizens = async () => {
      try {
        // Get current user's center ID
        const auth = getAuth();
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));

        if (!userDoc.exists()) {
          console.error("No user document found!");
          return;
        }

        setUserData(userDoc.data());
        const centerId = userDoc.data().center_id;

        // Query citizens belonging to this center
        const usersRef = collection(db, "users");
        const queryRef = query(
          usersRef,
          where("center_id", "==", centerId),
          where("user_role", "==", "citizen")
        );

        const querySnapshot = await getDocs(queryRef);
        const citizens = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const formattedData = await mapCitizenData(citizens);

        // Filter based on selected disasters if not "All"
        const filteredData = selectedDisasters.includes("All")
          ? formattedData
          : formattedData.filter((citizen) =>
              selectedDisasters.includes(citizen.disaster_id)
            );

        setGridData(filteredData);
      } catch (error) {
        console.error("Error fetching citizens:", error);
      }
    };

    fetchCitizens();
  }, [selectedDisasters]);

  const navigate = useNavigate();

  const [userId, setUserId] = useState(null);

  // Add this useEffect for authentication
  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCommandClick = async (args) => {
    if (args.commandColumn.type === "View") {
      try {
        if (!userId) {
          console.error("User ID is not set. Cannot update document.");
          return;
        }

        const rowData = args.rowData;
        console.log("Full row data:", rowData);

        if (!rowData) {
          console.error("No row data available.");
          return;
        }

        const citizen_id = rowData.citizen_id;
        const case_number = rowData.case_number;
        const disaster_id = rowData.disaster_id;

        console.log("Selected Citizen ID: ", citizen_id);
        console.log("Case Number: ", case_number);
        console.log("Disaster ID: ", disaster_id);

        const userDocRef = doc(db, "users", userId);

        // Store both the accessed_userId and the case number
        await updateDoc(userDocRef, {
          accessed_userId: citizen_id,
          accessed_case_number: case_number,
          accessed_disaster_id: disaster_id,
        });

        navigate("/casemanagement/disastermanagement");
      } catch (error) {
        console.error("Error in handleCommandClick: ", error);
      }
    } else if (args.commandColumn.type === "Assign") {
      setSelectedCase(args.rowData);
      setAssignDialogOpen(true);
    }
  };

  const handleAssign = async () => {
    try {
      if (!selectedEmployee || !selectedCase) {
        console.error("Missing required selections");
        return;
      }

      console.log("Selected Case:", selectedCase);
      console.log("Selected Employee:", selectedEmployee);
      console.log("Selected Company:", selectedCompany);
      console.log("Selected Supervisor:", selectedSupervisor);

      // Check if a case management record already exists
      const caseManagementRef = collection(db, "case_management");
      const q = query(
        caseManagementRef,
        where("case_number", "==", selectedCase.case_number),
        where("citizen_id", "==", selectedCase.citizen_id)
      );
      const querySnapshot = await getDocs(q);

      const updateData = {
        // Case details
        case_number: selectedCase.case_number,
        citizen_id: selectedCase.citizen_id,
        citizen_name: selectedCase.name,
        citizen_address: selectedCase.address,
        citizen_email: selectedCase.email,
        citizen_mobile: selectedCase.mobile_number,

        // Assignment details
        assigned_to: `${selectedEmployee.first_name} ${selectedEmployee.last_name}`,
        assigned_company: selectedCompany?.company || "",
        supervisor: selectedSupervisor
          ? `${selectedSupervisor.first_name} ${selectedSupervisor.last_name}`
          : "",
        assignment_date: new Date().toISOString(),

        // Disaster details
        disaster_id: selectedCase.disaster_id,
        disaster_name: selectedCase.disaster_name,

        // Center details
        center_id: userData.center_id,

        // Status
        status: selectedStatus,
        updated_at: new Date().toISOString(),
      };

      if (querySnapshot.empty) {
        // No existing record, create new one
        await addDoc(caseManagementRef, {
          ...updateData,
          created_at: new Date().toISOString(),
        });
      } else {
        // Update existing record
        const existingDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, "case_management", existingDoc.id), updateData);
      }

      setAssignDialogOpen(false);

      // Reset selections
      setSelectedEmployee(null);
      setSelectedCompany(null);
      setSelectedSupervisor(null);
      setSelectedStatus("Open");
      // Refresh the grid data
      setSelectedDisasters([...selectedDisasters]);
    } catch (error) {
      console.error("Error assigning case:", error);
    }
  };

  return (
    <div>
      <div
        className="relative mb-4 flex items-center gap-2 pt-4"
        ref={dropdownRef}
      >
        <span className="text-sm font-bold text-red-500">DISASTERS</span>
        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="relative flex w-[450px] items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm"
          >
            <span className="absolute inset-0 flex items-center justify-center">
              <span className="truncate px-6">
                {selectedDisasters.includes("All")
                  ? "All Disasters"
                  : selectedDisasters.length > 0
                  ? disasterOptions
                      .filter((disaster) =>
                        selectedDisasters.includes(disaster.id)
                      )
                      .map((disaster) => disaster.name)
                      .join(", ")
                  : "Select Disasters"}
              </span>
            </span>
            <span className="ml-auto">▼</span>
          </button>
          {isDropdownOpen && (
            <div className="absolute left-0 right-0 z-10 mt-1 rounded border border-gray-300 bg-white shadow-lg">
              {disasterOptions.map((disaster) => (
                <label
                  key={disaster.id}
                  className="hover:bg-gray-100 flex cursor-pointer items-center px-4 py-2"
                >
                  <input
                    type="checkbox"
                    checked={selectedDisasters.includes(disaster.id)}
                    onChange={() => handleDisasterChange(disaster.id)}
                    className="mr-2"
                  />
                  {disaster.name}
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <GridComponent
          //   ref={gridRef}
          dataSource={gridData}
          allowSorting={true}
          sortSettings={sortSettings}
          editSettings={editSettings}
          filterSettings={filterSettings}
          allowFiltering={true}
          // actionBegin={onActionBegin}
          toolbar={["Search"]}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
          commandClick={handleCommandClick}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" isPrimaryKey visible={false} />
            <ColumnDirective field="citizen_id" visible={false} />
            <ColumnDirective
              field="case_number"
              headerText="Case Number"
              width="200"
            />
            <ColumnDirective field="name" headerText="Name" width="200" />
            <ColumnDirective
              field="address"
              headerText="Impacted Address"
              width="300"
            />
            <ColumnDirective
              field="assigned_to"
              headerText="Assigned To"
              width="200"
            />
            <ColumnDirective
              field="assigned_company"
              headerText="Assigned Company"
              width="200"
            />
            <ColumnDirective
              field="supervisor"
              headerText="Supervisor"
              width="200"
            />
            <ColumnDirective
              field="assignment_date"
              headerText="Date Assigned"
              width="200"
              format="yMd" // This will format the date
              type="date" // This specifies that this is a date column
              template={(props) => {
                if (!props.assignment_date) return "";
                const date = new Date(props.assignment_date);
                return date.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                });
              }}
            />
            <ColumnDirective
              field="status"
              headerText="Status"
              width="200"
              template={(props) => {
                const statusColor =
                  props.status === "Open"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800";
                return (
                  <div
                    className={`inline-block rounded-full px-2 py-1 text-xs font-medium ${statusColor}`}
                  >
                    {props.status || "Unassigned"}
                  </div>
                );
              }}
            />
            {/* <ColumnDirective
                  headerText="Manage Records"
                  width="160"
                  commands={commands}
                /> */}
            <ColumnDirective
              headerText="Actions"
              textAlign="Center"
              width="500"
              commands={[
                {
                  type: "View",
                  buttonOption: {
                    content: "View Recovery Tracker",
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Assign",
                  buttonOption: {
                    content: "Assign",
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Access",
                  buttonOption: {
                    content: "Access Account",
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter, Search, ColumnMenu]} />
        </GridComponent>
      </div>
      <DialogComponent
        width="500px"
        height="500px"
        isModal={true}
        visible={assignDialogOpen}
        close={() => setAssignDialogOpen(false)}
        header="Assign Case"
        showCloseIcon={true}
        closeOnEscape={true}
        target="#root"
      >
        <div className="p-6">
          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium">Assign To</label>
            <DropDownListComponent
              dataSource={employees}
              fields={{ text: "fullName", value: "id" }}
              placeholder="Select Employee"
              onChange={(e) => {
                const selectedEmp = employees.find((emp) => emp.id === e.value);
                setSelectedEmployee(selectedEmp);
              }}
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium">Company</label>
            <DropDownListComponent
              dataSource={employees.filter((emp) => emp.company)}
              fields={{ text: "company", value: "id" }}
              placeholder="Select Company"
              onChange={(e) => {
                const selectedComp = employees.find(
                  (emp) => emp.id === e.value
                );
                setSelectedCompany(selectedComp);
              }}
              className="w-full"
            />
          </div>

          <div className="mb-6">
            <label className="mb-2 block text-sm font-medium">Supervisor</label>
            <DropDownListComponent
              dataSource={employees.filter((emp) =>
                emp.roles?.includes("Navigator Supervisor")
              )}
              fields={{ text: "fullName", value: "id" }}
              placeholder="Select Supervisor"
              onChange={(e) => {
                const selectedSup = employees.find((emp) => emp.id === e.value);
                setSelectedSupervisor(selectedSup);
              }}
              className="w-full"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium">Status</label>
            <DropDownListComponent
              dataSource={["Open", "Closed"]}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.value)}
              className="w-full"
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setAssignDialogOpen(false)}
              className="rounded-full border border-blue-500 px-4 py-2 text-blue-500 hover:bg-blue-50"
            >
              Cancel
            </button>
            <button
              onClick={handleAssign}
              className="rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Assign
            </button>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
};

export default CaseManagement;
