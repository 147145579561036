import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  orderBy,
  limit,
  collectionGroup,
  doc,
  getDoc,
} from "firebase/firestore";

let disasterDataCache = null;
let surveyDataCache = null;

const DisasterMgtService = {
  fetchDisasterData: async (disasterId, selectedCounties) => {
    try {
      const promises = [
        "general",
        "home_repair",
        "landscaping",
        "vehicle",
        "contents",
      ].map(async (collectionName) => {
        const snapshot = await getDocs(
          query(
            collectionGroup(db, collectionName),
            where("associatedDisasters", "array-contains", disasterId),
            where("is_deleted", "==", false)
          )
        );

        return snapshot.docs.map((doc) => ({
          collectionName,
          userId: doc.data().userId,
          value: parseFloat(doc.data().value || 0),
          ...doc.data(),
        }));
      });

      const results = await Promise.all(promises);
      let allRecords = results.flat();
      const userCountyChecks = allRecords.map(async (record) => {
        if (!record.userId) {
          return null;
        }

        try {
          const impactedAddressesRef = collection(
            db,
            "users",
            record.userId,
            "impacted-addresses"
          );
          const impactedAddressSnapshot = await getDocs(impactedAddressesRef);

          if (!impactedAddressSnapshot.empty) {
            for (const doc of impactedAddressSnapshot.docs) {
              const county = doc.data().county;
              if (selectedCounties.includes(county)) {
                return record;
              }
            }
          } else {
          }
        } catch (error) {
          console.error(
            `Error fetching county for user ${record.userId}:`,
            error
          );
        }
        return null;
      });

      const filteredRecords = (await Promise.all(userCountyChecks)).filter(
        Boolean
      );

      let totalValueSum = 0;
      let immediateAssistanceSum = 0;
      let recoveryAssistanceSum = 0;

      const uniqueUserIds = new Set();

      filteredRecords.forEach((record) => {
        totalValueSum += record.value;
        uniqueUserIds.add(record.userId);

        if (record.collectionName === "general") {
          immediateAssistanceSum += record.value;
        } else {
          recoveryAssistanceSum += record.value;
        }
      });

      const totalRecords = uniqueUserIds.size;

      disasterDataCache = {
        allRecords: filteredRecords,
        totalValueSum,
        immediateAssistanceSum,
        recoveryAssistanceSum,
        totalRecords,
      };

      return disasterDataCache;
    } catch (error) {
      console.error("Error fetching disaster data:", error);
      throw error;
    }
  },

  fetchDisasterSurvey: async (disasterId, selectedCounties) => {
    try {
      const surveySnapshot = await getDocs(
        collectionGroup(db, "survey_responses")
      );
      const filteredResponses = surveySnapshot.docs.filter((doc) =>
        doc.data().disasters?.some((d) => d.disaster_id === disasterId)
      );

      const userCountyChecks = filteredResponses.map(async (surveyDoc) => {
        const data = surveyDoc.data();
        const userId = data.user_id;

        if (!userId) {
          return null;
        }

        try {
          const impactedAddressesRef = collection(
            db,
            "users",
            userId,
            "impacted-addresses"
          );
          const impactedAddressesSnapshot = await getDocs(impactedAddressesRef);

          let matchFound = false;
          impactedAddressesSnapshot.forEach((doc) => {
            const county = doc.data().county;
            if (selectedCounties.includes(county)) {
              matchFound = true;
            }
          });

          if (matchFound) {
            return surveyDoc;
          }
        } catch (error) {
          console.error(`Error fetching county for user ${userId}:`, error);
        }

        return null;
      });

      const validResponses = (await Promise.all(userCountyChecks)).filter(
        Boolean
      );

      let disasterImpactCount = { affected: 0, notAffected: 0 };
      let injuryCount = {
        noInjury: 0,
        minorInjury: 0,
        medicalTreatment: 0,
        visitedHospital: 0,
        admitted: 0,
      };
      let homeDamageCount = {
        noDamage: 0,
        slightDamage: 0,
        limitedDamage: 0,
        severeDamage: 0,
        totalDestruction: 0,
      };
      let homesDestroyedCount = { damaged: 0, destroyed: 0, undamaged: 0 };
      let typeOfHomeDamageCount = {
        "Check All": 0,
        Siding: 0,
        Flooring: 0,
        Cabinets: 0,
        "Shed/garage": 0,
        Roof: 0,
        Foundation: 0,
        "Floor Joists": 0,
        HVAC: 0,
        Driveway: 0,
        Gutters: 0,
        Ceiling: 0,
        Drywall: 0,
        Electrical: 0,
        "Yard/Uprooted trees": 0,
        Other: 0,
      };

      validResponses.forEach((doc) => {
        const data = doc.data();
        const getAnswer = (key) => data.responses?.[key]?.answer || "";

        // Disaster Impact
        if (
          getAnswer("Was the impacted address your primary address?") === "No"
        ) {
          disasterImpactCount.notAffected += 1;
        } else if (
          getAnswer("Was the impacted address your primary address?") === "Yes"
        ) {
          disasterImpactCount.affected += 1;
        }

        // Injury Data Mapping
        switch (getAnswer("What level of injury did you sustain?")) {
          case "No Injury":
            injuryCount.noInjury += 1;
            break;
          case "Minor Injury":
            injuryCount.minorInjury += 1;
            break;
          case "Sought medical treatment":
            injuryCount.medicalTreatment += 1;
            break;
          case "Visited Hospital":
            injuryCount.visitedHospital += 1;
            break;
          case "Admitted":
            injuryCount.admitted += 1;
            break;
          default:
            break;
        }

        // Home Damage Mapping
        switch (getAnswer("What level of damage did your home sustain?")) {
          case "No Damage":
            homeDamageCount.noDamage += 1;
            break;
          case "Slight Damage":
            homeDamageCount.slightDamage += 1;
            break;
          case "Limited Damage":
            homeDamageCount.limitedDamage += 1;
            break;
          case "Severe Damage":
            homeDamageCount.severeDamage += 1;
            break;
          case "Total Destruction":
            homeDamageCount.totalDestruction += 1;
            break;
          default:
            break;
        }

        // Homes Destroyed Mapping
        switch (getAnswer("The impacted home:")) {
          case "Has minor damage":
            homesDestroyedCount.undamaged += 1;
            break;
          case "Has major damage":
            homesDestroyedCount.damaged += 1;
            break;
          case "Is destroyed":
            homesDestroyedCount.destroyed += 1;
            break;
          default:
            break;
        }

        // Type of Home Damage Mapping
        const homeDamageMap =
          data.responses?.["There is visible damage to the home's:"]?.answer ||
          {};
        Object.keys(typeOfHomeDamageCount).forEach((type) => {
          if (homeDamageMap[type] === true) {
            typeOfHomeDamageCount[type] += 1;
          }
        });
      });

      surveyDataCache = {
        disasterImpactCount,
        injuryCount,
        homeDamageCount,
        homesDestroyedCount,
        typeOfHomeDamageCount,
      };

      return surveyDataCache;
    } catch (error) {
      console.error("Error fetching disaster survey data:", error);
      throw error;
    }
  },

  fetchAllData: async () => {
    try {
      const mainCollectionRef = collection(db, "disaster-mgt");
      const mainSnapshot = await getDocs(mainCollectionRef);

      const allData = [];

      for (const docSnap of mainSnapshot.docs) {
        const docId = docSnap.id;

        const subcollections = [
          "general",
          "home_repair",
          "landscaping",
          "vehicle",
          "contents",
        ];

        for (const subcollectionName of subcollections) {
          const subcollectionRef = collection(
            db,
            "disaster-mgt",
            docId,
            subcollectionName
          );
          const subcollectionSnapshot = await getDocs(subcollectionRef);

          const subcollectionData = subcollectionSnapshot.docs.map(
            (subDoc) => ({
              id: subDoc.id,
              ...subDoc.data(),
            })
          );

          allData.push({
            docId,
            subcollectionName,
            data: subcollectionData,
          });

          console.log(
            `Fetched data from subcollection "${subcollectionName}" of document "${docId}"`,
            subcollectionData
          );
        }
      }

      return allData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  fetchRecords: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );
      const documentsSnapshot = await getDocs(documentsCollectionRef);

      const documentsList = documentsSnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      });

      return documentsList;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },
  addRecord: async (userId, documentData, collectionName) => {
    try {
      console.log("addRecord called with:", {
        userId,
        documentData,
        collectionName,
      });

      if (!userId) throw new Error("userId is undefined");
      if (!collectionName) throw new Error("collectionName is undefined");
      if (!documentData) throw new Error("documentData is undefined");

      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );

      console.log("Collection reference created:", documentsCollectionRef);

      const updatedDocumentData = {
        ...documentData,
        userId: userId,
        updated_at: serverTimestamp(),
      };

      console.log("Attempting to add document with data:", updatedDocumentData);

      const docRef = await addDoc(documentsCollectionRef, updatedDocumentData);

      console.log("Document successfully added with ID:", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },

  updateRecord: async (userId, documentId, documentData, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );

      const idField = getIdFieldForCollection(collectionName);

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;

          const updatedDocumentData = {
            ...documentData,
            userId: userId,
            updated_at: serverTimestamp(),
          };

          await updateDoc(documentRef, updatedDocumentData);
        });
        console.log("Document successfully updated!");
      } else {
        console.error("No document found with the given ID to update.");
        throw new Error("No document found with the given ID to update.");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  deleteRecord: async (userId, documentId, collectionName) => {
    try {
      console.log("Deleting document with ID:", documentId);

      if (!documentId) {
        throw new Error("documentId is undefined or null");
      }

      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );
      const idField = getIdFieldForCollection(collectionName);

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await deleteDoc(documentRef);
        });
        console.log("Document successfully deleted!");
      } else {
        console.error("No document found with the given ID to delete.");
        throw new Error("No document found with the given ID to delete.");
      }
    } catch (error) {
      console.error(
        "Error deleting document from disaster management: ",
        error
      );
      throw error;
    }
  },

  getLastEditedTime: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );
      const q = query(
        documentsCollectionRef,
        orderBy("last_edited", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data().last_edited.toDate();
      }
      return null;
    } catch (error) {
      console.error("Error getting last edited time:", error);
      return null;
    }
  },
};

const getIdFieldForCollection = (collectionName) => {
  switch (collectionName) {
    case "general":
      return "generalID";
    case "home_repair":
      return "homeRepairID";
    case "landscaping":
      return "landscapingID";
    case "contents":
      return "contentsID";
    case "vehicle":
      return "vehicleID";
    default:
      throw new Error(`Unknown collection name: ${collectionName}`);
  }
};

export default DisasterMgtService;
