import React, { useState, useRef, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Page,
  Sort,
  Filter,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import CenterService from "../../service/center/CenterService";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import dataService from "../../service/data/DataService";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaPlus } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    name: `${item.first_name} ${item.last_name}`,
    address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip} ${item.county}`,
  }));
};

const checkIfEmailExists = async (email) => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty;
};

const checkboxTemplate = (props) => {
  return <input type="checkbox" checked={props.is_approved} disabled />;
};

const PasswordTemplate = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.password || "");

  useEffect(() => {
    setPassword(props.password || "");
  }, [props.password]);

  const handleChange = (e) => {
    setPassword(e.value);
    if (props.onChange) {
      props.onChange({ ...e, value: e.value });
    }
  };

  return (
    <div className="relative w-full">
      <TextBoxComponent
        placeholder="Password"
        floatLabelType="Always"
        type={showPassword ? "text" : "password"}
        value={password}
        change={handleChange}
        name="password"
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  );
};

const Center = () => {
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  const [center, setCenter] = useState([]);
  const gridRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        const fetchCenters = async () => {
          try {
            const centers = await CenterService.getAllCenters();
            setCenter(preprocessData(centers));
            console.log("Centers: ", centers);
          } catch (error) {
            console.error("Error fetching centers: ", error);
          }
        };

        fetchCenters();
      } else {
        setCurrentUserData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const userData = await dataService.getUserProfile("users", userId);
    if (userData) {
      setCurrentUserData(userData);
      console.log("Data: ", userData);
    }
  };

  const handleAddClick = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const actionBegin = async (args) => {
    const auth = getAuth();
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const cols = gridRef.current.columns;
      for (const col of cols) {
        if (
          [
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "county",
            "email",
            "password",
            "is_approved",
          ].includes(col.field)
        ) {
          col.visible = true;
        } else if (["address"].includes(col.field)) {
          col.visible = false;
        }
      }
      if (args.requestType === "add") {
        args.data.password = generateRandomPassword();
      } else if (args.requestType === "beginEdit") {
        if (!args.rowData.password) {
          // alert("Please enter a password for this center");
          args.rowData.password = generateRandomPassword();
        }
      }
    }
    if (args.requestType === "save") {
      const cols = gridRef.current.columns;
      for (const col of cols) {
        if (
          [
            "first_name",
            "last_name",
            "address1",
            "address2",
            "city",
            "state",
            "county",
            "zip",
            "password",
            "is_approved",
          ].includes(col.field)
        ) {
          col.visible = false;
        } else if (["address", "mobile_number", "name"].includes(col.field)) {
          col.visible = true;
        }
      }

      const data = args.data;

      // Update Name and Address fields
      data.name = `${data.first_name} ${data.last_name}`;
      data.address = `${data.address1 || ""} ${data.address2 || ""} ${
        data.city || ""
      } ${data.state || ""} ${data.zip || ""} ${data.county || ""}`;

      if (args.action === "add") {
        const emailExists = await checkIfEmailExists(data.email);

        if (emailExists) {
          alert(
            "Email already exists. Please use a different email or log in."
          );
          return;
        }

        try {
          const user = await CenterService.createUserAccount(
            data.email,
            data.password
          );
          await signInWithEmailAndPassword(
            auth,
            currentUserData.email,
            currentUserData.password
          );
          const userData = {
            ...data,
            center_id: user.uid,
            created_at: serverTimestamp(),
            user_role: "center",
          };

          await CenterService.addCenter("users", userData);

          setCenter([...center, userData]);

          console.log("User and center data saved successfully");
        } catch (error) {
          console.error("Error adding new center: ", error);
        }
      } else if (args.action === "edit") {
        try {
          if (data.is_approved && !data.created_at) {
            const user = await CenterService.createUserAccount(
              data.email,
              data.password
            );
            await signInWithEmailAndPassword(
              auth,
              currentUserData.email,
              currentUserData.password
            );
            const userData = {
              ...data,
              center_id: user.uid,
              created_at: serverTimestamp(),
              user_role: "center",
            };

            await CenterService.addCenter("users", userData);

            // setCenter([...center, userData]);

            console.log("User and center data saved successfully");

            // delete approval record
            const centerQuery = query(
              collection(db, "center-for-approval"),
              where("email", "==", data.email)
            );
            const centerSnapshot = await getDocs(centerQuery);

            if (!centerSnapshot.empty) {
              const docToDelete = centerSnapshot.docs[0];
              await deleteDoc(docToDelete.ref);
              console.log(
                `Document with email ${data.email} deleted from center-for-approval.`
              );
            } else {
              console.log("No document found with the matching email.");
            }
          } else {
            const sanitizedData = Object.fromEntries(
              Object.entries(data).map(([key, value]) => [
                key,
                value === null || value === undefined ? "" : value,
              ])
            );
            console.log("Sanitized Data: ", sanitizedData);
            if (sanitizedData.center_id) {
              await CenterService.updateCenter(
                "users",
                sanitizedData.center_id,
                sanitizedData
              );
              setCenter((prevCenter) =>
                prevCenter.map((item) =>
                  item.center_id === sanitizedData.center_id
                    ? { ...item, ...sanitizedData }
                    : item
                )
              );
            } else {
              console.error("center_id is undefined in sanitizedData");
            }
            const updatedCenters = await CenterService.getAllCenters();
            setCenter(updatedCenters);
          }
        } catch (error) {
          console.error("Error updating center: ", error);
        }
      }
    } else if (args.requestType === "delete") {
      const data = args.data[0];
      try {
        console.log("deleting", args.data[0]);
        if (args.data[0].created_at) {
          console.log("marking as deleted");
          await CenterService.updateCenter("users", data.center_id, {
            is_deleted: true,
          });
          setCenter((prevCenter) =>
            prevCenter.map((item) =>
              item.center_id === data.center_id
                ? { ...item, is_deleted: true }
                : item
            )
          );
        } else {
          console.log("marking as deleted in center-for-approval");
          const centerQuery = query(
            collection(db, "center-for-approval"),
            where("email", "==", data.email)
          );
          const centerSnapshot = await getDocs(centerQuery);

          if (!centerSnapshot.empty) {
            const docToUpdate = centerSnapshot.docs[0];
            await updateDoc(docToUpdate.ref, { is_deleted: true });
            console.log(
              `Document with email ${data.email} marked as deleted in center-for-approval.`
            );
          } else {
            console.log("No document found with the matching email.");
          }

          setCenter((prevCenter) =>
            prevCenter.map((item) =>
              item.email === data.email ? { ...item, is_deleted: true } : item
            )
          );
        }
      } catch (error) {
        console.error("Error marking center as deleted: ", error);
      }
      await CenterService.getAllCenters();
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      const cols = gridRef.current.columns;
      for (const col of cols) {
        if (
          [
            "password",
            "name_of_hub",
            "first_name",
            "last_name",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "county",
            "is_approved",
          ].includes(col.field)
        ) {
          col.visible = true;
        } else if (["name", "address"].includes(col.field)) {
          col.visible = false;
        }
      }
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Center" : "Add a Center";
      }
    }
  };

  return (
    <div>
      <header className="mb-2 mr-4 mt-4 flex flex-row items-center justify-between self-stretch">
        <div>
          <h2 className="mb-1 text-sm font-bold text-blue-500">
            CENTER (For approval)
          </h2>
        </div>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
            onClick={handleAddClick}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div>
      </header>

      <div className="flex flex-col gap-4 self-stretch">
        <GridComponent
          ref={gridRef}
          dataSource={center.filter((item) => item.is_deleted !== true)}
          allowSorting={true}
          sortSettings={sortSettings}
          editSettings={editing}
          filterSettings={filterSettings}
          allowFiltering={true}
          actionBegin={actionBegin}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="center_id"
              headerText="Center ID"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="name_of_hub"
              headerText="Name of Center"
              width="100"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width="100"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="password"
              headerText="Password"
              width="100"
              editType="passwordedit"
              visible={false}
              editTemplate={PasswordTemplate}
              // validationRules={{ required: true }}
            />
            <ColumnDirective
              field="name"
              headerText="Contact Person"
              width="150"
            />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective field="address" headerText="Address" width="150" />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              width="100"
              visible={false}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="county"
              headerText="County"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              width="100"
              validationRules={{ required: true }}
            />

            <ColumnDirective
              field="is_approved"
              headerText="Approve"
              width="100"
              template={checkboxTemplate}
              editType="booleanedit"
              visible={false}
            />

            <ColumnDirective
              headerText="Actions"
              width="120"
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: '<i class="fas fa-edit"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default Center;
